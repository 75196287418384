<style>
#v-data-table td,
#v-data-table th {
  padding: 0px 0px 0px 1px !important;
}
</style>

<template>
  <v-app>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="desserts"
      :loading="loadingTable"
      :single-select="singleSelect"
      item-key="ID"
      id="v-data-table"
      style="padding: 25px;"
    >
      <template v-slot:top>
        <div>
          <div>
            <v-toolbar style="box-shadow: none !important;">
              <v-toolbar-title
                ><b>{{ title }}</b></v-toolbar-title
              >
              <v-spacer></v-spacer>

              <v-spacer></v-spacer>

              <v-btn
                rounded
                style="margin-top: 15px; margin-right: 5px;"
                color="primary"
                large
                v-on:click="modalForm()"
              >
                <v-icon dark style="margin-right: 10px;">search</v-icon>
                Filter
              </v-btn>
              <v-btn
                rounded
                style="margin-top: 15px;"
                color="default"
                large
                v-on:click="alldata()"
              >
                <v-icon dark>refresh</v-icon>
              </v-btn>
            </v-toolbar>
          </div>
        </div>

        <br /><br />
        <v-dialog v-model="modal_form" max-width="1000px">
          <v-card>
            <v-card-title>
              SLA Filter
            </v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <div style="padding: 10px;">
                  <v-layout>
                    <v-flex md3>
                      <div style="margin-left: 20px; padding-top: 10px;">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="date"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="date"
                              label="Start Date"
                              append-icon="event"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu.save(date)"
                              >OK</v-btn
                            >
                          </v-date-picker>
                        </v-menu>
                      </div>
                    </v-flex>

                    <v-flex md3>
                      <div style="margin-left: 20px; padding-top: 10px;">
                        <v-menu
                          ref="menu2"
                          v-model="menu2"
                          :close-on-content-click="false"
                          :return-value.sync="date2"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="date2"
                              label="End Date"
                              append-icon="event"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="date2" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu2 = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu2.save(date2)"
                              >OK</v-btn
                            >
                          </v-date-picker>
                        </v-menu>
                      </div>
                    </v-flex>

                    <v-flex md3>
                      <div style="margin-left: 20px; padding-top: 10px;">
                        <v-select
                          item-text="SERVER_NAME"
                          item-value="SERVER_CODE"
                          v-model="server_code"
                          :items="list_server_code"
                          :rules="rules"
                          label="Choose Server"
                          v-on:change="getCompany(), nullCompany()"
                        ></v-select>
                      </div>
                    </v-flex>

                    <v-flex md3>
                      <div style="margin-left: 20px; padding-top: 10px;">
                        <v-autocomplete
                          item-text="COMPANY_NAME"
                          item-value="COMPANY_ID"
                          v-model="company_id"
                          :items="list_company"
                          label="Choose Company"
                          v-bind:value="{ id: COMPANY_ID, text: COMPANY_NAME }"
                        ></v-autocomplete>
                      </div>
                    </v-flex>
                  </v-layout>

                  <v-layout>
                    <v-flex md3>
                      <div style="margin-left: 20px; padding-top: 10px;">
                        <v-autocomplete
                          item-text="DEPARTEMENT_NAME"
                          item-value="DEPARTEMENT_ID"
                          v-model="departement_id"
                          :items="list_departement"
                          :rules="rules"
                          label="Choose Department"
                          v-bind:value="{
                            id: DEPARTEMENT_ID,
                            text: DEPARTEMENT_DESC,
                          }"
                        ></v-autocomplete>
                      </div>
                    </v-flex>

                    <!--<v-flex md3> 
                                  <div style="margin-left:20px;padding-top:10px">
                                      <v-autocomplete 
                                        item-text="DEPARTEMENT_NAME"
                                        item-value="DEPARTEMENT_ID"
                                        v-model="departement_id"
                                        :items="list_departement"
                                        :rules="rules"
                                        label="Choose Department"
                                        v-bind:value="{ id: DEPARTEMENT_ID, text: DEPARTEMENT_DESC }"
                                      ></v-autocomplete>
                                  </div>
                              </v-flex>-->

                    <v-flex md3>
                      <div style="margin-left: 20px; padding-top: 10px;">
                        <v-autocomplete
                          item-text="NAME"
                          item-value="USER_ID"
                          v-model="requester"
                          :items="list_requester"
                          label="Choose Requester"
                        ></v-autocomplete>
                      </div>
                    </v-flex>

                    <v-flex md3>
                      <div style="margin-left: 20px; padding-top: 10px;">
                        <v-autocomplete
                          item-text="NAME"
                          item-value="USER_ID"
                          v-model="approval1"
                          :items="list_approval1"
                          label="Choose Approval 1"
                        ></v-autocomplete>
                      </div>
                    </v-flex>

                    <v-flex md3>
                      <div style="margin-left: 20px; padding-top: 10px;">
                        <v-autocomplete
                          item-text="NAME"
                          item-value="USER_ID"
                          v-model="approval2"
                          :items="list_approval2"
                          label="Choose Approval 2"
                        ></v-autocomplete>
                      </div>
                    </v-flex>
                  </v-layout>

                  <v-layout>
                    <v-flex md3>
                      <div style="margin-left: 20px; padding-top: 10px;">
                        <v-autocomplete
                          item-text="NAME"
                          item-value="USER_ID"
                          v-model="cataloguer"
                          :items="list_cataloguer"
                          label="Choose Cataloguer"
                        ></v-autocomplete>
                      </div>
                    </v-flex>

                    <v-flex md3>
                      <div style="margin-left: 20px; padding-top: 10px;">
                        <v-select
                          item-text="STATUS_DESC"
                          item-value="STATUS_ID"
                          v-model="item_status"
                          :items="list_item_status"
                          label="Choose Status"
                        ></v-select>
                      </div>
                    </v-flex>

                    <v-flex md3>
                      <div style="margin-left: 20px; padding-top: 10px;">
                        <v-text-field
                          v-model="request_no"
                          label="Request Number"
                          append-icon="mdi-card-text"
                        ></v-text-field>
                      </div>
                    </v-flex>

                    <v-flex md3>
                      <div style="margin-left: 20px; padding-top: 10px;">
                        <v-text-field
                          v-model="item_name"
                          label="Item Name"
                          append-icon="mdi-folder-table"
                        ></v-text-field>
                      </div>
                    </v-flex>
                  </v-layout>

                  <v-layout>
                    <v-flex md3>
                      <div style="margin-left: 20px; padding-top: 20px;">
                        <v-btn color="primary" v-on:click="search()">
                          Search
                        </v-btn>
                        &nbsp;

                        <v-btn color="success" dark v-on:click="alldata()">
                          All
                        </v-btn>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>

                <div align="right">
                  <v-btn color="primary" text @click="modal_form = false">
                    Close
                  </v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>

      <template v-slot:item.approval1="{ item }">
        <div
          v-if="item.APPROVAL1"
          style="background-color: #4beb7c; padding: 10px;"
        >
          <div>{{ item.APPROVAL1 }}</div>
          <div>
            Approved : <b>{{ item.APPROVAL1_DATES }}</b>
          </div>
          <div v-if="item.APPROVAL1_DATE == '0' || item.APPROVAL1_DATE">
            <b>{{ item.APPROVAL1_DATE }} Days</b>
          </div>
        </div>

        <div
          v-if="
            !item.APPROVAL1 &&
            item.TYPE_STOCK == 'Y' &&
            item.ITEM_STATUS == 'APP1' &&
            item.ITEM_STATUS != 'DRAFT'
          "
          style="background-color: #fcf76c; padding: 10px;"
        >
          <div>{{ item.MON_APPROVAL1 }}</div>
          <div>Not Yet Approved</div>
          <div>
            <b>{{ item.MON_APPROVAL1_DATE }} Days</b>
          </div>
        </div>

        <div
          v-if="item.TYPE_STOCK == 'N'"
          style="background-color: #f8f8f3; padding: 10px;"
        >
          No Approval 1 <br /><b>Non Stock</b><br /><br />
        </div>
      </template>

      <template v-slot:item.approval2="{ item }">
        <div
          v-if="item.APPROVAL2"
          style="background-color: #4beb7c; padding: 10px;"
        >
          <div>{{ item.APPROVAL2 }}</div>
          <div>
            Approved : <b>{{ item.APPROVAL2_DATES }}</b>
          </div>
          <div v-if="item.APPROVAL2_DATE == '0' || item.APPROVAL2_DATE">
            <b>{{ item.APPROVAL2_DATE }} Days</b>
          </div>
        </div>

        <div
          v-if="
            !item.APPROVAL2 &&
            item.TYPE_STOCK == 'Y' &&
            item.ITEM_STATUS == 'APP2'
          "
          style="background-color: #fcf76c; padding: 10px;"
        >
          <div>{{ item.MON_APPROVAL2 }}</div>
          <div>Not Yet Approved</div>
          <div>
            <b>{{ item.MON_APPROVAL2_DATE }} Days</b>
          </div>
        </div>

        <div
          v-if="item.TYPE_STOCK == 'N'"
          style="background-color: #f8f8f3; padding: 10px; width: 100%;"
        >
          No Approval 2 <br /><b>Non Stock</b><br /><br />
        </div>
      </template>

      <template v-slot:item.cataloguer="{ item }">
        <div
          v-if="item.CATALOGUER"
          style="background-color: #4beb7c; padding: 10px;"
        >
          <div>{{ item.CATALOGUER }}</div>
          <div>
            Approved : <b>{{ item.CATALOGUER_DATES }}</b>
          </div>
          <div>
            <b v-if="item.CATALOGUER_DATE == '0' || item.CATALOGUER_DATE"
              >{{ item.CATALOGUER_DATE }} Days</b
            >
          </div>
        </div>

        <div
          v-if="!item.CATALOGUER && item.ITEM_STATUS == 'CAT'"
          style="background-color: #fcf76c; padding: 10px;"
        >
          <div>{{ item.MON_CATALOGUER }}</div>
          <div>Not Yet Approved</div>
          <div>
            <b>{{ item.MON_CATALOGUER_DATE }} Days</b>
          </div>
        </div>
      </template>

      <template v-slot:item.total="{ item }">
        <div
          v-if="item.ITEM_STATUS == 'REG'"
          style="padding: 10px; background-color: #4beb7c;"
        >
          <br />
          <b>
            <span
              >{{
                item.APPROVAL1_DATE +
                item.APPROVAL2_DATE +
                item.CATALOGUER_DATE +
                item.REGISTER_SAP_DATE
              }}
              Days</span
            >
          </b>
          <br /><br />
        </div>

        <div
          v-if="item.ITEM_STATUS != 'REG' && item.ITEM_STATUS != 'RJT'"
          style="padding: 10px; background-color: #fcf76c;"
        >
          <br />
          <b>
            <span>{{ item.MON_APPROVAL1_DATE }} Days</span>
          </b>
          <br /><br />
        </div>

        <div
          v-if="item.ITEM_STATUS == 'RJT'"
          style="padding: 10px; background-color: #fa4b4c;"
        >
          <br />
          <b>
            <span
              >{{
                item.APPROVAL1_DATE +
                item.APPROVAL2_DATE +
                item.CATALOGUER_DATE +
                item.REGISTER_SAP_DATE
              }}
              Days</span
            >
          </b>
          <br /><br />
        </div>
      </template>

      <template v-slot:item.status="{ item }">
        <div
          style="padding: 10px; background-color: #f6f8f9;"
          v-if="item.ITEM_STATUS != 'RJT' && !item.NEED_INFO_DATE"
        >
          <br />
          <b>
            <span>{{ item.STATUS_DESC }}</span>
          </b>
          <br /><br />
        </div>

        <div
          style="padding: 10px; background-color: #fbf0bf;"
          v-if="item.ITEM_STATUS != 'RJT' && item.NEED_INFO_DATE"
        >
          <br />
          <b>
            <span>{{ item.STATUS_DESC }}</span>
          </b>
          <br /><br />
        </div>

        <div
          style="padding: 10px; background-color: #fa4b4c;"
          v-if="item.ITEM_STATUS == 'RJT'"
        >
          <b>
            {{ item.STATUS_DESC }}<br />
            By {{ item.REJECT_USER }} - {{ item.REJECT_USER_ROLE }}<br />
            {{ item.REJECT_DATE }}
          </b>
          <br />
        </div>
      </template>

      <template v-slot:item.registerdate="{ item }">
        <div
          style="padding: 30px 18px; background-color: #f6f8f9;"
          v-if="item.ITEM_STATUS == 'REG'"
        >
          <b>{{ item.REGISTER_SAP_DATES }}</b>
        </div>
      </template>
    </v-data-table>
  </v-app>
</template>

<script>
export default {
  methods: {
    getData() {
      var self = this;
      var url = self.base_url + "dashboard/sla_admin";

      self.axios
        .post(url, {
          departement_id: self.departement_id,
          company: self.company_id,
          startdate: self.date,
          enddate: self.date2,
          requester: self.requester,
          approval1: self.approval1,
          approval2: self.approval2,
          cataloguer: self.cataloguer,
          item_name: self.item_name,
          request_no: self.request_no,
          server: self.server_code,
          status: self.item_status,
        })
        .then(function (response) {
          self.desserts = response.data;
          self.loadingTable = false;
        });
    },
    getServer() {
      var self = this;
      var url = this.base_url + "dropdown/server_dashboard";
      this.axios.get(url).then(function (response) {
        self.list_server_code = response.data;
      });
    },
    getCompany() {
      var self = this;
      var server_code = self.server_code;
      if (server_code == "") {
        server_code = "-";
      }
      var url = this.base_url + "dropdown/company_dashboard/" + server_code;
      this.axios.get(url).then(function (response) {
        self.list_company = response.data;
      });
    },
    alldata() {
      var self = this;
      self.company_id = "";
      self.date = "";
      self.date2 = "";
      self.requester = "";
      self.approval1 = "";
      self.approval2 = "";
      self.cataloguer = "";
      self.item_name = "";
      self.request_no = "";
      self.item_status = "";
      self.server_code = "";
      self.getData();
      self.modal_form = false;
    },
    getRequester() {
      var self = this;
      var url = self.base_url + "dropdown/requester";
      self.axios.get(url).then(function (response) {
        self.list_requester = response.data;
      });
    },
    getApproval1() {
      var self = this;
      var url = self.base_url + "dropdown/approval1";
      self.axios.get(url).then(function (response) {
        self.list_approval1 = response.data;
      });
    },
    getApproval2() {
      var self = this;
      var url = self.base_url + "dropdown/approval2";
      self.axios.get(url).then(function (response) {
        self.list_approval2 = response.data;
      });
    },
    getCataloguer() {
      var self = this;
      var url = self.base_url + "dropdown/cataloguer";
      self.axios.get(url).then(function (response) {
        self.list_cataloguer = response.data;
      });
    },
    getItemStatus() {
      var self = this;
      var url = self.base_url + "dropdown/item_status";
      self.axios.get(url).then(function (response) {
        self.list_item_status = response.data;
      });
    },
    search() {
      var self = this;
      self.getData();
      self.modal_form = false;
    },
    modalForm() {
      var self = this;
      self.modal_form = true;
      self.disabled = false;
      //self.reset();
    },
    getDepartement() {
      var self = this;
      var url = self.base_url + "dropdown/departement_dashboard";
      self.axios.get(url).then(function (response) {
        self.list_departement = response.data;
      });
    },
    getDateMonth() {
      var today = new Date();
      var year = today.getFullYear();
      var month = today.getMonth();
      var date = today.getDate();
      if (month - 1 <= 0) year = today.getFullYear();
      var backdate = new Date(year, month - 1, date)
        .toISOString()
        .substr(0, 10);
      this.date = backdate;
    },
  },
  mounted() {
    if (this.storage_role != "CAT" && this.storage_role != "ADM") {
      this.$router.push("/login");
    }

    this.getDateMonth();
    this.getDepartement();
    this.getData();
    this.getServer();
    this.getCompany();
    this.getRequester();
    this.getApproval1();
    this.getApproval2();
    this.getCataloguer();
    this.getItemStatus();
  },
  data() {
    return {
      name: "SLA Report",
      title: "SLA Report",
      modal_form: false,
      items: [],
      desserts: [],
      headers: [
        { text: "Request Number", value: "REQUEST_CODE", width: "150" },
        { text: "Department", value: "DEPARTEMENT_ID", width: "150" },
        { text: "Requester", value: "REQUESTER", width: "190" },
        { text: "Item Name", value: "INC", width: "250" },
        { text: "Request Date", value: "REQUEST_DATE", width: "180" },
        { text: "Submit Date", value: "SUBMIT_DATE", width: "180" },
        { text: "Approval 1", value: "approval1", width: "260" },
        { text: "Approval 2", value: "approval2", width: "260" },
        { text: "Cataloguer", value: "cataloguer", width: "260" },
        { text: "Total LT", value: "total", width: "150" },
        { text: "Status", value: "status", width: "280" },
        { text: "SAP Register Date", value: "registerdate", width: "250" },
      ],
      list_departement: [
        { DEPARTEMENT_ID: "", DEPARTEMENT_NAME: "All Departement" },
      ],
      list_company: [{ COMPANY_ID: "", COMPANY_NAME: "All Company" }],
      list_item_status: [{ STATUS_ID: "", STATUS_DESC: "All Status" }],
      list_server_code: [{ SERVER_CODE: "", SERVER_NAME: "All Server" }],
      list_requester: [],
      list_approval1: [],
      list_approval2: [],
      list_cataloguer: [],
      list_status: [],
      server_code: "",
      departement_id: "",
      company_id: "",
      date: "",
      date2: new Date().toISOString().substr(0, 10),
      requester: "",
      approval1: "",
      approval2: "",
      cataloguer: "",
      item_name: "",
      request_no: "",
      item_status: "",
      loadingTable: true,

      //storage.....................................
      storage_user_id: atob(localStorage.getItem("user_id")),
      storage_username: atob(localStorage.getItem("username")),
      storage_fullname: atob(localStorage.getItem("fullname")),
      storage_company_id: atob(localStorage.getItem("company_id")),
      storage_company_name: atob(localStorage.getItem("company_name")),
      storage_role: atob(localStorage.getItem("role")),
      storage_server: atob(localStorage.getItem("server")),
      storage_departement_id: atob(localStorage.getItem("departement_id")),
    };
  },
};
</script>
