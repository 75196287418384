<template>
  <v-app id="login">
    

    <v-content  style="background:linear-gradient(to right, #cc0000, #E1ACA3);">
      

      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <div align="center">
              <v-img
                alt="Vuetify Name"
                class="shrink mt-1 hidden-sm-and-down"
                contain
                src="../../assets/logo-em.png"
                width="260"
                style="margin-bottom:25px"
              />
            </div>

            <v-alert
  color="white"
  border="left"
  text
  icon="mdi-alert"
  title="Alert title"
  dark
  v-if="!isAIExtensionInstalled"
>
  <span >
    Please download and install 
    <a href="https://chromewebstore.google.com/detail/autodb-chatbot/enmkfbiikgjgaingkelkjgnkjkdhcjma"
    style="color:#0050A9" target="_blank" >
      this extension 
    </a> 
    to your Chrome browser!
  </span>
</v-alert>
            <v-card class="elevation-12">
              <v-toolbar
                color="red"
                dark
                flat
              >
                <v-toolbar-title>Login</v-toolbar-title>
                <v-spacer />  
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="Login"
                    name="login"
                    v-model="username"
                    prepend-icon="email" 
                    placeholder="Email"
                    v-on:keyup.enter="login()"
                    type="text"
                  />

                  <v-text-field
                    id="password"
                    label="Password"
                    v-model="password"
                    placeholder="password"
                    name="password"
                    prepend-icon="lock"
                    v-on:keyup.enter="login()"
                    type="password"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                  <v-btn
                  color="primary"
                  dark
                  :disabled="dialog"
                  :loading="dialog"
                  v-on:click="login()">
                  Login
                  </v-btn>
                
                </v-card-actions>
            </v-card>

          </v-col>
        </v-row>
      </v-container>
    </v-content>

    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading..
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    
  </v-app>
</template>

<script>
  export default {
    name: 'login',
    props: {
      source: String,
    },
    methods : {
        login(){

    console.log(document.documentElement.getAttribute('AI-ext-installed'))
    this.isAIExtensionInstalled = document.documentElement.getAttribute('AI-ext-installed') === 'true';
            var self = this;
            var url  = self.base_url + "login/getUser";
            if(!this.username && !this.password){
              alert('Please enter email dan password !');
              return false;
            }

            if(!this.username || !this.password){
              alert('You have not entered email or password');
              return false;
            }

            self.dialog = true;
            this.axios.post(url , {
                username : this.username,
                password : this.password
            })
            .then(function (response) {
                if(response.data.length > 0){
                     window.localStorage.setItem('user_id', btoa(response.data[0].USER_ID));
                     window.localStorage.setItem('username', btoa(response.data[0].USER_NAME));
                     window.localStorage.setItem('fullname', btoa(response.data[0].NAME));
                     window.localStorage.setItem('departement_id', btoa(response.data[0].DEPARTEMENT_ID));
                     window.localStorage.setItem('departement_desc', btoa(response.data[0].DEPARTEMENT_DESC));
                     window.localStorage.setItem('role', btoa(response.data[0].ROLE_ID));
                     window.localStorage.setItem('server', btoa(response.data[0].SERVER_CODE));
                     window.localStorage.setItem('email', btoa(response.data[0].EMAIL));
                    //  window.localStorage.setItem('company_id', "");
                    //  window.localStorage.setItem('company_name', "");
                     //setTimeout(() => (
                        
                        if(response.data[0].ROLE_ID=='REQ'){
                          self.$router.push('/searchRegister');

                        }else if(response.data[0].ROLE_ID=='APP'){
                           self.$router.push('/listApprove');

                        }else if(response.data[0].ROLE_ID=='CAT'){
                           self.$router.push('/listCatalog');
                        
                        }else{
                          self.$router.push('/');
                        }
                        
                    //), 500);
                     
                }else{
                  self.dialog = false;
                  alert("Incorrect email or password");
                  
                }
            });
        }
    },
    data () { 
      return {
        storage : {
          session : false
        },
        username : "",
        password : "",
        md5:"",
        dialog:false,
        isAIExtensionInstalled: false,
      }
    },  mounted() {
    // Run the logic to check if the attribute "AI-ext-installed" is present in the document's root element
 window.onload = () => {
      // Run the logic to check if the attribute "AI-ext-installed" is present in the document's root element

    console.log(document.documentElement.getAttribute('AI-ext-installed'))   
      this.isAIExtensionInstalled = document.documentElement.getAttribute('AI-ext-installed') === 'true';
    };
  },
  }
</script>