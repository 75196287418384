<template>
  <v-app>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="desserts"
    :single-select="singleSelect"
    item-key="ID"
    show-select
    style="padding:25px">

    <template v-slot:top>
      <div>
          <div>
            <v-toolbar style="box-shadow:none !important">
              <v-toolbar-title><b>{{title}}</b></v-toolbar-title>
              <v-spacer></v-spacer>
              
              <v-spacer></v-spacer>
              <v-breadcrumbs>Master Data / Profit Center</v-breadcrumbs>
              <v-btn icon v-on:click="getMaterial">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-toolbar>

            <div style="padding:0px 25px">
                <v-layout row>
                  <v-flex md8>
                      <v-btn rounded style="margin-top:15px" color="primary" large v-on:click="send('APP')">
                          <v-icon dark style="margin-right:10px">mdi-plus</v-icon>
                          Add Data
                      </v-btn>
                  </v-flex>
                  <v-flex md4>
                      <v-col cols="12" sm="6" md="12">
                        <v-text-field
                          
                          v-model="val"
                           v-on:keyup.enter="getData()"
                          placeholder="Enter Your Keywords"
                          solo
                          append-icon="search"
                        ></v-text-field>
                      </v-col>
                  </v-flex>

                </v-layout>   
            </div>
        </div>
      </div>

    </template>

    <template v-slot:item.action="{ item }">
      <v-icon
        medium
        class="mr-2"
        @click="edit(item)"
      >
        mdi-file-edit
      </v-icon>

      <v-icon
        medium
        class="mr-2"
        @click="getDetail(item)"
      >
         mdi-delete
      </v-icon>
    </template>
  </v-data-table>
  </v-app>
</template>


<script>
export default {
    methods: {
        getData(){
         var self = this;
          var val  = self.val; 
         
          var url  = self.base_url + "master/profit_center";
          this.axios.post(url , {
              param:val,
          })
          .then(function (response) {
              self.desserts = response.data;
          });
      }
    },
    mounted(){
        this.getData();
    },
    data () { 
      return {
        name : "ProfitCenter",
        title : "Data Profit Center",
        dialog: false,
        widgets: false,
        singleSelect: false,
        selected: [],
        showSelect:false,
        val:'',
        headers: [
          { text: 'Profit Center ID', value: 'PROFIT_CENTER_CODE',width:250},
          { text: 'Profit Center Name', value: 'PROFIT_CENTER_DESC'},
          { text: 'Material Type', value: 'MATERIAL_TYPE'},
          { text: 'Server Code', value: 'SERVER_CODE'},
          { text: 'Action', value: 'action', sortable: false,width:120},
          
        ],
        desserts: [],
        modal_detail:false,
        detail:[],

        //storage.....................................
        storage_user_id : localStorage.getItem("user_id"),
        storage_username : localStorage.getItem("username"),
        storage_fullname : localStorage.getItem("fullname"),
        storage_company_id : localStorage.getItem("company_id"),
        storage_company_name : localStorage.getItem("company_name"),
        storage_role : localStorage.getItem("role"),
        storage_departement_id : localStorage.getItem("departement_id"),
        
      }
    },
  }
</script>
