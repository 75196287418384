<style>
  .fltable td{
      vertical-align: top;  
  }

  .v-data-table__empty-wrapper{
    display:none !important;
  }
</style>
<template>
  <v-app>
  <v-data-table
     hide-default-header
    hide-default-footer
    style="padding:25px">

    <template v-slot:top>
      <div>
          <div>
            <v-toolbar style="box-shadow:none !important">
              <v-toolbar-title><b>{{title}}</b></v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>

            <div style="padding:0px 25px">
                <v-layout row>
                  

                  <v-flex md3>
                      <div style="padding-top:10px">
                          <v-menu
                              ref="menu"
                              v-model="menu"
                              :close-on-content-click="false"
                              :return-value.sync="date"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="date"
                                  label="Start Date"
                                  append-icon="event"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="date" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                                <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                              </v-date-picker>
                            </v-menu>
                        </div>
                  </v-flex>

                  <v-flex md3 style="padding-left:30px">
                       <v-select
                           style="margin-top:15px;"
                          item-text="SERVER_CODE"
                          item-value="SERVER_CODE"
                          v-model="server"
                          :items="list_server_code"
                          label="Choose Server"
                          v-bind:value="{ id: SERVER_CODE, text: SERVER_CODE }"
                        ></v-select> 
                  </v-flex>


                  <v-flex md3 style="padding-left:30px">
                      <v-btn style="margin-top:15px;"  
                      v-on:click="sapProcess()" 
                      :loading="loading"
                      color="primary"
                      large>
                        <v-icon dark style="margin-right:10px">mdi-check-circle-outline</v-icon>
                        Get Initial From SAP
                      </v-btn>
                  </v-flex>

                </v-layout>
              
              <br>
              <div style="font-size:20px;padding:10px 0px">
                Status
              </div>
              <div style="padding:30px;background-color:#000;color:#fff;height:400px;max-height:600px;overflow-y:scroll">
                <div>{{status_temp}}</div>
                <div>{{status_proses}}</div>
                <div>{{status_clear_temp}}</div>
              </div>

            </div>
        </div>
      </div>
      
      
    </template>

  </v-data-table>
  </v-app>
</template>


<script>
export default {
    methods: {
        sapProcess_temp(){
              var self = this;
              var date = self.date;
              var server = self.server;
              
              self.status_temp = "";
              self.status_proses = "";
              self.status_clear_temp = "";

              var url = this.base_url + 'sap/get_param/'+server+'/'+date;
              self.loading = true;
              self.axios.get(url).then(function (response) {
                  if(response.data==true) {
                      
                      self.status_temp = "Data Successfully Dragged on Temporary";

                       //Proses Temp..............................................................
                      var url_proses = self.base_url + 'sap/proses';
                      self.axios.get(url_proses).then(function (response) {
                            if(response.data==true) {
                                self.status_proses = "Data Successfully Copy To Initial Item";
                                
                                //Clear Temp..............................................................
                                var url_clear = self.base_url + 'sap/clear_temp';
                                self.axios.get(url_clear).then(function (response) {
                                    if(response.data==true) {
                                      self.status_clear_temp = "Initial Temp Succesfully Clear";
                                      self.loading = false;
                                    }else{
                                      self.status_clear_temp = "Initial Temp Failed Clear"
                                      self.loading = false;
                                    }
                                });
                            }else{
                              alert("Failed Copy to Initial Item");
                              self.status_proses = "Failed Copy to Initial Item";
                              self.loading = false;
                            }

                      }).catch(function (error) {
                          self.result = error;
                          alert("Failed Copy to Initial Item");
                          self.loading = false;
                      });

                  }else{
                    self.status_temp = "Data Failed Dragged on Temporary"
                    self.loading = false;
                  }

              }) .catch(function (error) {
                  self.result = error;
                  alert('Failed Dragged on Temporary');
                  self.loading = false;
              });
        },
        sapProcess(){
              var self = this;
              //var date = self.date;
              var server = self.server;
              
              self.status_temp = "";
              self.status_proses = "";
              self.status_clear_temp = "";

              var url = this.base_url + 'sap/get_initial/'+server;
              self.loading = true;
              self.axios.get(url).then(function (response) {
                  if(response.data==true) {
                      
                      self.status_temp = "Data Successfully Dragged on Temporary";
                      self.loading = false;
                  }else{
                    self.status_temp = "Data Failed Dragged on Temporary"
                    self.loading = false;
                  }

              }) .catch(function (error) {
                  self.result = error;
                  alert('Failed Dragged on Temporary');
                  self.loading = false;
              });
        },
         getServer(){
            var self = this;
            var url = this.base_url + "dropdown/dropdynamic/ts_server_setting/USER_ID/"+self.storage_user_id; 
            this.axios.get(url).then(function (response) {
              self.list_server_code = response.data;
            })
          }
    },
    mounted(){
        if(this.storage_role !='ADM'){
          this.$router.push('/login');
      }
    },
    data () { 
      return {
        name : "Registration",
        title : "Initial Item SAP",
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
        singleSelect: true,
        selected: [],
        showSelect:false,
        loading:false,
        sap_dialog:false,
        json_sap:'',
        total_row:'',
        server : "",
        date : "",
        list_server_code : ['GEMS','BKES','BC'],
        status_temp : "",
        status_proses : "",
        status_clear_temp : "",

        //storage.....................................
        storage_user_id : atob(localStorage.getItem("user_id")),
        storage_username : atob(localStorage.getItem("username")),
        storage_fullname : atob(localStorage.getItem("fullname")),
        storage_company_id : atob(localStorage.getItem("company_id")),
        storage_company_name : atob(localStorage.getItem("company_name")),
        storage_role : atob(localStorage.getItem("role")),
        storage_departement_id : atob(localStorage.getItem("departement_id")),
        
      }
    },
  }
</script>
