<template>
    <div style="padding:40px">
      <v-layout>
          <v-flex md4 style="padding:10px">
              <v-card
                class="mx-auto"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="headline">{{storage_fullname}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <!--<v-card-text>
                  <v-row align="center">
                    <v-col class="display-2" cols="6">
                      {{storage_server}}
                    </v-col>
                    <v-col cols="6">
                      <v-img
                        src="../../assets/database.png"
                        alt="Sunny image"
                        width="92"
                      ></v-img>
                    </v-col>
                  </v-row>
                </v-card-text>-->

                <v-list-item>
                  
                  <v-list-item-subtitle>PT. Sinarmas Mining</v-list-item-subtitle>
                </v-list-item>

                <v-list class="transparent">
                  <v-list-item
                    v-for="item in forecast"
                    :key="item.day"
                  >
                    <v-list-item-title>{{ item.day }}</v-list-item-title>

                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-subtitle class="text-right">
                      {{ item.temp }}
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn text v-on:click="getModal()">Change Password</v-btn>
                </v-card-actions>
              </v-card>
          </v-flex>

          <v-flex md8 style="padding:10px">
              <v-card
                  class="mx-auto"
                >
                  <v-toolbar
                    color="red"
                    dark
                  >
                   
                    <v-toolbar-title>Item Process</v-toolbar-title>

                    <v-spacer></v-spacer>
                  </v-toolbar>

                  <v-list subheader>
                    <v-subheader>Item Registration Process</v-subheader>

                    <v-list-item
                      v-for="item in list_dashboard"
                      :key="item.ITEM_STATUS"
                    >
                      <v-list-item-avatar>
                        <v-img src="../../assets/folder.png"></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title v-text="item.STATUS_DESC"></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-icon>
                            <div align="center"><b>{{item.TOTAL}}</b></div>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-card>
          </v-flex>
      </v-layout>

      <v-dialog
        v-model="modal_password"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            Change Password
          </v-card-title>
          <v-card-text>
              <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              >
                <div style="padding:10px">
                      <label>
                          Password
                      </label>

                      <v-text-field
                        label="Password"
                        v-model="password"
                        placeholder="Enter your password"
                        name="password"
                        append-icon="lock"
                        type="password"
                        :rules="rules"
                        solo
                      />

                      <label>
                          New Password
                      </label>

                      <v-text-field
                        label="Password"
                        v-model="new_password"
                        placeholder="Enter new password"
                        name="password"
                        append-icon="lock"
                        type="password"
                        :rules="rules"
                        solo
                      />

                      <label>
                          Re Password
                      </label>

                      <v-text-field
                        label="Re Password"
                        v-model="re_password"
                        placeholder="Enter re new password"
                        name="password"
                        append-icon="lock"
                        :rules="rules"
                        type="password"
                        solo
                      />

                  </div>

                  <div align="right">
                      <v-btn
                      color="red"
                      text
                      :loading="loading"
                      v-on:click="update_password()"
                      >
                        Ok
                      </v-btn>

                      <v-btn
                      color="red"
                      text
                      @click="modal_password = false"
                      >
                        Close
                      </v-btn>
                </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>


    
</template>


<script>
export default {
    methods: {
        getData(){
           var self = this;
           var url  = self.base_url + "dashboard/home";

           self.axios.post(url , {
                user_id : self.storage_user_id,
                role : self.storage_role
            })
            .then(function (response) {
                self.list_dashboard = response.data;
            }); 
        },
        getModal(){
          this.modal_password = true;
        },
        update_password(){
            var self = this;
            var url  = self.base_url + "mail/update_password";
            var password = self.password;
            var new_password = self.new_password;
            var re_password = self.re_password;
            
            if (self.$refs.form.validate()) {
                if(re_password != new_password){
                    alert("New password should be different than old password");
                    return false;
                }

                self.axios.post(url , {
                      password : password,
                      new_password : self.new_password,
                      user_id : self.storage_user_id
                })
                .then(function (response) {
                    if(response.data==true){
                      alert("Change password successfully, Please Login again ");
                      self.modal_password = false;
                      self.logout();
                    }else{
                      alert(response.data);
                    }
                });
            }
        },
        logout () {
          window.localStorage.setItem('username', "");
          window.localStorage.setItem('fullname', "");
          window.localStorage.setItem('role', "");
          window.localStorage.setItem('departement_id', "");
          window.localStorage.setItem('departement_desc', "");
          window.localStorage.setItem('company_id', "");
          window.localStorage.setItem('company_name', "");
          this.$router.push('/login');
        },
    },
    mounted(){
      this.getData();
    },
    data () { 
      return {
        name : "Dashboard",
        title : "Approval Material",
        list_dashboard : [],
        loading:false,
        modal_password:false,
        items: [
          { active: true, title: 'ROLLER,BEARING', avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',total:'Draft',color:'primary' },
          { active: true, title: 'AMPLIFIER,TRIGGER PULSE', avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',total:'Approval 1',color:'teal' },
          { active: true, title: 'CABLE ASSEMBLY,TELEPHONE', avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',total:'Approval 2',color:'teal' },
          { active: true, title: 'PHOTOELECTRIC CELL', avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',total:'Need Info',color:'orange' },
          { active: true, title: 'CONTACT SET,MOTOR STARTER', avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',total:'Draft',color:'primary' },
          { active: true, title: 'CONTACT SET,MOTOR STARTER', avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',total:'Draft',color:'primary' },
          { active: true, title: 'CONTACT SET,MOTOR STARTER', avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',total:'Draft',color:'primary' },
        ],
        items2: [
          { title: 'Travis Howard', avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg' },
        ],
        labels: ['SU', 'MO', 'TU', 'WED', 'TH', 'FR', 'SA'],
        time: 0,
        forecast: [
          { day: 'Name', icon: '', temp: atob(localStorage.getItem("fullname")) },
          { day: 'Username', icon: '', temp: atob(localStorage.getItem("username")) },
          { day: 'Departement', icon: '', temp: atob(localStorage.getItem("departement_desc")) },
          { day: 'Email', icon: '', temp: atob(localStorage.getItem("email")) },
          { day: 'Role', icon: '', temp: atob(localStorage.getItem("role")) },
        ],

        //Password model
        password:"",
        new_password :"",
        re_password : "",
        rules : [
           v => !!v || 'Form is required',
           //v => (v && v.length <= 10) || 'This form must be less than 10 characters',
         ],

        //storage.....................................
        storage_user_id : atob(localStorage.getItem("user_id")),
        storage_username : atob(localStorage.getItem("username")),
        storage_fullname : atob(localStorage.getItem("fullname")),
        storage_company_id : atob(localStorage.getItem("company_id")),
        storage_company_name : atob(localStorage.getItem("company_name")),
        storage_role : atob(localStorage.getItem("role")),
        storage_server : atob(localStorage.getItem("server")),
        storage_departement_id : atob(localStorage.getItem("departement_id")),
      }
    },
  }
</script>
