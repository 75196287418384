<style>
  a{
    text-decoration: none;
  }
</style>
<template>
  <v-app>
    <v-app-bar
      app
      style="background:linear-gradient(to right, #cc0000, #E1ACA3);"
      dark
    >
      <router-link to='/'>
        <div class="d-flex align-center">
            <v-img
              alt="Vuetify Name"
              class="shrink mt-1 hidden-sm-and-down"
              contain
              min-width="100"
              src="./assets/logo-em.png"
              width="150"
            />
        </div>
      </router-link>

      <router-link to='/'>
          <v-btn text style="margin-left:20px;">
            <span class="mr-2">Home</span>
          </v-btn>
      </router-link>

      <div v-if="role=='ADM'">    
          <template>
            <v-menu top offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    dark
                    v-on="on"
                  >
                    Master Data 1
                  </v-btn>
                </template>
                
                <v-list>
                  <v-list-item-group>
                    <v-list-item to='/server'>
                      <v-list-item-title style="width:200px">Server</v-list-item-title>
                    </v-list-item>                  
                    
                    <v-list-item to='/company'>
                      <v-list-item-title style="width:200px">Company</v-list-item-title>
                    </v-list-item>
                    
                    <v-list-item to='/plant'>
                      <v-list-item-title style="width:200px">Plant</v-list-item-title>
                    </v-list-item>
                    
                    <v-list-item to='/storageLocation'>
                      <v-list-item-title style="width:200px">Storage Location</v-list-item-title>
                    </v-list-item>

                    <v-list-item to='/uom'>
                      <v-list-item-title style="width:200px">UOM</v-list-item-title>
                    </v-list-item>
                    
                    <v-list-item to='/profitCenter' style="display:none">
                      <v-list-item-title style="width:200px">Profit Center</v-list-item-title>
                    </v-list-item>

                    <v-list-item to='/materialType'>
                      <v-list-item-title style="width:200px">Material Type</v-list-item-title>
                    </v-list-item>
                    
                    <v-list-item to='/valuationClass'>
                      <v-list-item-title style="width:200px">Valuation Class</v-list-item-title>
                    </v-list-item>

                    <v-list-item to='/priceControl'>
                      <v-list-item-title style="width:200px">Price Control</v-list-item-title>
                    </v-list-item>

                    <v-list-item to='/labOffice'>
                      <v-list-item-title style="width:200px">Lab Office</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
            </v-menu>
          </template>


          <template>
            <v-menu top offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    dark
                    v-on="on"
                  >
                    Master Data 2
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item-group>
                    
                    <v-list-item to='/materialGroup'>
                      <v-list-item-title style="width:200px">Material Group</v-list-item-title>
                    </v-list-item>
                    
                    <v-list-item to='/externalGroup'>
                      <v-list-item-title style="width:200px">External Group</v-list-item-title>
                    </v-list-item>

                    <v-list-item to='/inc'>
                      <v-list-item-title style="width:200px">INC</v-list-item-title>
                    </v-list-item>
                    <v-list-item to='/incAttribute'>
                      <v-list-item-title style="width:200px">INC Attribute</v-list-item-title>
                    </v-list-item>

                    <v-list-item to='/initialItem'>
                      <v-list-item-title style="width:200px">Initial Item</v-list-item-title>
                    </v-list-item>
                    
                    <v-list-item to='/serviceInitial'>
                      <v-list-item-title style="width:200px">Initial SAP</v-list-item-title>
                    </v-list-item>
                    
                  </v-list-item-group>
                </v-list>
            </v-menu>
          </template>

          <template>
            <v-menu top offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    dark
                    v-on="on"
                  >
                    User
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item-group>
                    <v-list-item to='/role' style="display:none">
                      <v-list-item-title style="width:200px">Role</v-list-item-title>
                    </v-list-item>
                    <v-list-item to='/departement'>
                      <v-list-item-title style="width:200px">Department</v-list-item-title>
                    </v-list-item>
                     <v-list-item to='/user'>
                      <v-list-item-title style="width:200px">User</v-list-item-title>
                    </v-list-item>
                     <v-list-item to='/departementRole'>
                      <v-list-item-title style="width:200px">Approval 1 Role</v-list-item-title>
                    </v-list-item>
                     <v-list-item to='/cataloguerRole'>
                      <v-list-item-title style="width:200px">Approval 2 Role</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
            </v-menu>
          </template>
          
      </div>
      
      <div v-if="role=='REQ'">
          <router-link to='/searchRegister'>
              <v-btn text retain-focus-on-click="true">
                <span class="mr-2">Item Search</span>
              </v-btn>
          </router-link>

          <router-link to='/listRegister'>
              <v-btn text retain-focus-on-click="true">
                <span class="mr-2">Registration List</span>
              </v-btn>
          </router-link>

          <router-link to='/averageSLAUser'>
              <v-btn text retain-focus-on-click="true">
                <span class="mr-2">SLA Report</span>
              </v-btn>
          </router-link>
      </div>


      <div v-if="role=='APP1' || role=='APP2'">
        <router-link to='/listApprove'>
            <v-btn text retain-focus-on-click="true">
              <span class="mr-2">Approval</span>
            </v-btn> 
        </router-link>
      </div>
      
      <div v-if="role=='CAT'">
          <router-link to='/listCatalog'>
              <v-btn text retain-focus-on-click="true">
                <span class="mr-2">Cataloguing</span>
              </v-btn>
          </router-link>
      </div>

      <template v-if="role=='CAT' || role=='ADM'">
            <v-menu top offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    dark
                    v-on="on"
                  >
                    Dashboard
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item-group>
                    <v-list-item to='/RequestRatio'>
                      <v-list-item-title style="width:200px">Done / Request Ratio</v-list-item-title>
                    </v-list-item>
                    <v-list-item to='/AverageSLA'>
                      <v-list-item-title style="width:200px">SLA Report</v-list-item-title>
                    </v-list-item>
                    <v-list-item to='/ApproveRatio'>
                      <v-list-item-title style="width:200px">Approve / Rejected Ratio</v-list-item-title>
                    </v-list-item>
                     <v-list-item to='/TopRequester'>
                      <v-list-item-title style="width:200px">Top Requester</v-list-item-title>
                    </v-list-item>
                    <!--<v-list-item to='/TopApprover'>
                      <v-list-item-title style="width:200px">Top Aprovers</v-list-item-title>
                    </v-list-item>
                    <v-list-item to='/TopComplain'>
                      <v-list-item-title style="width:200px">Top Complain Category</v-list-item-title>
                    </v-list-item>-->
                  </v-list-item-group>
                </v-list>
            </v-menu>
          </template>

      <v-spacer></v-spacer>


      <template>
        <div class="text-center">
          <v-menu offset-y transition="slide-y-transition">
            <template v-slot:activator="{ on }">
                <v-btn text v-on="on">
                  <v-icon>mdi-account-circle</v-icon>
                </v-btn>
            </template>
            <v-card>
             <v-list>
                 <v-list-item-group>
                  <v-list-item>
                    <v-list-item-title style="width:250px">
                      <v-icon>mdi-account-circle</v-icon> {{fullname}}
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item
                    v-for="(item, index) in setting"
                    :key="index"
                    :to="item.link"
                  >
                    <v-list-item-title style="width:250px">{{ item.title }}</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                     v-on:click="logout()"
                  >
                    <v-list-item-title style="width:250px">Logout</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </template>

    </v-app-bar>
    
    <v-content>
      <div><router-view />
      </div>   
    </v-content>

    <div align="center" style="padding:20px;color:grey">
      Copyright&copy; 2020 IMaster Sinarmas Mining
    </div>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  methods:{
    logout () {
      window.localStorage.setItem('username', "");
      window.localStorage.setItem('fullname', "");
      window.localStorage.setItem('role', "");
      window.localStorage.setItem('departement_id', "");
      window.localStorage.setItem('departement_desc', "");
      window.localStorage.setItem('company_id', "");
      window.localStorage.setItem('company_name', "");
      this.$router.push('/login');
    },
    login_check(){
        var username = localStorage.getItem("username");
        var fullname = localStorage.getItem("fullname");
        var server = localStorage.getItem("server");
        var role = localStorage.getItem("role");
        
        if(!username || !fullname || !role || !server){
            this.$router.push('/login');
        }
    }
  },
  mounted(){  
      this.login_check();
  },
  data: () => ({ 
      items: [
        { title: 'Master User' },
        { title: 'Master Brand' },
        { title: 'Master UOM' },
        { title: 'Master Type' },
        { title: 'Master Division' },
        
        { title: 'Master Unit' },
        { title: 'Master Role' },
        { title: 'Master Color' }
      ],
      setting: [
        {title: atob(localStorage.getItem("departement_desc"))},
        {title: 'Setting'}
      ],
      username : atob(localStorage.getItem("username")),
      departement_desc : atob(localStorage.getItem("departement_desc")),
      fullname : atob(localStorage.getItem("fullname")),
      role : atob(localStorage.getItem("role"))
  }),
};
</script>
