<style>
  .hello {
    height: 600px !important; 
    margin-top:20px
  }
</style>

<template>
    <div style="padding:40px;background-color:#fff">
      <div>
          <b><h3>{{title}}</h3></b>
      </div>
      <br>
      <v-layout row>
         <v-toolbar style="box-shadow:none;background-color:#fff">
            
            <v-layout>
              
              <v-flex md2>
                  <div style="padding-top:10px">
                      <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="date"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="date"
                              label="Start Date"
                              append-icon="event"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                            <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                          </v-date-picker>
                        </v-menu>
                    </div>
              </v-flex>

              <v-flex md2>
                 <div style="margin-left:20px;padding-top:10px">
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :return-value.sync="date2"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="date2"
                            label="End Date"
                            append-icon="event"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="date2" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu2 = false">Cancel</v-btn>
                          <v-btn text color="primary" @click="$refs.menu2.save(date2)">OK</v-btn>
                        </v-date-picker>
                      </v-menu>
                  </div>   
              </v-flex>

              

              <v-flex md2>
                 <div style="margin-left:20px;padding-top:10px">
                      <v-select 
                        item-text="SERVER_NAME"
                        item-value="SERVER_CODE"
                        v-model="server_code"
                        :items="list_server_code"
                        :rules="rules"
                        label="Choose Server"
                        v-on:change="getCompany(),nullCompany()"
                      ></v-select>
                  </div>   
              </v-flex>

              <v-flex md3>
                 <div style="margin-left:20px;padding-top:10px">
                      <v-autocomplete 
                        item-text="COMPANY_NAME"
                        item-value="COMPANY_ID"
                        v-model="company_id" 
                        :items="list_company"
                        :rules="rules"
                        label="Choose Company"
                        v-bind:value="{ id: COMPANY_ID, text: COMPANY_NAME }"
                      ></v-autocomplete>
                  </div>   
              </v-flex>

              <v-flex md3>
                 <div style="margin-left:20px;padding-top:10px">
                      <v-autocomplete 
                        item-text="DEPARTEMENT_NAME"
                        item-value="DEPARTEMENT_ID"
                        v-model="departement_id"
                        :items="list_departement"
                        :rules="rules"
                        label="Choose Department"
                        v-bind:value="{ id: DEPARTEMENT_ID, text: DEPARTEMENT_DESC }"
                      ></v-autocomplete>
                  </div>   
              </v-flex>

              <v-flex md12>
                  <div style="margin-left:0px" align="right">
                    <v-btn color="primary" v-on:click="getData">
                      Search
                    </v-btn>
                    &nbsp;
                    <v-btn color="success" dark v-on:click="reset">
                      All Data
                    </v-btn>
                  </div>
              </v-flex>

            </v-layout>
                
         </v-toolbar>
      </v-layout>
      <br>
      <div class="hello" id="chartdiv"></div>

      <div style="padding:15px;display:none">
          <template>
            <v-data-table
              :headers="headers"
              :items="desserts"
              :items-per-page="5"
              class="elevation-1"
            ></v-data-table>
          </template>
      </div>
    </div>
</template>



<script>

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);


export default {
    methods: {
        getData(){
          var self = this;
          var url  = self.base_url + "dashboard/approve_ratio";

          self.axios.post(url , {
              departement_id : self.departement_id,
              startdate : self.date,
              enddate : self.date2,
              server_code : self.server_code,
              company_id : self.company_id
          })
          .then(function (response) {
              var waiting_sap =  response.data[0].WAITING_SAP ?  response.data[0].WAITING_SAP : 0;
              var rejected = response.data[0].REJECTED ? response.data[0].REJECTED : 0;

              self.waiting_sap = waiting_sap;
              self.rejected = rejected;

              self.chart();
              
          });
        },
        reset(){
            var self = this;
            self.departement_id = "";
            self.date = "";
            self.date2 = "";
            this.getData();
        },
        chart(){
          var self = this;
          am4core.ready(function() {

              // Themes begin
              am4core.useTheme(am4themes_animated);
              // Themes end

              var chart = am4core.create("chartdiv", am4charts.PieChart3D);
              chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

              chart.legend = new am4charts.Legend();

              chart.data = [
                {
                  country: "Waiting Sent to SAP",
                  litres: self.waiting_sap
                },
                {
                  country: "Rejected",
                  litres: self.rejected
                },
              ];

              var series = chart.series.push(new am4charts.PieSeries3D());
              series.dataFields.value = "litres";
              series.dataFields.category = "country";

              var colors = ["#03af70","#fe1f1f"];
              var colorset = new am4core.ColorSet();
              colorset.list = [];
              for(var i=0;i<colors.length;i++)
                  colorset.list.push(new am4core.color(colors[i]));
                  series.colors = colorset;  
              }); 
        },
        getDepartement(){
          var self = this;
          var url = self.base_url + "dropdown/departement_dashboard";
          self.axios.get(url).then(function (response) {
              self.list_departement = response.data;
          })
        },
        getServer(){
          var self = this;
          var url = this.base_url + "dropdown/server_dashboard"; 
          this.axios.get(url).then(function (response) {
            self.list_server_code = response.data;
          })
        },
        getCompany(){  
          var self = this;
          var server_code = self.server_code;
          if(server_code==''){
            server_code = '-'
          }
          var url = this.base_url + "dropdown/company_dashboard/"+server_code; 
          this.axios.get(url).then(function (response) {
            self.list_company = response.data;
          });
        },
        getDateMonth(){
          var today=new Date()
          var year=today.getFullYear()
          var month=today.getMonth()
          var date=today.getDate()
          if((month-1)<=0)
          year=today.getFullYear()
          var backdate = new Date(year,month-1,date).toISOString().substr(0, 10);
          this.date = backdate;
        }
    },
    mounted(){
        //this.getMaterial();
        if(this.storage_role !='CAT' && this.storage_role !='ADM'){
          this.$router.push('/login');
        }
         
         this.getDateMonth();
         this.getData();
         this.getDepartement();
         this.getServer();
         this.getCompany();
    },
    data () { 
      return {
        name : "ApproveRatio",
        title : "Approve / Reject Ratio",
        items: [],
        desserts : [],
        headers: [
          { text: 'Action', value: 'action', sortable: false,width:150},
          { text: 'Approval', value: 'status'},
          { text: 'Total Item', value: 'status'},
        ],
        list_company : [{COMPANY_ID:'',COMPANY_NAME:'All Company'}],
        list_server_code : [],
        list_departement : [{DEPARTEMENT_ID:'',DEPARTEMENT_NAME:'All Departement'}],
        departement_id:"",
        server_code : "",
        company_id : "",
        date:"",
        date2:new Date().toISOString().substr(0, 10),
        waiting_sap : 10,
        rejected : 10,
      
        //storage.....................................
        storage_user_id : atob(localStorage.getItem("user_id")),
        storage_username : atob(localStorage.getItem("username")),
        storage_fullname : atob(localStorage.getItem("fullname")),
        storage_company_id : atob(localStorage.getItem("company_id")),
        storage_company_name : atob(localStorage.getItem("company_name")),
        storage_role : atob(localStorage.getItem("role")),
        storage_server : atob(localStorage.getItem("server")),
        storage_departement_id : atob(localStorage.getItem("departement_id")),
      }
    },
  }
</script>
