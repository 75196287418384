<template>
  <v-app>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="desserts"
    :single-select="singleSelect"
    item-key="INC_CODE"
    show-select
    :loading="loading"
    style="padding:25px">

    <template v-slot:top>
      <div>
          <div>
            <v-toolbar style="box-shadow:none !important">
              <v-toolbar-title><b>{{title}}</b></v-toolbar-title>
              <v-spacer></v-spacer>
              
              <v-spacer></v-spacer>
              <v-breadcrumbs>Master Data / INC</v-breadcrumbs>
              <v-btn icon v-on:click="getData">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-toolbar>

            <div style="padding:0px 25px">
                <v-layout row>
                  <v-flex md8>
                      <v-btn rounded style="margin-top:15px" color="primary" large v-on:click="modalForm()">
                          <v-icon dark style="margin-right:10px">mdi-plus</v-icon>
                          Add Data
                      </v-btn>
                  </v-flex>
                  <v-flex md4>
                      <v-col cols="12" sm="6" md="12">
                        <v-text-field
                          
                          v-model="val"
                          v-on:keyup.enter="getData()"
                          placeholder="Enter Your Keywords"
                          solo
                          append-icon="search"
                        ></v-text-field>
                      </v-col>
                  </v-flex>

                </v-layout>   
            </div>
        </div>
      </div>

       <v-dialog
        v-model="modal_form"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            INC
          </v-card-title>
          <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                  <div style="padding:10px">
                      <label>
                          INC Code
                      </label>
                      <v-text-field
                        v-model="inc_code"
                        :rules="rules_inc"
                        :disabled="disabled"
                        placeholder="Enter INC Code"
                        required
                        solo
                      ></v-text-field>

                      <label>
                          INC Name
                      </label>
                      <v-text-field
                        v-model="inc_name"
                        :rules="rules"
                        placeholder="Enter INC Name"
                        required
                        solo
                      ></v-text-field>

                      <label>
                          Short Name
                      </label>
                      <v-text-field
                        v-model="short_name"
                        :rules="rules"
                        placeholder="Enter Short Name"
                        required
                        solo
                      ></v-text-field>

                      <label>
                          Colloquial Name
                      </label>
                      <v-text-field
                        v-model="colloquial_name"
                        placeholder="Enter Colloquial Name"
                        required
                        solo
                      ></v-text-field>

                      <label>
                          Material Group
                      </label>
                      <v-select
                        :items="material_group"
                        v-model="selected_material_group"
                        @change="getExternalMaterialGroup"
                        return-object
                        :rules="rules"
                        item-text="MATERIAL_GROUP_NAME"
                        label="Select Material Group"
                        required
                        solo
                      ></v-select>

                      <label>
                          External Material Group
                      </label>
                      <v-select
                        :items="external_material_group"
                        v-model="selected_external_material_group"
                        item-text="EXTERNAL_GROUP_NAME"
                        :rules="rules"
                        return-object
                        label="Select Material Group"
                        required
                        solo
                      ></v-select>
                  </div>
                        
                  <div align="right">
                      
                      <v-btn
                      color="primary"
                      text
                      @click="modal_form = false"
                      >
                        Close
                      </v-btn>

                      <v-btn
                      color="primary"
                      text
                      :loading="loading"
                      v-on:click="processForm"
                      >
                        Process
                      </v-btn>

                </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

    </template>

    <template v-slot:item.status="{ item }">
        <v-chip color="success" v-if="item.STATUS=='Y'">Active</v-chip>
        <v-chip color="error" v-if="item.STATUS=='N'">Inactive</v-chip>
    </template>

    <template v-slot:item.action="{ item }">
      <v-icon
        medium
        class="mr-2"
        @click="edit(item)"
      >
        mdi-file-edit
      </v-icon>

      <v-icon
        medium
        class="mr-2"
        color="error"
        @click="changeStatus(item,'N')"
        v-if="item.STATUS=='Y'"
      >
         mdi-close-circle
      </v-icon>

      <v-icon
        medium
        class="mr-2"
        color="success"
        @click="changeStatus(item,'Y')"
        v-if="item.STATUS=='N'"
      >
         mdi-check-circle
      </v-icon>

    </template>
  </v-data-table>
  </v-app>
</template>


<script>
export default {
    methods: {
        getData(){
         var self = this;
          const value  = self.val; 
         
          var url  = self.base_url + "master/inc";
          this.axios.post(url , {
            param: value,
          })
          .then(function (response) {
              self.desserts = response.data;
              self.loading=false
          });
      },
      async getMaterialGroup(){
          const url = this.base_url + "dropdown/material_group/"+'BC';
          const resp = await this.axios.get(url);
          this.material_group = resp.data;
      },
      async getExternalMaterialGroup(){
          const id = this.selected_material_group.MATERIAL_GROUP_ID;
          const url = this.base_url + "dropdown/external_group/"+id;
          const resp = await this.axios.get(url);
          this.external_material_group = resp.data;
          this.selected_external_material_group = '';
      },
      async modalForm(){
        var self = this;
        self.modal_form = true;
        self.reset();
        self.disabled = false;
      },
      async edit(item){
          var self = this;
          self.modal_form = true;
          self.inc_code = item.INC_CODE;
          self.inc_name = item.INC_NAME;
          self.short_name = item.SHORT_NAME;
          self.colloquial_name = item.COLLOQUIAL_NAME;
          self.selected_material_group = this.material_group.find(matGroup => matGroup.MATERIAL_GROUP_ID == item.MATERIAL_GROUP) || '';
          await this.getExternalMaterialGroup();
          self.selected_external_material_group = this.external_material_group.find(extMatGroup => extMatGroup.EXTERNAL_GROUP_CODE == item.EXTERNAL_MATERIAL_GROUP) || '';
          self.type = "UPDATE";
          self.disabled = true;
      },
      reset () {
        this.inc_code = "";
        this.external_material_group = [];
        this.$refs.form.reset();
        this.type = "INSERT";
      },
      processForm(){
        var self = this;
        console.log("woiz")
        console.log(this.inc_code)
        // if(this.inc_code)
        if (self.$refs.form.validate()) {
          var type = self.type;
          var url = "";
          self.loading = true;

          if(type=="UPDATE"){
              url  = self.base_url + "master/update_inc";
          }else{
              url  = self.base_url + "master/add_inc";
          }
          
          self.axios.post(url , {
              inc_code : self.inc_code,
              inc_name : self.inc_name,
              short_name : self.short_name,
              colloquial_name : self.colloquial_name,
              material_group: self.selected_material_group.MATERIAL_GROUP_ID,
              external_material_group: self.selected_external_material_group.EXTERNAL_GROUP_CODE,
              type: self.type,

          })
          .then(function (response) {
              if(response.data==true){
                self.modal_form = false;
                alert("Data succesfully saved");

              }else{
                alert(response.data)
              }
              self.getData();
              self.loading = false;
          });
        }
      },
      changeStatus(item,status){
        var self = this;
        var url  = self.base_url + "master/change_status";
         
        var text_confirm = 'Are you sure you want to Deactivated this data ?';
        
        if(status=='Y'){
          text_confirm = 'Are you sure you want to Activated this data ?';
        }

        this.$dialog.confirm({
          text: text_confirm,
          overlay:true,
          actions: {
              false: 'No',
              true: {
                color: 'red',
                text: 'Yes',
                handle: () => {
                     self.axios.post(url , {
                        id : item.ID,
                        status : status,
                        table : 'tm_inc',
                        field : 'ID'
                    })
                    .then(function (response) {
                        alert(response.data);
                        self.getData();
                    });                   
                }
              }
            }
        });
      }
    },
    mounted(){
        this.getData();
        this.getMaterialGroup();
        this.getExternalMaterialGroup();
    },
    data () { 
      return {
        name : "Inc",
        title : "Data INC",
        dialog: false,
        widgets: false,
        singleSelect: false,
        selected: [],
        showSelect:false,
        loading:true,
        val:'',
        headers: [
          { text: 'INC Code', value: 'INC_CODE',width:150},
          { text: 'INC Name', value: 'INC_NAME',width:200},
          { text: 'Shortname', value: 'SHORT_NAME',width:200},
          { text: 'Colloquial Name', value: 'COLLOQUIAL_NAME'},
          { text: 'Material Group', value: 'MATERIAL_GROUP_NAME'},
          { text: 'External Material Group', value: 'EXTERNAL_GROUP_NAME'},
          { text: 'Status', value: 'status', sortable: true,width:150},
          { text: 'Action', value: 'action', sortable: false,width:120},
          
        ],
        desserts: [],
        modal_detail:false,
        detail:[],
        modal_form:false,
        rules : [
          v => !!v || 'Form is required',
          //v => (v && v.length <= 10) || 'This form must be less than 10 characters',
        ],
        rules_inc : [
          v => !!v || 'Form is required',
          v => /^[a-zA-Z0-9]*$/.test(v) || 'Symbols are not allowed'
          //v => (v && v.length <= 10) || 'This form must be less than 10 characters',
        ],
        list_inc: [],

        //form..............................
        inc_code : "",
        inc_name : "",
        short_name : "",
        colloquial_name : "",
        material_group: '',
        selected_material_group: '',
        external_material_group: '',
        selected_external_material_group: '',
        type : "",
        disabled : false,

        //storage.....................................
        storage_user_id : localStorage.getItem("user_id"),
        storage_username : localStorage.getItem("username"),
        storage_fullname : localStorage.getItem("fullname"),
        storage_company_id : localStorage.getItem("company_id"),
        storage_company_name : localStorage.getItem("company_name"),
        storage_role : localStorage.getItem("role"),
        storage_departement_id : localStorage.getItem("departement_id"),
        
      }
    },
  }
</script>
