<template>
  <v-app>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="desserts"
      :single-select="singleSelect"
      item-key="USER_ID"
      show-select
      style="padding:25px"
    >
      <template v-slot:top>
        <div>
          <div>
            <v-toolbar style="box-shadow:none !important">
              <v-toolbar-title
                ><b>{{ title }}</b></v-toolbar-title
              >
              <v-spacer></v-spacer>

              <v-spacer></v-spacer>
              <v-breadcrumbs>User / User</v-breadcrumbs>
              <v-btn icon v-on:click="getData">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </v-toolbar>

            <div style="padding:0px 25px">
              <v-layout row>
                <v-flex md8>
                  <v-btn
                    rounded
                    style="margin-top:15px"
                    color="primary"
                    large
                    v-on:click="modalForm()"
                  >
                    <v-icon dark style="margin-right:10px">mdi-plus</v-icon>
                    Add Data
                  </v-btn>
                </v-flex>
                <v-flex md4>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      
                      v-model="val"
                      v-on:keyup.enter="getData()"
                      placeholder="Enter Your Keywords"
                      solo
                      append-icon="search"
                    ></v-text-field>
                  </v-col>
                </v-flex>
              </v-layout>
            </div>
          </div>
        </div>

        <v-dialog v-model="modal_form" max-width="500px">
          <v-card>
            <v-card-title>
              Create User
            </v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <div style="padding:10px">
                  <label>
                    Username
                  </label>
                  <v-text-field
                    v-model="username"
                    :rules="rules"
                    :disabled="disabled"
                    placeholder="Enter Username"
                    required
                    solo
                  ></v-text-field>

                  <label>
                    Fullname
                  </label>
                  <v-text-field
                    v-model="fullname"
                    :rules="rules"
                    placeholder="Enter Fullname"
                    required
                    solo
                  ></v-text-field>

                  <label>
                    Email
                  </label>
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    placeholder="Enter Email"
                    required
                    solo
                  ></v-text-field>

                  <label>
                    Department
                  </label>
                  <v-select
                    item-text="DEPARTEMENT_NAME"
                    item-value="DEPARTEMENT_ID"
                    v-model="departement_id"
                    :items="list_departement"
                    :rules="rules"
                    label="Choose Departement"
                    v-bind:value="{
                      id: DEPARTEMENT_ID,
                      text: DEPARTEMENT_DESC,
                    }"
                    solo
                  ></v-select>

                  <label>
                    Role
                  </label>
                  <v-select
                    item-text="ROLE_NAME"
                    item-value="ROLE_ID"
                    v-model="role_id"
                    :items="list_role"
                    :rules="rules"
                    label="Choose Role"
                    v-bind:value="{ id: ROLE_ID, text: ROLE_NAME }"
                    v-on:change="getServer(), nullServer()"
                    solo
                  ></v-select>

                  <div v-if="role_id == 'REQ'">
                    <label>Choose Server</label>
                    <v-toolbar style="box-shadow:none !important">
                      <v-checkbox
                        v-model="checkedServer"
                        label="GEMS"
                        value="GEMS"
                      ></v-checkbox>

                      <v-checkbox
                        v-model="checkedServer"
                        label="BKES"
                        value="BKES"
                        style="margin-left:15px"
                      ></v-checkbox>

                      <v-checkbox
                        v-model="checkedServer"
                        label="BC"
                        value="BC"
                        style="margin-left:15px"
                      ></v-checkbox>
                    </v-toolbar>
                  </div>
                </div>

                <div align="right">
                  <v-btn color="primary" text @click="modal_form = false">
                    Close
                  </v-btn>

                  <v-btn
                    color="primary"
                    text
                    :loading="loading"
                    v-on:click="processForm"
                  >
                    Save
                  </v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip color="success" v-if="item.STATUS == 'Y'">Active</v-chip>
        <v-chip color="error" v-if="item.STATUS == 'N'">Inactive</v-chip>
      </template>

      <template v-slot:item.action="{ item }">
        <v-icon medium class="mr-2" @click="edit(item)">
          mdi-file-edit
        </v-icon>

        <v-icon medium color="primary" class="mr-2" @click="resetPassword(item)">
          mdi-reload
        </v-icon>

        <v-icon
          medium
          class="mr-2"
          color="error"
          @click="changeStatus(item, 'N')"
          v-if="item.STATUS == 'Y'"
        >
          mdi-close-circle
        </v-icon>

        <v-icon
          medium
          class="mr-2"
          color="success"
          @click="changeStatus(item, 'Y')"
          v-if="item.STATUS == 'N'"
        >
          mdi-check-circle
        </v-icon>

      </template>
    </v-data-table>
  </v-app>
</template>

<script>
export default {
  methods: {
    getData() {
      var self = this;
      var val = self.val;

      var url = self.base_url + "master/user";
      this.axios
        .post(url, {
          param: val,
        })
        .then(function(response) {
          self.desserts = response.data;
        });
    },
    modalForm() {
      var self = this;
      self.modal_form = true;
      self.disabled = false;
      self.reset();
    },
    edit(item) {
      //var self = this;
      this.modal_form = true;
      this.id = item.USER_ID;

      this.server = "";
      this.approval2 = "";
      this.cataloguer = "";

      this.username = item.USER_NAME;
      this.fullname = item.NAME;
      this.email = item.EMAIL;
      this.departement_id = item.DEPARTEMENT_ID;
      this.role_id = item.ROLE_ID;

      var server_code = JSON.parse(item.SERVER_ROLE);
      this.checkedServer = server_code;
      self.disabled = true;
    },
    reset() {
      this.id = "";
      this.$refs.form.reset();
    },
    processForm() {
      var self = this;
      if (self.$refs.form.validate()) {
        var url = "";

        self.loading = true;
        url = self.base_url + "master/add_user";

        self.axios
          .post(url, {
            username: self.username,
            fullname: self.fullname,
            email: self.email,
            departement_id: self.departement_id,
            role: self.role_id,
            server: JSON.stringify(self.checkedServer),
            id: self.id,
          })
          .then(function(response) {
            if (response.data == true) {
              self.modal_form = false;
              alert("Data succesfully saved");
            } else {
              alert(response.data);
            }
            self.getData();
            self.loading = false;
          });
      }
    },
    changeStatus(item, status) {
      var self = this;
      var url = self.base_url + "master/change_status";

      var text_confirm = "Are you sure you want to Deactivated this data ?";

      if (status == "Y") {
        text_confirm = "Are you sure you want to Activated this data ?";
      }

      this.$dialog.confirm({
        text: text_confirm,
        overlay: true,
        actions: {
          false: "No",
          true: {
            color: "red",
            text: "Yes",
            handle: () => {
              self.axios
                .post(url, {
                  id: item.USER_ID,
                  status: status,
                  table: "tm_user",
                  field: "USER_ID",
                })
                .then(function(response) {
                  alert(response.data);
                  self.getData();
                });
            },
          },
        },
      });
    },
    resetPassword(item) {
      var self = this;
      var url = self.base_url + "master/reset_password";

      var text_confirm = "Are you sure you want to Reset Password this user ?";

      this.$dialog.confirm({
        text: text_confirm,
        overlay: true,
        actions: {
          false: "No",
          true: {
            color: "red",
            text: "Yes",
            handle: () => {
              self.axios
                .post(url, {
                  id: item.USER_ID
                })
                .then(function(response) {
                  alert(response.data);
                  self.getData();
                });
            },
          },
        },
      });
    },
    getDepartement() {
      var self = this;
      var url = self.base_url + "dropdown/departement";
      self.axios.get(url).then(function(response) {
        self.list_departement = response.data;
        self.getUser();
      });
    },
    getRole() {
      var self = this;
      var url = self.base_url + "dropdown/role";
      self.axios.get(url).then(function(response) {
        self.list_role = response.data;
      });
    },
  },
  mounted() {
    this.getData();
    this.getRole();
    this.getDepartement();
  },
  data() {
    return {
      name: "User",
      title: "Data User",
      dialog: false,
      valid: true,
      widgets: false,
      singleSelect: false,
      selected: [],
      showSelect: false,
      val: "",
      headers: [
        { text: "Username", value: "USER_NAME", width: 200 },
        { text: "Fullname", value: "NAME", width: 200 },
        { text: "Email", value: "EMAIL" },
        { text: "Department", value: "DEPARTEMENT_ID" },
        { text: "Role", value: "ROLE_NAME" },
        { text: "Status", value: "status", sortable: true, width: 120 },
        { text: "Action", value: "action", sortable: false, width: 150 },
      ],
      desserts: [],
      modal_detail: false,
      detail: [],
      loading: false,
      modal_form: false,
      rules: [
        (v) => !!v || "Form is required",
        //v => (v && v.length <= 10) || 'This form must be less than 10 characters',
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      list_departement: [],
      list_role: [],
      username: "",
      fullname: "",
      email: "",
      departement_id: "",
      role_id: "",
      server: [],
      checkedServer: [],
      disabled: false,

      //storage.....................................
      storage_user_id: localStorage.getItem("user_id"),
      storage_username: localStorage.getItem("username"),
      storage_fullname: localStorage.getItem("fullname"),
      storage_company_id: localStorage.getItem("company_id"),
      storage_company_name: localStorage.getItem("company_name"),
      storage_role: localStorage.getItem("role"),
      storage_departement_id: localStorage.getItem("departement_id"),
    };
  },
};
</script>
