<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'App',
    data () {
      return {
        section: 'Head'
      }
    },
    mounted(){

    },
    methods: {
      logout () {
        window.localStorage.setItem('username', "");
        window.localStorage.setItem('fullname', "");
        this.$router.push('/login');
      },
      // setLocalStorage(){
      //    window.localStorage.setItem('username', 'hermawan.irca');
      //    window.localStorage.setItem('fullname', 'Hermawan Irca Sinaga');
      //    window.localStorage.setItem('company_id', 'PO01');
      //    window.localStorage.setItem('company_name', 'PT.Golden Mining');
      //    window.localStorage.setItem('role', '01');
      // }
    }
  }
</script>