<template>
  
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="desserts"
    :single-select="singleSelect"
    item-key="REQUEST_NO"
    :loading="loadingTable"
    show-select
    style="padding:25px">

    <template v-slot:top>
      <div>
          <div>
            <v-toolbar style="box-shadow:none !important">
              <v-toolbar-title><b>{{title}}</b></v-toolbar-title>

              <v-spacer></v-spacer>

              <div style="margin-right:30px;display:none">
                  Requester Name <b>| GEM | SCM</b>
              </div>

              <template v-if="$vuetify.breakpoint.smAndUp">
                <v-btn v-on:click="formSearch">
                  <v-icon>search</v-icon> Filter
                </v-btn>
               
                <v-btn icon v-on:click="refreshAll" style="margin-left:10px">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
            </v-toolbar>

            <div style="padding:15px 25px 0px 15px">
                <v-layout row>
                  <v-flex md8>
                      <v-btn color="primary" v-on:click="deletes">
                          Delete
                        </v-btn>
                        
                        <v-btn color="primary" v-on:click="send()" style="margin:0px 10px">
                          Submit
                        </v-btn>
                        
                        <router-link to="/searchRegister">
                          <v-btn color="primary">
                            Register New Item
                          </v-btn>
                        </router-link>
                </v-flex>

                  <v-flex md4>
                     
                        <v-text-field
                          
                          v-model="val"
                          placeholder="Enter Your Keywords"
                          solo
                          v-on:keyup.enter="getMaterial()"
                          append-icon="search"
                          @click:append="getMaterial()"
                        ></v-text-field>
                      
                  </v-flex>

                </v-layout>   
            </div>
        </div>
      </div>


      <v-dialog
        v-model="modal_message"
        max-width="700px"
      >
        <v-card>
          <v-card-title>
            Notes
          </v-card-title>
          <v-card-text>
            
            <v-tabs v-model="tab2">
                <v-tabs-slider></v-tabs-slider>
                
                <v-tab href="#tab-1">
                    Requester to Cataloguer
                </v-tab>
                
                <v-tab href="#tab-2">
                    Notes Approver
                </v-tab>

                <v-tab href="#tab-3">
                    Notes Rejection
                </v-tab>

            </v-tabs>

            <v-tabs-items v-model="tab2">
                <v-tab-item  key="1" value="tab-1">
                      <div style="height:285px;overflow-y:auto;padding:20px" id="divMessage">            
                        <div>
                            
                            <br>
                            <div style="margin-bottom:120px">
                              <div  v-for="message in list_message" v-bind:key="message.ID" style="padding:15px 20px;color:#fff;background-color:#1976d2;border-radius:10px;width:90%;margin-top:15px">
                                  <b>{{message.NAME}}</b>, {{message.DEPARTEMENT_DESC}} | {{message.ROLE_NAME}}<br>
                                  <span>To</span> <b>{{message.USER_RECEIVER}}</b> | Cataloguer
                                  <br><br>
                                  {{message.MESSAGE}}
                                  <div align="right" style="font-size:12px">{{message.MESSAGE_DATE}}</div>
                              </div>
                              
                              <div v-if="list_message==''" style="padding:20px;background-color:#fafafa;border-radius:10px;width:90%">
                                  Empty Notes
                              </div>                  
                            </div> 
                      </div>
                  </div>
                  
                  <div style="padding-top:5px">
                      <v-text-field
                        label="Message"
                        v-model="message"
                        placeholder="Type message"
                        append-icon="send"
                        @click:append="sendMessage('CAT')"
                        v-on:keyup.enter="sendMessage('CAT')"
                      ></v-text-field>
                  </div>
              </v-tab-item>
              

              <v-tab-item  key="1" value="tab-2">
                  <div style="height:285px;overflow-y:auto;padding:20px" id="divMessage">            
                        <div>
                            <br>

                            <div style="margin-bottom:120px">
                              <div  v-for="message in list_message2" v-bind:key="message.ID" style="padding:15px 20px;color:#fff;background-color:#1976d2;border-radius:10px;width:90%;margin-top:15px">
                                  <b>{{message.NAME}}</b>, {{message.DEPARTEMENT_DESC}} | {{message.ROLE_NAME}}<br>
                                  <span>To</span> <b>{{message.USER_RECEIVER}}</b> | 
                                  <span v-if="message.ROLE_RECEIVER=='REQ'">Requester</span>
                                  <span v-if="message.ROLE_RECEIVER=='APP1'">Approval 1</span>
                                  <span v-if="message.ROLE_RECEIVER=='APP2'">Approval 2</span>
                                  
                                  <br><br>
                                  {{message.MESSAGE}}
                                  <div align="right" style="font-size:12px">{{message.MESSAGE_DATE}}</div>
                              </div>
                              
                              <div v-if="list_message2==''" style="padding:20px;background-color:#fafafa;border-radius:10px;width:90%">
                                Empty Notes
                              </div>                 
                            </div> 
                      </div>
                  </div>

                  <div style="padding-top:5px">
                      <v-select 
                        item-text="USER_NAME"
                        item-value="USER_ID"
                        v-model="receiver"
                        :items="list_approval_user"
                        label="Choose Approval"
                      ></v-select>
                  </div>
                  
                  <div style="padding-top:5px">
                      <v-text-field
                        label="Message"
                        v-model="message"
                        placeholder="Type message"
                        append-icon="send"
                        @click:append="sendMessage('APP')"
                        v-on:keyup.enter="sendMessage('APP')"
                      ></v-text-field>
                  </div>
              
              </v-tab-item>


              <v-tab-item  key="1" value="tab-3">
                      <div style="height:285px;overflow-y:auto;padding:20px" id="divMessage">            
                        <div>
                            
                            <br>
                            <div style="margin-bottom:120px">
                              <div  v-for="message in list_message3" v-bind:key="message.ID" style="padding:15px 20px;color:#fff;background-color:#1976d2;border-radius:10px;width:90%;margin-top:15px">
                                  <b>{{message.NAME}}</b>, {{message.DEPARTEMENT_DESC}} | {{message.ROLE_NAME}}<br>
                                  <span>To</span> <b>{{message.USER_RECEIVER}}</b> | Cataloguer
                                  <br><br>
                                  {{message.MESSAGE}}
                                  <div align="right" style="font-size:12px">{{message.MESSAGE_DATE}}</div>
                              </div>
                              
                              <div v-if="list_message3==''" style="padding:20px;background-color:#fafafa;border-radius:10px;width:90%">
                                  Empty Notes
                              </div>                  
                            </div> 
                      </div>
                  </div>
                  
                  <!-- <div style="padding-top:5px">
                      <v-text-field
                        label="Message"
                        v-model="message"
                        placeholder="Type message"
                        append-icon="send"
                        @click:append="sendMessage('CAT')"
                        v-on:keyup.enter="sendMessage('CAT')"
                      ></v-text-field>
                  </div> -->
              </v-tab-item>
              
            </v-tabs-items>

              
              <div align="right">
                  <v-btn
                  color="primary"
                  text
                  @click="modal_message = false"
                >
                  Close
                </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="loading"
        persistent
        width="300"
      >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading..
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog
        v-model="modal_detail"
        max-width="85%"  
      >
        <v-card>
          <v-card-title>
            Item Details
          </v-card-title>
          <v-card-text>

          <template>
              <v-tabs
                  v-model="tab"
                >
                  <v-tabs-slider></v-tabs-slider>

                  <v-tab href="#tab-1">
                    Detail
                  </v-tab>

                  <v-tab href="#tab-2">
                    Log
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item  key="1" value="tab-1">
                    <v-card flat>
                      <v-card-text>
                          <div style="padding-left:10px">
                                <v-layout row>
                                  <v-flex md6>
                                      <table width="100%" cellspacing="15">
                                          <tr>
                                            <td width="35%">Item Code</td>
                                            <td><b>{{detail.INC_CODE}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Item Name</td>
                                            <td><b>{{detail.INC}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Description</td>
                                            <td><b>{{detail.DESCRIPTION}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Additional Information</td>
                                            <td><b>{{detail.ADDITIONAL_INFORMATION}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Brand</td>
                                            <td><b>{{detail.BRAND}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Part No</td>
                                            <td><b>{{detail.PART_NO}}</b></td>
                                          </tr>
                                          
                                          <tr>
                                            <td>Material Type</td>
                                            <td><b>{{detail.MATERIAL_TYPE}} - {{detail.MATERIAL_TYPE_NAME}}</b></td>
                                          </tr>
                                          
                                          <tr>
                                            <td>Criticallity</td>
                                            <td><b>{{detail.CRITICALLITY}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Request By</td>
                                            <td><b>{{detail.REQUEST_BY}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Submitted Date</td>
                                            <td><b>{{detail.DATE}}</b></td>
                                          </tr>
                                      </table>
                                  </v-flex>

                                  <v-flex md6>
                                      <table width="100%" cellspacing="15">
                                          <tr>
                                            <td width="35%">Server</td>
                                            <td><b>{{detail.SERVER_CODE}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Company</td>
                                            <td><b>{{detail.COMPANY_NAME}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Plant</td>
                                            <td><b>{{detail.PLANT_NAME}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Storage Location</td>
                                            <td><b>{{detail.STORAGE_LOCATION_NAME}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Stock Type</td>
                                            <td>
                                              <b v-if="detail.TYPE_STOCK=='Y'">STOCK</b>
                                              <b v-if="detail.TYPE_STOCK=='N'">NON STOCK</b>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Material Group</td>
                                            <td><b>{{detail.MATERIAL_GROUP}} - {{detail.MATERIAL_GROUP_NAME}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>External Group</td>
                                            <td><b>{{detail.MATERIAL_GROUP}} - {{detail.EXTERNAL_GROUP_NAME}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>UOM</td>
                                            <td><b>{{detail.UOM_NAME}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Safety Stock</td>
                                            <td><b>{{detail.SAFETY_STOCK}}</b></td>
                                          </tr>

                                          <tr>
                                            <td>Average Usage</td>
                                            <td><b>{{detail.AVERAGE_USAGE}}</b></td>
                                          </tr>
                                      </table> 
                                  </v-flex>
                                </v-layout>    
                            </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item key="2" value="tab-2" >
                    <v-card flat>
                      <v-card-text>
                          <div style="height:300px;padding-top:20px">
                            <template>
                              <v-simple-table>
                                <template>
                                  <thead>
                                    <tr>
                                      <th class="text-left" width="50"></th>
                                      <th class="text-left" width="350">Date</th>
                                      <th class="text-left">Activity</th>
                                      <th class="text-left">Role</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-if="log.SUBMIT_DATE">
                                        <td>
                                            <v-icon
                                              color="primary"
                                              large
                                              class="mr-2"
                                            >
                                              mdi-calendar
                                            </v-icon>
                                        </td>
                                        <td>
                                          <b>{{log.SUBMIT_DATE}}</b>
                                        </td>
                                        <td>
                                          Submitted By <b>{{detail.REQUEST_BY}} ({{detail.DEPARTEMENT_ID}})</b>
                                        </td>
                                        <td>
                                          Requester
                                        </td>
                                    </tr>

                                    <tr v-if="log.APPROVAL1_DATE">
                                        <td>
                                            <v-icon
                                              color="primary"
                                              large
                                              class="mr-2"
                                            >
                                              mdi-calendar
                                            </v-icon>
                                        </td>
                                        <td>
                                         <b>{{log.APPROVAL1_DATE}}</b>
                                        </td>
                                        <td>
                                          Approved By <b>{{log.USER_APPROVAL1}} ({{log.DEPARTEMENT_APPROVAL1}})</b>
                                        </td>
                                        <td>
                                          Approval 1
                                        </td>
                                    </tr>

                                    <tr v-if="log.APPROVAL2_DATE">
                                        <td>
                                            <v-icon
                                              color="primary"
                                              large
                                              class="mr-2"
                                            >
                                              mdi-calendar
                                            </v-icon>
                                        </td>
                                        <td>
                                          <b>{{log.APPROVAL2_DATE}}</b>
                                        </td>
                                        <td>
                                          Approved By <b>{{log.USER_APPROVAL2}} ({{log.DEPARTEMENT_APPROVAL2}})</b>
                                        </td>
                                        <td>
                                          Approval 2
                                        </td>
                                    </tr>

                                      <tr v-if="log.CATALOGUER_DATE">
                                          <td>
                                              <v-icon
                                                color="primary"
                                                large
                                                class="mr-2"
                                              >
                                                mdi-calendar
                                              </v-icon>
                                          </td>
                                          <td>
                                            <b>{{log.CATALOGUER_DATE}}</b>
                                          </td>
                                          <td>
                                            Cataloguing By <b>{{log.USER_CATALOGUER}}</b>
                                          </td>
                                          <td>
                                            Cataloguer
                                          </td>
                                      </tr>

                                      <tr v-if="log.REGISTER_SAP_DATE">
                                          <td>
                                              <v-icon
                                                color="primary"
                                                large
                                                class="mr-2"
                                              >
                                                mdi-calendar
                                              </v-icon>
                                          </td>
                                          <td>
                                            <b>{{log.REGISTER_SAP_DATE}}</b>
                                          </td>
                                          <td>
                                            Register to SAP By <b>{{log.USER_CATALOGUER}}</b>
                                          </td>
                                          <td>
                                            Cataloguer
                                          </td>
                                      </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </template>
                          </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                </v-tabs-items>

                <div align="right">
                    <v-btn
                    color="primary"
                    text
                    @click="modal_detail = false"
                  >
                    Close
                  </v-btn>
              </div>
            </template>

              
          </v-card-text>
        </v-card>
      </v-dialog>



      <v-dialog
        v-model="form_search"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            Filter List
          </v-card-title>
          <v-card-text>
                <div style="padding:10px">
                      <label>
                          Status
                      </label>
                      <v-select 
                        item-text="STATUS_NAME"
                        item-value="STATUS_ID"
                        v-model="filter_status"
                        :items="list_status"
                        label="Choose Status"
                        solo
                      ></v-select>

                      <label>
                          Server
                      </label>

                      <v-select 
                        v-model="filter_server"
                        :items="list_server"
                        label="Choose Server"
                        solo
                      ></v-select>

                      <div>
                        <label>Start Date</label>
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="date"
                                label="Choose Date"
                                append-icon="event"
                                readonly
                                solo
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="date" no-title scrollable>
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                              <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                            </v-date-picker>
                          </v-menu>
                      </div>
                      
                      <div>
                        <label>End Date</label>
                        <v-menu
                            ref="menu2"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :return-value.sync="date2"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="date2"
                                label="Choose Date"
                                append-icon="event"
                                readonly
                                solo
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="date2" no-title scrollable>
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="menu2 = false">Cancel</v-btn>
                              <v-btn text color="primary" @click="$refs.menu2.save(date2)">OK</v-btn>
                            </v-date-picker>
                          </v-menu>
                      </div>
                  </div>
                  <div align="right">
                      <v-btn
                      color="primary"
                      text
                      :loading="loading"
                      v-on:click="getMaterial()"
                      >
                        Ok
                      </v-btn>

                      <v-btn
                      color="primary"
                      text
                      @click="form_search = false"
                      >
                        Close
                      </v-btn>
                </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>


    <template v-slot:item.action="{ item }">
    <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">
              <v-icon
              medium
              class="mr-2"
              v-if="!item.MESSAGE"
              @click="getMessage(item)"
            >
              email
            </v-icon>

            <v-icon
              medium
              color="primary"
              class="mr-2"
              v-if="item.MESSAGE"
              @click="getMessage(item)"
            >
              email
            </v-icon>
          </span>
        </template>
        <span>Notes</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">
              <v-icon
                medium
                color="primary"
                class="mr-2"
                @click="getDetail(item)"
              >
                mdi-book-open
              </v-icon>
          </span>
        </template>
        <span>Detail</span>
    </v-tooltip>

      <v-icon
        medium
        class="mr-2"
        @click="edit(item)"
        v-if="item.ITEM_STATUS=='DRAFT'|| item.ITEM_STATUS=='NI'"
      >
        edit
      </v-icon>

      <v-icon
        medium
        @click="deleterow(item)"
        v-if="item.ITEM_STATUS=='DRAFT'"
      >
        delete
      </v-icon>
      
      
      
    </template>

      <template v-slot:item.status="{ item }">
        <span v-if="item.ITEM_STATUS=='DRAFT'">Draft</span>
        <span v-if="item.ITEM_STATUS=='NI'">Need Info Requester</span>
        <span v-if="item.ITEM_STATUS=='APP1'">Waiting Approval 1</span>
        <span v-if="item.ITEM_STATUS=='APP2'">Waiting Approval 2</span>
        <span v-if="item.ITEM_STATUS=='CAT'">Waiting Cataloguing</span>
        <span v-if="item.ITEM_STATUS=='DRAFT_CAT'">Waiting Sent to SAP</span>
        <span v-if="item.ITEM_STATUS=='REG'">Registered on SAP</span>
        <span v-if="item.ITEM_STATUS=='FAIL'">Failed</span>
        <span v-if="item.ITEM_STATUS=='RJT'">Rejected</span>
    </template>

    <template v-slot:item.materialnumber="{ item }">
        <span v-if="item.MATERIAL_NUMBER !='null'">{{item.MATERIAL_NUMBER}}</span>
    </template>

    <template v-slot:item.attachment="{ item }">
        <a :href="file_url+item.ATTACHMENT" :download="item.ATTACHMENT" target="_blank">{{item.ATTACHMENT}}</a>
    </template>
    
  </v-data-table>



</template>


<script>
export default {
    methods: {
      greet: function (event) {
        // `this` inside methods points to the Vue instance
        alert('Hello ' + this.name + '!')
        // `event` is the native DOM event
        if (event) {
          alert(event.target.tagName)
        }
      },
      deletes: function (){
        if(this.selected.length==0){
          alert("You haven't selected an item");
          return false;
        }

          var check = this.checkRows();
          if(check > 0){
            alert("There are " + check + " items that cannot be processed because the status has been process");
            return false;
          }

          this.$dialog.confirm({
            text: 'Are you sure you want to delete this item ?',
            title: 'Warning',
            overlay:true,
            actions: {
                false: 'No',
                true: {
                  color: 'red',
                  text: 'Yes',
                  handle: () => {
                      var self = this;
                      var url  = self.base_url + "material/delete";
                      this.axios.post(url , {
                          request_no : this.selected[0].REQUEST_NO
                      })
                      .then(function (response) {
                          if(response.data==true){
                              self.getMaterial();
                          }        
                      });  
                    
                  }
                }
              }
          })
      },
       deleterow: function (item){
          var check = this.checkRows();
          if(check > 0){
            alert("There are " + check + " items that cannot be processed because the status has been process");
            return false;
          }

          this.$dialog.confirm({
              text: 'Are you sure you want to delete this item ?',
              title: 'Perhatian',
              overlay:true,
              actions: {
                  false: 'No',
                  true: {
                    color: 'red',
                    text: 'Yes',
                    handle: () => {
                        var self = this;
                        var url  = self.base_url + "material/delete";
                        this.axios.post(url , {
                            request_no : item.REQUEST_NO
                        })
                        .then(function (response) {
                            if(response.data==true){
                                self.getMaterial();
                            }        
                        });  
                      
                    }
                  }
                }
            })
      },
      edit(item){
        var id = item.REQUEST_NO;
        var server = item.SERVER_CODE;
        this.$router.push({path: '/addRegister/'+btoa(server)+'/edit/'+btoa(id)});
      },
      refreshAll(){
        this.val = "";
        this.filter_status = "";
        this.filter_server = "";
        this.date = "";
        this.date2 = "";
        this.getMaterial();
      },
      getMaterial(){
          var self = this;
          var val  = self.val; 
          var url  = self.base_url + "search/item_registration";
          var status = '';
          this.axios.post(url , {
              param   : val,
              user_id : self.storage_user_id,
              status  : status,
              filter_status : self.filter_status,
              filter_server : self.filter_server,
              filter_startdate : self.date,
              filter_enddate : self.date2
          })
          .then(function (response) {
              self.desserts = response.data;
              self.form_search = false;
              self.loadingTable=false
          });
      },
      getMailApproval(){
          var self = this;
          var url  = self.base_url + "mail/mail_approval";
          this.axios.post(url , {
              departement : self.storage_departement_id,
              role : 'APP1'
          })
          .then(function (response) {
              self.email_approval = response.data[0].EMAIL;
              self.user_approval = response.data[0].USER_ID;
          });
      },
      getMessage(item){
            var self= this;
            self.modal_message = true;
            self.requester_message = item.MESSAGE;
            self.requester_date = item.DATE;
            self.request_no = item.REQUEST_NO;
            self.getCataloguerMessage(item);
            self.getApprovalMessage(item);
            self.listMessage();
            self.listMessageApp();
            self.listMessageReject();
      },
      listMessage(){

        var self = this;
        var url  = self.base_url + "mail/list_message";
        this.axios.post(url , {
            request_no : self.request_no,
            type : 'CAT',
        })
        .then(function (response) {
            self.list_message = response.data;
            var el = document.getElementById('divMessage'); 
            el.scrollTop = el.scrollHeight;  
        });
      },
      listMessageApp(){

        var self = this;
        var url  = self.base_url + "mail/list_message";
        this.axios.post(url , {
            request_no : self.request_no,
            type : 'APP',
        })
        .then(function (response) {
            self.list_message2 = response.data;
            var el = document.getElementById('divMessage2'); 
            el.scrollTop = el.scrollHeight;  
        });
      },
      listMessageReject(){

        var self = this;
        var url  = self.base_url + "mail/list_message";
        this.axios.post(url , {
            request_no : self.request_no,
            type : 'RJT',
        })
        .then(function (response) {
            self.list_message3 = response.data;
            var el = document.getElementById('divMessage3'); 
            el.scrollTop = el.scrollHeight;  
        });
      },
      send(){      
            var self = this;
            var url = this.base_url + 'material/submit';
            var arritem = self.selected;
            var item = "";
            var type_stock = "N"; 

            var check = self.checkRows();
            if(check > 0){
              alert("There are " + check + " items that cannot be processed because the status has been process");
              return false;
            }

            for(var a=0; a<arritem.length; a++){
                item += ",'"+arritem[a].REQUEST_NO+"'";
                if(arritem[a].TYPE_STOCK=='Y'){
                    type_stock='Y';
                }
            }
            
            item = item.substr(1);    
            
            if(arritem.length < 1){
                alert("You haven't selected an item");
                return false;
            }

            this.$dialog.confirm({
              text: 'Are you sure you want to submit this item ?',
              overlay:true,
              actions: {
                  false: 'No',
                  true: {
                    color: 'red',
                    text: 'Yes',
                    handle: () => {
                        self.loading = true;
                        self.container = false;
                        
                        this.axios.post(url , {
                            email_approval:self.email_approval,
                            user_approval:self.user_approval,
                            user_id:self.storage_user_id,
                            username:self.storage_username,
                            departement_id:self.storage_departement_id,
                            item_status : 'APP1',
                            item : item,
                            type_stock : type_stock
                        })
                        .then(function (response) {
                            self.result = response.data;
                            if(response.data==true){
                                self.getMaterial();
                                self.selected = [];
                                setTimeout(() => (
                                    self.loading = false,
                                    alert("Item successfully submitted")
                                ), 1000);
                                
                                // this.$dialog.notify.info('Item successfully submitted', {
                                //   position: 'bottom',
                                //   timeout : 2000
                                // });
                            }else{
                              alert(response.data);
                            }
                            self.container = true;
                        })
                        .catch(function (error) {
                            self.result = error;
                            self.loading = false;
                            self.container = true;
                        });                    
                    }
                  }
                }
            })         
        },
        getDetail(item){
            var self= this;
            self.modal_detail = true;
            self.detail = item;

            var url  = self.base_url + "master/log";
            this.axios.post(url , {
                request_no : item.REQUEST_NO
            })
            .then(function (response) {
                self.log = response.data[0];
            });
        },
        getCataloguerMessage(item){
            var self= this;

            var url  = self.base_url + "mail/getCataloguerMessage";
            this.axios.post(url , {
                server : item.SERVER_CODE
            })
            .then(function (response) {
                self.user_cataloguer = response.data[0].USER_ID;
            });
        },
        getApprovalMessage(item){
            var self= this;

            var url  = self.base_url + "mail/getApprovalMessage";
            this.axios.post(url , {
                server : item.SERVER_CODE,
                departement : self.storage_departement_id
            })
            .then(function (response) {
                self.list_approval_user = response.data;
            });
        },
        sendMessage(type){
          var self = this;
          var receiver = "";
          if(type=='CAT'){
              receiver = self.user_cataloguer;

          }else if(type=='APP'){
              receiver = self.receiver;
          }

          var url  = self.base_url + "mail/send_message";
          if(self.message){
            this.axios.post(url , {
                message : self.message,
                from : self.storage_user_id,
                receiver : receiver,
                request_no : self.request_no,
                type : type
            })
            .then(function (response) {
                if(response.data==true){
                    self.message = '';
                    self.receiver = '';

                    if(type=='CAT'){
                        self.listMessage();
                        var el = document.getElementById('divMessage'); 
                        el.scrollTop = el.scrollHeight;
                    
                    }else if(type=='APP'){
                        self.listMessageApp();
                        el = document.getElementById('divMessage2'); 
                        el.scrollTop = el.scrollHeight;
                    }else if(type=='RJT'){
                        self.listMessageApp();
                        el = document.getElementById('divMessage3'); 
                        el.scrollTop = el.scrollHeight;
                    }
                    

                }else{
                    alert(response.data);
                }
            });
          }
        },
        checkRows(){
          var total = this.selected.length;
          //var values = [];
          var a = 0;
          for(var i=0; i<total; i++){
              if(this.selected[i].ITEM_STATUS !='DRAFT' && this.selected[0].ITEM_STATUS !='NI'){
                  a++;
              }
          }
          return a;
        },
        formSearch(){
          this.form_search = true;
        }
    },
    mounted(){
        this.getMaterial();
        this.getMailApproval();
        if(this.storage_role !='REQ'){
            this.$router.push('/login');
        }     
    }
    ,data () { 
      return {
        name : "Registration",
        title : "Item Registration Draft",
        storage : {
          session : true
        },
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
        singleSelect: false,
        selected: [],
        loading :false,
        loadingTable:true,
        val:'',
        log:'',
        tab:'tab-1',  
        tab2:'tab-1',     
        headers: [
            { text: 'Action', value: 'action', sortable: false,width:160},
            { text: 'Status', value: 'status',width:'180' },
            { text: 'Request Number', value: 'REQUEST_CODE',width:"150"},
            { text: 'Requester', value: 'REQUEST_BY',width:'130' },
            { text: 'Request Date', value: 'REQUEST_DATE',width:'130' },
            { text: 'Server', value: 'SERVER_CODE',width:'110' },
            { text: 'INC Code', value: 'INC_CODE',width:'150' },
            { text: 'Item Name', value: 'INC',width:'250' },
            { text: 'Description', value: 'DESCRIPTION',width:'250'},
            { text: 'Brand', value: 'BRAND',width:'150' },
            { text: 'Part Number', value: 'PART_NO',width:'200' },
            { text: 'Company', value: 'COMPANY_NAME',width:'250' },
            { text: 'Plant', value: 'PLANT_NAME',width:'170' },
            { text: 'Storage Location', value: 'STORAGE_LOCATION_NAME',width:'180' },
            { text: 'UOM', value: 'UOM_NAME',width:'150' },
            { text: 'Criticallity', value: 'CRITICALLITY',width:'150' },
            { text: 'Avg Usage', value: 'AVERAGE_USAGE',width:'120' },
            { text: 'Material Number', value: 'materialnumber',width:'150' },
            { text: 'Attachment', value: 'attachment',width:'150' },
          ],
        desserts: [],
        list_message:[],
        list_message2:[],
        list_message3:[],
        user_id:'',
        email_approval:'noreply@sinarmas.com',
        user_request:'',
        departement_id:'',
        username:'',
        user_approval:'',
        request_no : '',
        message : '',
        requester_message:'',
        requester_date:'',
        modal_message:'',
        modal_detail:false,
        detail:[],
        form_search:false,
        menu : false,
        menu2 : false,
        user_cataloguer : '',
        list_approval_user : [],

        //Dropdown......................................
        list_server : ['GEMS','BKES','BC'],
        list_status : [
                        {'STATUS_ID':'DRAFT','STATUS_NAME':'Draft'},
                        {'STATUS_ID':'APP1','STATUS_NAME':'Waiting Approval 1'},
                        {'STATUS_ID':'APP2','STATUS_NAME':'Waiting Approval 2'},
                        {'STATUS_ID':'CAT','STATUS_NAME':'Waiting Cataloguing'},
                        {'STATUS_ID':'DRAFT_CAT','STATUS_NAME':'Waiting Send to SAP'},
                        {'STATUS_ID':'REG','STATUS_NAME':'Registered On SAP'},
                        {'STATUS_ID':'FAIL','STATUS_NAME':'Failed'},
                        {'STATUS_ID':'RJT','STATUS_NAME':'Rejected'},
                      ],

        //storage.....................................
        storage_user_id : atob(localStorage.getItem("user_id")),
        storage_username : atob(localStorage.getItem("username")),
        storage_fullname : atob(localStorage.getItem("fullname")),
        storage_company_id : atob(localStorage.getItem("company_id")),
        storage_company_name : atob(localStorage.getItem("company_name")),
        storage_role : atob(localStorage.getItem("role")),
        storage_departement_id : atob(localStorage.getItem("departement_id")),
        storage_server : atob(localStorage.getItem("server"))
      }
    },
  }
</script>
