<style>
  .fltable td{
      vertical-align: top;  
  }

  .v-data-table__empty-wrapper{
    display:none !important;
  }
</style>
<template >
  
  <v-data-table
    hide-default-header
    hide-default-footer
   style="padding:30px">

    <template v-slot:top>
      <div>
          <div>
            <v-toolbar style="box-shadow:none !important">
                <v-toolbar-title><b>{{title}}</b></v-toolbar-title>
               
                <v-spacer></v-spacer> 
                 <div>
                  Your Server : <b style="color:#1976d2">{{storage_server}}</b>
                </div>
                <div style="margin:0px 10px">
                  
                  <v-btn depressed color="primary" to="/listCatalog" style="margin-left:10px">List Cataloguer</v-btn>&nbsp;
                                  
                </div>

                
            </v-toolbar>
            <v-alert
              color="orange"
              border="left"
              text
              icon="mdi-alert"
              title="Alert title"
              dark
              v-if="!isAIExtensionInstalled"
            >
              <span >
                Please download and install 
                <a href="https://chromewebstore.google.com/detail/autodb-chatbot/enmkfbiikgjgaingkelkjgnkjkdhcjma"
                  target="_blank">
                  this extension 
                </a> 
                to your Chrome browser!
              </span>
            </v-alert>
          
            <div style="padding:20px 25px">
                <v-layout row>
                  
                  <v-flex md6>
                      <v-col cols="12" sm="6" md="12">
                      <v-card
                        max-width="100%"
                        class="mx-auto"
                      >
                        <v-toolbar
                          color="red"
                          dark
                        >
                        
                          <v-toolbar-title><v-icon>mdi-credit-card-plus</v-icon> Naming Convention</v-toolbar-title>

                          <v-spacer></v-spacer>
                        </v-toolbar>

                        <v-list subheader>
                          <br>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                  <table class="fltable">
                                    <tr>
                                      <td width="10%"><b>Item Name *</b></td>
                                      <td>

                                          <span>
                                              <v-row>
                                                <v-dialog v-model="dialog" transition="fade-transition" max-width="1200px">
                                                  <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                      v-on:click="modalInc()"
                                                      v-model="inc"
                                                                               
                                                      placeholder="Enter Item Name"
                                                      :disabled="disabledMaterial"
                                                      readonly
                                                      solo
                                                    ></v-text-field>
                                                  </template>

                                                  <v-card>
                                                    <v-toolbar dark color="primary" fixed>
                                                      
                                                      <v-toolbar-title>INC (Item Name Code)</v-toolbar-title>
                                                      <v-spacer></v-spacer>
                                                      
                                                      <v-btn icon dark @click="dialog = false">
                                                        <v-icon>mdi-close</v-icon>
                                                      </v-btn>
                                                    </v-toolbar>
                                                    <div style="padding:20px 25px
                                                    ">
                                                        <v-layout row>
                                                          <v-flex md12>
                                                              <v-simple-table>
                                                                <template v-slot:default>
                                                                  <thead>
                                                                    <tr>
                                                                      <th colspan="2"></th>
                                                                      <th class="text-right" colspan="2" style="padding:10px">
                                                                          <v-text-field
                                                                            v-model="searchInc"
                                                                            
                                                                            v-on:keyup.enter="getInc()"
                                                                            placeholder="Enter INC Code / INC Name / Colloquial Name"
                                                                            solo
                                                                            append-icon="search"
                                                                            :disabled="disabledMaterial"
                                                                          ></v-text-field>
                                                                      </th>
                                                                    </tr>
                                                                    <tr>
                                                                      <th class="text-left">INC Code</th>
                                                                      <th class="text-left">INC Name</th>
                                                                      <th class="text-left">Short Name</th>
                                                                      <th class="text-left">Colloquial Name</th>
                                                                      <th class="text-left">Material Group</th>
                                                                      <th class="text-left">External Material Group</th>
                                                                      <th class="text-center">Action</th> 
                                                                    </tr>
                                                                  </thead>
                                                                  <tbody style="height:100px">
                                                                    <tr v-for="item in list_inc" v-bind:key="item.INC_CODE">
                                                                      <td width="15%">{{item.INC_CODE}}</td>
                                                                      <td width="30%">{{item.INC_NAME}}</td>
                                                                      <td width="30%">{{item.SHORT_NAME}}</td>
                                                                      <td width="30%">{{item.COLLOQUIAL_NAME}}</td>
                                                                      <td width="30%">{{item.MATERIAL_GROUP_NAME}}</td>
                                                                      <td width="30%">{{item.EXTERNAL_GROUP_NAME}}</td>
                                                                      <td width="10%" align="center">
                                                                          <v-btn small color="default" rounded v-on:click="selectInc($event,item.INC_CODE,item.SHORT_NAME,item.COLLOQUIAL_NAME, item.MATERIAL_GROUP, item.EXTERNAL_MATERIAL_GROUP)">Pilih</v-btn>
                                                                      </td>
                                                                    </tr>
                                                                  </tbody>
                                                                </template>
                                                              </v-simple-table>
                                                          </v-flex>
                                                        </v-layout>   
                                                    </div>
                                                    <v-divider></v-divider>
                                                    <v-card-actions>
                                                      <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
                                                    </v-card-actions>
                                                  </v-card>
                                                  
                                                </v-dialog>
                                              </v-row>
                                            </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td><b>INC Code</b></td>
                                      <td style="font-size:16px">{{inc_code}}</td>
                                    </tr>
                                    <tr>
                                      <td><b>Attribute *</b></td>
                                      <td>
                                          <template>
                                              <v-row justify="left">
                                                <v-dialog v-model="dialog2" max-width="1200px">
                                                  <template v-slot:activator="{ on }">
                                                    <v-btn color="primary" :disabled="disabledMaterial" v-on:click="modalAttribute()">Fill Attribute</v-btn>
                                                  </template>
                                                  <v-card>
                                                    <v-card-title>
                                                      <span class="headline">Fill Attribute Value <v-btn v-on:click="refreshSimilarity()" small >Refresh</v-btn> </span>
                                                    </v-card-title>
                                                    <v-card-text>
                                                      <v-container>
                                                        <template>
                                                            <v-container>
                                                              <v-row>
                                                                <v-col cols="12" sm="4">
                                                                    <div style="height:400px;overflow-y:scroll;padding-right:20px">
                                                                        <table width="100%" class="fltable">
                                                                        <tr v-for="(item,index) in list_inc_attribute" v-bind:key="item.ATTRIBUTE_ID">
                                                                          <td>
                                                                            {{item.ATTRIBUTE_DESC}} <strong>{{ item.REQUIRED_FIELD === "Y"?"*":"" }}</strong>
                                                                            <v-text-field
                                                                              
                                                                              v-model="attr[index]"                      
                                                                              placeholder="Enter Type"
                                                                              :maxlength="40"
                                                                              v-on:keyup="setItemDesc()"
                                                                              solo
                                                                              :disabled="disabledAttr[index]"
                                                                            ></v-text-field>
                                                                          </td>

                                                                          <td style="padding:30px 0px 0px 5px">
                                                                        
                                                                              <v-tooltip bottom>
                                                                                <template v-slot:activator="{ on }">
                                                                                  <span v-on="on">
                                                                                      <v-icon
                                                                                        medium
                                                                                        color="orange"
                                                                                        class="mr-2"
                                                                                      >
                                                                                        mdi-help-circle
                                                                                      </v-icon>
                                                                                  </span>
                                                                                </template>

                                                                                <div style="max-width:280px" v-if="item.EXPLANATION">{{item.EXPLANATION}}</div>
                                                                                <div style="max-width:280px" v-if="!item.EXPLANATION">This attribute has no explanation</div>
                                                                            </v-tooltip>

                                                                          </td>


                                                                      <td style="padding:30px 0px 0px 0px">


                                                                        <v-tooltip v-if="similarity_data?.data && isModalAttributeDone && attr[index] && similarity_data!=null" bottom >
                                                                            <template v-slot:activator="{ on }">
                                                                              <span v-on="on">
                                                                                  <v-icon
                                                                                    medium
                                                                                    color="red"
                                                                                    class="mr-2"
                                                                                    v-if="similarity_data.data.attributes_score[item.ATTRIBUTE_CODE.toLowerCase()]?.similarity > 90"
                                                                                  >
                                                                                    mdi-information
                                                                                  </v-icon>
                                                                                  <v-icon
                                                                                    medium
                                                                                    color="gray"
                                                                                    class="mr-2"
                                                                                    v-if="similarity_data.data.attributes_score[item.ATTRIBUTE_CODE.toLowerCase()]?.similarity == undefined"
                                                                                  >
                                                                                    mdi-information
                                                                                  </v-icon>
                                                                                  <v-icon
                                                                                    medium
                                                                                    color="green"
                                                                                    class="mr-2"
                                                                                    v-if="similarity_data.data.attributes_score[item.ATTRIBUTE_CODE.toLowerCase()]?.similarity <= 90"
                                                                                  >
                                                                                    mdi-information
                                                                                  </v-icon>
                                                                              </span>
                                                                            </template>
                                                                            
                                                                            <div style="max-width:280px">
                                                                              Similarity :
                                                                              {{ similarity_data.data.attributes_score[item.ATTRIBUTE_CODE.toLowerCase()] != '' && similarity_data.data.attributes_score[item.ATTRIBUTE_CODE.toLowerCase()] != undefined ? similarity_data.data.attributes_score[item.ATTRIBUTE_CODE.toLowerCase()]?.similarity+" % ": `not found,`}} 
                                                                                 {{ similarity_data.data?.attributes_score[item.ATTRIBUTE_CODE.toLowerCase]?.similarity }} <br>in index {{ similarity_data.data.attributes_score[item.ATTRIBUTE_CODE.toLowerCase()]?.attribute_code }} <br>With Attribute {{ similarity_data.data.attributes_score[item.ATTRIBUTE_CODE.toLowerCase()]?.value }} 
                                                                            </div>
                                                                          </v-tooltip>

                                                                        </td>
                                                                        </tr>
                                                                        
                                                                        <tr>
                                                                          <td>
                                                                            ADDITIONAL TEXT
                                                                            <v-text-field 
                                                                              v-on:keyup="setItemDesc()"
                                                                              v-model="AttrAdditionalInfo" 
                                                                              placeholder="Enter Attr Additional"
                                                                              :disabled="disabledMaterial"
                                                                              solo
                                                                            ></v-text-field>
                                                                          </td>
                                                                          
                                                                          <td style="padding:30px 0px 0px 5px">
                                                                            
                                                                              <v-tooltip bottom>
                                                                                <template v-slot:activator="{ on }">
                                                                                  <span v-on="on">
                                                                                      <v-icon
                                                                                        medium
                                                                                        color="orange"
                                                                                        class="mr-2"
                                                                                      >
                                                                                        mdi-help-circle
                                                                                      </v-icon>
                                                                                  </span>
                                                                                </template>
                                                                                
                                                                                <div style="max-width:250px">Additional text for item description</div>
                                                                            </v-tooltip>

                                                                          </td>
                                                                        </tr>
                                                                    </table>
                                                                    </div>
                                                                </v-col>

                                                                <v-col cols="12" sm="4">
                                                                    <table width="100%" class="fltable">
                                                                        <tr>
                                                                          <td>
                                                                            Item Description
                                                                              <br><b>{{countString}} / 40</b>

                                                                            <v-textarea 
                                                                              :maxlength="40"
                                                                              :value="modalItemDesc"
                                                                              readonly                      
                                                                              placeholder=""
                                                                              :disabled="disabledMaterial"
                                                                              solo
                                                                            ></v-textarea>
                                                                          </td>
                                                                        </tr>

                                                                        <tr>
                                                                          <td>
                                                                          Additional Information
                                                                            <v-textarea 
                                                                              :value="modalAdditionalInfo"
                                                                              readonly                     
                                                                              placeholder=""
                                                                              solo
                                                                            ></v-textarea>
                                                                          </td>
                                                                        </tr>
                                                                  <v-alert v-if="alert_required" type="error">
                                                                    Please Fill Required Field
                                                                  </v-alert>
                                                                  <v-alert v-if="firstCharIsSymbol" type="error">
                                                                    First character in every field cant Symbol
                                                                  </v-alert>
                                                                        <tr>
                                                                          <td>
                                                                            <v-btn color="primary" dark @click="dialog2 = false">Close</v-btn>
                                                                            &nbsp;
                                                                            <v-btn color="primary" dark  @click="addDescription()">Done</v-btn>
                                                                          </td>
                                                                        </tr>
                                                                    </table>
                                                                </v-col>
                                                                
                                                                <v-col cols="12" sm="4" v-if="isModalAttributeDone">
                                                                      
                                                                <tr v-for="(item,index) in list_inc_attribute" v-bind:key="item.ATTRIBUTE_ID">
                                                                  <td width="50%" >{{item.ATTRIBUTE_CODE}}</td>
                                                                  <td width="50%" ><strong>{{ attr[index] }}</strong></td>
                                                                </tr>

                                                                <div  style=" border-bottom: 1px solid #aaa;"><strong> Requester Item Description </strong></div>
                                                                <p>{{modalItemDesc}}</p>

                                                                <div  style=" border-bottom: 1px solid #aaa;"><strong> Requester Item Information </strong></div>
                                                                <p>{{modalAdditionalInfo}}</p>
                                                                <div id="duplicate-checker" style="background-color: white;border: 1px solid gray;padding: 20px;border-radius: 10px;">
                                                                  <div id="duplicate-checker-body" v-if="!similarity_data?.data">
                                                                  <h3 style="text-align: center">Duplicate Checker</h3><br>
                                                                  <h4 style="text-align: center">Similarity Not Found</h4><br>
                                                                  </div>
                                                                  <div id="duplicate-checker-body" v-if="similarity_data?.data">
                                                                  <h3 style="text-align: center">Duplicate Checker</h3><br>
                                                                  <h4 style="text-align: left">Global Result : <v-chip style="height: 20px;" :color="similarity_data?.data?.conclusion?.score_label=='SAFE'?'#83d560':'red'" text-color="white"> {{similarity_data?.data?.conclusion?.score_label??""}} ({{similarity_data?.data?.conclusion?.score_label?(similarity_data?.data?.conclusion?.global_similarity_score+"%").replaceAll("%%","%"):""}})</v-chip></h4>
                                                                  <br>
                                                                   <strong>High Similarity (>90%)</strong> 
                                                                   <v-list dense style="margin-top:0px" v-if="duplicate_wording.length==0">
                                                                    <v-list-item style="padding: 0;" >
                                                                      <v-list-item-icon style="min-width: 30px; margin-right: 0px;">
                                                                        <v-avatar color="#2596be" size="20" style="font-size: 14px; text-align: center; line-height: 24px;">
                                                                          <span class="white--text"> - </span>
                                                                        </v-avatar>
                                                                      </v-list-item-icon>
                                                                    </v-list-item>
                                                                  </v-list>
                                                                  <v-list dense style="margin-top:0px" v-if="duplicate_wording.length>0">
                                                                    <v-list-item style="padding: 0;"  v-for="(item,index) in duplicate_wording" :key="index">
                                                                      <v-list-item-icon style="min-width: 30px; margin-right: 0px;">
                                                                        <v-avatar color="#2596be" size="20" style="font-size: 14px; text-align: center; line-height: 24px;">
                                                                          <span class="white--text">{{ index+1 }}</span>
                                                                        </v-avatar>
                                                                      </v-list-item-icon>
                                                                      <v-list-item-content>
                                                                        <v-list-item-title>
                                                                          <div style="display:inline-block;color:#2596be">{{ item.value }}</div><span class="grey--text"> in </span> {{item.attribute_code}} <span class="grey--text">({{item.similarity}}% Similar)</span>
                                                                        </v-list-item-title>
                                                                      </v-list-item-content>
                                                                    </v-list-item>
                                                                  </v-list>

                                                                  <v-text><strong><v-text v-if="similarity_data?.data?.conclusion?.score_label!='SAFE'">Potential </v-text> New Attribute </strong></v-text>
                                                                   <v-list dense style="margin-top:0px" v-if="similarity_data?.data?.conclusion?.new_attribute.length==0">
                                                                    <v-list-item style="padding: 0;" >
                                                                      <v-list-item-icon style="min-width: 30px; margin-right: 0px;">
                                                                        <v-avatar color="#2596be" size="20" style="font-size: 14px; text-align: center; line-height: 24px;">
                                                                          <span class="white--text"> - </span>
                                                                        </v-avatar>
                                                                      </v-list-item-icon>
                                                                    </v-list-item>
                                                                  </v-list>
                                                                  <v-list dense style="margin-top:0px" v-if="similarity_data?.data?.conclusion?.new_attribute.length>0">
                                                                    <v-list-item style="padding: 0;"  v-for="(item,index) in similarity_data?.data?.conclusion?.new_attribute" :key="index">
                                                                      <v-list-item-icon style="min-width: 30px; margin-right: 0px;">
                                                                        <v-avatar color="#2596be" size="20" style="font-size: 14px; text-align: center; line-height: 24px;">
                                                                          <span class="white--text">{{ index+1 }}</span>
                                                                        </v-avatar>
                                                                      </v-list-item-icon>
                                                                      <v-list-item-content>
                                                                        <v-list-item-title>
                                                                          <div style="display:inline-block;color:#2596be">{{ item.value.toUpperCase() }}</div><span class="grey--text"> in </span> {{item.attribute_code}} 
                                                                        </v-list-item-title>
                                                                      </v-list-item-content>
                                                                    </v-list-item>
                                                                  </v-list> 
                                                                <!-- <ol>
                                                                  <li>
                                                                    <item v-for="(item,index) in duplicate_wording" :key="index">{{ item }}</item> {{ item == undefined?"-":"" }}
                                                                  </li>
                                                                  <li>Global similarity score: <strong>{{ similarity_data?.data?.conclusion?.global_similarity_score??"-" }}</strong> (<strong>{{ similarity_data?.data?.conclusion?.score_label??"-" }}</strong>)</li>
                                                                  <li>New attribute: <span v-for="(item,index) in similarity_data?.data?.conclusion?.new_attribute" :key="index" ><br><strong>{{ item.value }}</strong> in <strong>{{ item.attribute_code }}</strong> </span></li>
                                                                </ol> -->
                                                              </div><div id="duplicate-checker-footer" style="display: flex; justify-content: flex-end;">
                                                                <div style="margin-right: 20px;"><div style="display: inline-block;background-color: #abdbe3;width: 15px;border-radius: 2px;text-align: center;color:#1e81b0">T </div><span> Value</span></div>
                                                                <div><div style="display: inline-block;background-color: #6d6d6d;width: 15px;border-radius: 2px;text-align: center;color:#ffffff">T </div><span> Field</span></div>
                                                              </div>

                                                                
                                                              </div>
                                                                </v-col>
                                                                
                                                              </v-row>
                                                            </v-container>
                                                          </template>
                                                            
                                                      </v-container>
                                                      <small>*All informations will be combined in to Item Description</small>
                                                    </v-card-text>
                                                  </v-card>
                                                </v-dialog>
                                              </v-row>
                                            </template>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td><b>Item Description *</b></td>
                                      <td>
                                          <template>
                                              <v-row justify="left">
                                                <v-textarea
                                                  :maxlength="40"
                                                  disabled
                                                  v-model="description"
                                                                           
                                                  placeholder="Enter Item Description"
                                                  solo
                                                ></v-textarea>
                                              </v-row>
                                          </template>
                                      </td>
                                    </tr>             

                                    <tr>
                                      <td><b>Additional Information</b></td>
                                      <td>
                                          <template>
                                              <v-row justify="left">
                                                <v-textarea
                                                  v-model="additional_information"
                                                    
                                                  disabled                       
                                                  placeholder="Enter Additional Information"
                                                  solo
                                                ></v-textarea>
                                              </v-row>
                                            </template>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td><b>Colloquial Name</b></td>
                                      <td>
                                          <template>
                                              <v-row justify="left">
                                                <v-text-field
                                                  v-model="colloquial_name"
                                                                            
                                                  placeholder="Enter Colloquial Name"
                                                  solo
                                                  :disabled="disabledMaterial"
                                                ></v-text-field>
                                              </v-row>
                                            </template>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td><b>Brand</b></td>
                                      <td> 
                                          <template>
                                              <v-row justify="left">
                                                <v-text-field
                                                v-model="brand"
                                                                                 
                                                placeholder="Enter Brand"
                                                solo
                                              ></v-text-field>
                                            </v-row>
                                          </template>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td width="30%"><b>Part Number</b></td>
                                      <td>
                                          <v-template>
                                            <v-row justify="left">
                                              <v-text-field 
                                                v-model="part_no"
                                                                                 
                                                placeholder="Enter Part Number"
                                                solo
                                              ></v-text-field>
                                            </v-row>
                                          </v-template>
                                      </td>
                                    </tr>

                                    <tr style="display:none">
                                      <td><b>Notes</b></td>
                                      <td>
                                          <template>
                                              <v-row justify="left">
                                                <v-textarea
                                                  v-model="message"
                                                    
                                                  :disabled="isDisabled"                        
                                                  placeholder="Enter Notes"
                                                  solo
                                                ></v-textarea>
                                              </v-row>
                                            </template>
                                      </td>
                                    </tr>
                                    
                                    <tr>
                                      <td><b>Request By</b></td>
                                      <td>
                                        <template>
                                          <v-row justify="left">
                                              <v-text-field
                                                style="font-style:"
                                                disabled
                                                v-model="request_by"
                                                readonly
                                                solo
                                                value="123"
                                              ></v-text-field>
                                            </v-row>
                                        </template>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td><b>Request Date</b></td>
                                      <td>
                                        <template>
                                              <v-row justify="left">
                                                <v-text-field
                                                  style="font-style:"
                                                  disabled
                                                  v-model="request_date"
                                                  readonly
                                                  solo
                                                ></v-text-field>
                                              </v-row>
                                        </template>
                                      </td>
                                    </tr>

                                  </table>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          
                        </v-list>
                      </v-card>
                      </v-col>
                      
                      <br><br>

                     
                  </v-flex>

                  <v-flex md6>
                      <table width="100%"  class="fltable" style="margin-top:20px">
                          

                          <tr>
                            <td><b>Server *</b></td>
                            <td width="70%">
                              <v-select 
                                  item-text="SERVER_CODE"
                                  item-value="SERVER_CODE"
                                  v-model="server_code"
                                  :items="list_server_code"
                                  disabled
                                  label="Choose Server"
                                  v-bind:value="{ id: SERVER_CODE, text: SERVER_CODE }"
                                  v-on:change="getCompany()"
                                  solo
                                ></v-select>
                            </td>
                          </tr>

                          <tr>
                            <td><b>Company *</b></td>
                            <td>
                              <v-autocomplete
                                  item-text="COMPANY_NAME"
                                  item-value="COMPANY_ID"
                                  v-model="company_id"
                                  :items="list_company"
                                  label="Choose Company"
                                  v-bind:value="{ id: COMPANY_ID, text: COMPANY_NAME }"
                                  v-on:change="getPlant(),nullPlant(),nullStorageLocation()"
                                  solo
                                ></v-autocomplete>
                            </td>
                          </tr>

                          <tr>
                            <td><b>Plant *</b></td>
                            <td>
                              <v-autocomplete
                                  item-text="PLANT_NAME"
                                  item-value="PLANT_CODE"
                                  v-model="plant"
                                  :items="list_plant"
                                  label="Choose Plant"
                                  v-bind:value="{ id: PLANT_CODE, text: PLANT_CODE + PLANT_NAME }"
                                  v-on:change="getStorageLocation(),nullStorageLocation()"
                                  solo
                                ></v-autocomplete>
                            </td>
                          </tr>
                          
                          <tr>
                            <td><b>Storage Location</b></td>
                            <td>
                              <v-select
                                  item-text="STORAGE_LOCATION_NAME"
                                  item-value="STORAGE_LOCATION_ID"
                                  v-model="storage_location"
                                  :items="list_storage_location"
                                  label="Choose Storage Location"
                                  v-bind:value="{ id: STORAGE_LOCATION_ID, text: STORAGE_LOCATION_NAME }"
                                  solo
                                ></v-select>
                            </td>
                          </tr>

                          <tr>
                            <td><b>Stock Type *</b></td>
                            <td>
                              <v-select
                                  item-text="TYPE_STOCK_VAL"
                                  item-value="TYPE_STOCK_ID"
                                  v-model="type_stock"
                                  :items="list_typestock"
                                  label="Choose Stock Type"
                                  v-bind:value="{ id: TYPE_STOCK_ID, text: TYPE_STOCK_VAL }"
                                  solo
                                  disabled
                                  v-on:change="getStockType"
                                ></v-select>
                            </td>
                          </tr>

                          <tr>
                              <td><b>Material Type *</b></td>
                              <td>
                                  <v-select
                                    item-text="MATERIAL_TYPE_NAME"
                                    item-value="MATERIAL_TYPE_CODE"
                                    v-model="material_type"
                                    :items="list_material_type" 
                                    label="Choose Material Type"
                                    solo
                                    v-on:change="getPriceControl(),getProfitCenter(),getValuationClass(),nullPriceControl(),nullProfitCenter(),nullValuationClass()"
                                  ></v-select>
                              </td>
                            </tr>
                            <tr v-if="material_type === 'ZSP' && server_code === 'BC'">
                              <td><b>Sub Material Type *</b></td>
                              <td>
                                  <v-autocomplete
                                    
                                    item-text="SUB_MATERIAL_TYPE_NAME"
                                    item-value="SUB_MATERIAL_TYPE_CODE"
                                    v-model="sub_material_type"
                                    :items="list_sub_material_type"
                                    label="Choose Sub Material Type"
                                    solo
                                  ></v-autocomplete>
                              </td>
                            </tr>

                          <tr>
                          <tr>
                            <td><b>Material Group *</b></td>
                            <td>
                                  <v-autocomplete
                                      item-text="MATERIAL_GROUP_NAME"
                                      item-value="MATERIAL_GROUP_ID"
                                      v-model="material_group"
                                      :items="list_material_group"
                                      label="Choose Material Group"
                                      v-on:change="getExternalGroup(),nullExternalGroup()"
                                      readonly
                                      solo
                                    ></v-autocomplete>
                            </td>
                          </tr>
                          
                          <tr>
                            <td><b>External Group *</b></td>
                            <td>
                                <v-autocomplete
                                  item-text="EXTERNAL_GROUP_NAME"
                                  item-value="EXTERNAL_GROUP_CODE"
                                  v-model="external_group"
                                  :items="list_external_group"
                                  label="Choose External Group"
                                  readonly
                                  solo
                                   v-on:change="getLabOffice(),nullLabOffice()"
                                ></v-autocomplete>
                            </td>
                          </tr>

                            <tr>
                            <td valign="top"><b>UOM *</b></td>
                            <td >
                                <v-autocomplete
                                  item-text="UOM_NAME"
                                  item-value="UOM_CODE"
                                  v-model="base_uom"
                                  :items="list_base_uom"
                                  label="Choose UOM" 
                                  v-bind:value="{ id: UOM_CODE, text: UOM_NAME }"
                                  solo
                                ></v-autocomplete>
                            </td>
                          </tr>


                          <tr>
                            <td><b>Criticallity *</b></td>
                            <td>
                              <v-select
                                  v-model="criticallity"
                                  :items="list_criticallity"
                                  label="Choose Criticallity"
                                  solo
                                  :disabled="disabledStock"
                                ></v-select>
                            </td>
                          </tr>

                          <tr>
                              <td><b>Safety Stock *</b></td>
                              <td> 
                                  <v-text-field
                                  v-model="safety_stock"
                                                                   
                                  placeholder="Enter Safety Stock"
                                  solo
                                  :disabled="disabledStock"
                               ></v-text-field>
                              </td>
                            </tr>

                          <tr>
                            <td><b>Average Usage *</b></td>
                            <td>
                              <v-text-field
                                v-model="average_usage" 
                                                         
                                placeholder="Enter Average Usage"
                                solo
                                :disabled="disabledStock"
                              ></v-text-field>
                            </td>
                          </tr>

                          <tr>
                            <td><b>Price Control *</b></td>
                            <td>
                                <v-select
                                  item-text="PRICE_CONTROL_DESC"
                                  item-value="PRICE_CONTROL_ID"
                                  v-model="price_control"
                                  :items="list_price_control"
                                  label="Choose Price Control"
                                  solo
                                ></v-select>
                            </td>
                          </tr>

                          <tr>
                            <td><b>Valuation Class *</b></td>
                            <td>
                                <v-select
                                  item-text="VALUATION_CLASS_NAME"
                                  item-value="VALUATION_CLASS_ID"
                                  v-model="valuation_class"
                                  :items="list_valuation_class"
                                  label="Choose Valuation Class"
                                  solo
                                ></v-select>
                            </td>
                          </tr>

                          <tr>
                            <td><b>Lab Office *</b></td>
                            <td>
                                <v-select
                                  item-text="LAB_OFFICE"
                                  item-value="LAB_OFFICE"
                                  v-model="lab_office"
                                  :items="list_lab_office"
                                  label="Choose Lab Office"
                                  solo
                                ></v-select>
                            </td>
                          </tr>

                          <!-- <tr v-if="server_code=='BC'">
                            <td><b>Material Number</b></td>
                            <td>
                              <v-text-field
                                v-model="material_number" 
                                                         
                                placeholder="Enter Material Number"
                                solo
                                :maxlength="10"
                              ></v-text-field>
                            </td>
                          </tr> -->

                          <tr>
                            <td><b>Attachment</b></td>
                            <td>
                                <template>
                                    <v-file-input v-model="attachment" ref="attachment" chips show-size label="File input"></v-file-input>
                                </template>

                                <div>
                                    <a :href="file_url+attachment_str" :download="attachment_str" target="_blank">
                                      <span style="font-size:18px"><b>{{attachment_str}}</b></span><br>
                                    </a>
                                    <br><br>
                                </div>
                            </td>
                          </tr>


                          <tr>
                            <td colspan="2">
                              <div align="right">
                                  <v-btn depressed large color="primary" v-on:click="addMaterial('SAVE')">Save Item</v-btn>&nbsp;
                                  <v-btn depressed large color="primary" v-on:click="addMaterial('SUBMIT')" style="display:none">Send To SAP</v-btn>
                              </div>
                            </td>
                          </tr>                        
                      </table>
                  </v-flex>

                </v-layout>  

                <v-dialog
                    v-model="error_dialog"
                    max-width="500px"
                  >
                    <v-card>
                      <v-card-title>
                        Warning
                      </v-card-title>
                      <v-card-text>
                          <p v-if="errors.length">
                            <b>Please correct the following error(s) : </b>
                            <ul>
                              <li v-for="error in errors" v-bind:key="error.id">{{ error }}</li>
                            </ul>
                          </p>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          color="primary"
                          text
                          @click="error_dialog = false"
                        >
                          Close
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog
                    v-model="loading"
                    persistent
                    width="300" 
                  >
                    <v-card
                      color="primary"
                      dark
                    >
                      <v-card-text>
                        Loading..
                        <v-progress-linear
                          indeterminate
                          color="white"
                          class="mb-0"
                        ></v-progress-linear>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
            </div>

            <div style="display:none">
                <v-toolbar style="box-shadow:none !important">
                  <v-toolbar-title>5 Items</v-toolbar-title>
                  
                  <v-spacer></v-spacer>
                  <v-btn large icon v-on:click="deletes" style="margin-right:10px">
                    <v-icon>mdi-delete-circle</v-icon>
                  </v-btn>
              </v-toolbar>
            </div>
        </div>
      </div>
    </template>

    <template v-slot:item.action="{ item }">
      <v-icon
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil-outline
      </v-icon>

      <v-icon
        
        @click="deletes(item)"
      >
        mdi-delete-outline
      </v-icon>
    </template>
  </v-data-table>
</template>


<script>
export default {
    methods: {
      setItemDesc(){
          var row_attr = this.list_inc_attribute.length;
          var val = this.attr[0]+";";
          for(var x=1;x<=row_attr;x++){
              if(this.attr[x]){
                val += this.attr[x] + ";";
              }
          }

          var values = [];
          for(var n=0;n<=row_attr;n++){
              if(this.attr[n]){
                console.log("flagging error "+n)
                if(this.list_inc_attribute[n] == undefined){
                  console.log("continue")
                  continue;
                }
                console.log(this.list_inc_attribute[n])
                values.push({
                   "index" : n,
                   "code" : this.list_inc_attribute[n].ATTRIBUTE_CODE,
                   "desc" : this.list_inc_attribute[n].ATTRIBUTE_DESC,
                   "val" : this.attr[n]
                });
              }
          }
          
          this.countString = val.length;
          if(this.countString > 40){
            this.countString=40;
          }

          var valItem = val.substr(0,41);
          this.modalItemDesc = valItem.slice(0,-1);
          
          var additionalInfo = this.AttrAdditionalInfo;
          if(additionalInfo !=''){
              values.push({
                   "index" : "add",
                   "code" : "ADDITIONAL_TEXT",
                   "desc" : "ADDITIONAL_TEXT",
                   "val" : this.AttrAdditionalInfo
                });
              additionalInfo = this.AttrAdditionalInfo + ";";
          }

          var valAdditional = val + additionalInfo;
          
          this.json_inc_attribute = JSON.stringify(values);

          this.modalAdditionalInfo = valAdditional.slice(0,-1);
      },
      addDescription(){
        if(!this.required_field_pass){

        let counterSymbol = 0
        this.required_field_pass = true
        for (const [index,value] of this.list_inc_attribute.entries()){
          console.log(value.REQUIRED_FIELD)

          if (/^[^a-zA-Z0-9]/.test(this.attr[index])) {
            this.firstCharIsSymbol=true
            counterSymbol++
          }
          if(value.REQUIRED_FIELD === "Y"){
            if(this.attr.length >= index){
              if(this.attr[index]==undefined || this.attr[index] == ""){
                this.required_field_pass = false
              }
            }
            
          }
        }

        if(counterSymbol > 0){
          this.firstCharIsSymbol = true;
        }else{
          this.firstCharIsSymbol = false
        }
      }
      console.log(this.required_field_pass)
        if(!this.required_field_pass){
        this.alert_required=true
        // alert("please fill required field")
      }else if(this.firstCharIsSymbol){
        this.firstCharIsSymbol = true
        this.required_field_pass = false;
      }
        else{
        this.description = this.modalItemDesc;
        this.additional_information = this.modalAdditionalInfo;
        this.dialog2 = false;
        this.required_field_pass=false
        this.alert_required=false
      }
      },
      async selectInc(event,code,name,colloquial_name, matGroupId, extMatGroupId){
          this.inc_code=code;
          this.inc = name;
          this.shortname = name;
          this.dialog = false;
          this.colloquial_name = colloquial_name;
          this.material_group = this.list_material_group.find(mat => mat.MATERIAL_GROUP_ID == matGroupId).MATERIAL_GROUP_ID;
          await this.getExternalGroup(matGroupId);
          this.external_group = this.list_external_group.find(mat => mat.EXTERNAL_GROUP_CODE == extMatGroupId).EXTERNAL_GROUP_CODE;
          await this.getLabOffice(extMatGroupId);
          this.clearAll();
      },
      getColor (calories) {
          if (calories > 400) return 'red'
          else if (calories > 200) return 'orange'
          else return 'green'
      },
      getBrand(){
          var self = this;
          var url = this.base_url + "dropdown/dropselect/tm_brand/BRAND_NAME"; 
          this.axios.get(url).then(function (response) {
            self.list_brand = response.data;
          })
      },
      getInc(){
          var self = this;
          var val  = self.searchInc; 
          var url  = self.base_url + "search/inc/" + self.server_code;
          this.axios.post(url , {
              param : val
          })
          .then(function (response) {
              self.list_inc = response.data;
          });
      },
      getMailApproval(){
          var self = this;
          var url  = self.base_url + "mail/mail_approval";
          this.axios.post(url , {
              departement : self.storage_departement_id,
              role : 'APP1'
          })
          .then(function (response) {
              self.email_approval = response.data[0].EMAIL;
              self.user_approval = response.data[0].USER_NAME;
          });
      },
      getServer(){
        var self = this;
        var url = this.base_url + "dropdown/dropdynamic/ts_server_setting/USER_ID/"+self.storage_user_id; 
        this.axios.get(url).then(function (response) {
          self.list_server_code = response.data;
        })
      },
      getCompany(){ 
        var self = this;
        //self.storage_location = '';
        var url = this.base_url + "dropdown/company/"+self.server_code; 
        this.axios.get(url).then(function (response) {
          self.list_company = response.data;
        });

        this.getMaterialGroup();
        this.getMaterialType();
      },
      nullCompany(){
        this.company_id = "";
      },
      getPlant(){
        var self = this;
        var url = this.base_url + "dropdown/plant/"+self.company_id; 
        this.axios.get(url).then(function (response) {
          self.list_plant = response.data;
        })
      },
      nullPlant(){
       this.plant = "";
      },
      getStorageLocation(){
        var self = this;
        var url = this.base_url + "dropdown/storage_location/"+self.plant;
        this.axios.get(url).then(function (response) {
          self.list_storage_location = response.data;
          if(response.data.length==0){
              self.list_storage_location = []
          }
        })
      },
      nullStorageLocation(){
        this.storage_location = "";
      },
      getUOM(){
          var self = this;
          var url = this.base_url + "dropdown/uom"; 
          this.axios.get(url).then(function (response) {
            self.list_base_uom = response.data;
          })
      },
      getAttribute(){
          var self = this;
          var inc_code = self.inc_code;
          var url = this.base_url + "dropdown/inc_attribute/"+inc_code; 
          this.axios.get(url).then(function (response) {
            self.list_inc_attribute = response.data;
            self.attr[0] = self.shortname;
          })
      },
      getMaterialGroup(){
          var self = this;
          var url = this.base_url + "dropdown/material_group/"+self.server_code;
          this.axios.get(url).then(function (response) {
            self.list_material_group = response.data;
            self.getExternalGroup();
          });
      },
      nullMaterialGroup(){
        this.material_group = "";
      },
      getMaterialGroupReq(server_code){
          var self = this;
          var url = this.base_url + "dropdown/material_group/"+server_code;
          this.axios.get(url).then(function (response) {
            self.list_material_group = response.data;
            self.getExternalGroup();
          });

      },
      async getExternalGroup(matGroupId){
          let self = this;
          let id = matGroupId || self.material_group;
          let url = this.base_url + "dropdown/external_group/"+id; 
          const response = await this.axios.get(url);
          self.list_external_group = response.data;
      },
      nullExternalGroup(){
        this.external_group = "";
      },
      getMaterialType(){
          var self = this;
          var id = self.server_code;
          var url = this.base_url + "dropdown/material_type/"+id; 
          this.axios.get(url).then(function (response) {
            if(self.server_code !=="BC"){
            self.list_material_type = response.data;
            }else{
              let materials = response.data;
              let filteredMaterials
              if(self.type_stock == "Y"){
             filteredMaterials = materials.filter(item => 
                    item.MATERIAL_TYPE_CODE === "ZOS" || item.MATERIAL_TYPE_CODE === "ZSP"
              );
            }else{

              filteredMaterials = materials.filter(item => 
                    item.MATERIAL_TYPE_CODE === "ZOS" || item.MATERIAL_TYPE_CODE === "ZNS"
              );
            }
            self.list_material_type = filteredMaterials;
            }
          })
      },
      nullMaterialType(){
        this.material_type = "";
      },
      getStockType(){
        var self = this;
        if(self.type_stock=='Y'){
            self.disabledStock = false;
        }else{
          self.disabledStock = true;
          self.criticallity = '';
          self.average_usage = '';
          self.safety_stock = '';
        }
      },
      modalInc(){
        this.dialog = true;
        this.getInc();
      },
      async modalAttribute(){
        console.log("test apakah dieksekusi")
        this.isModalAttributeDone = false
        this.required_field_pass = false
        this.disabledAttr[0] = true;
        this.getAttribute();
        this.dialog2 = true;
        this.modalAdditionalInfo = this.additional_information;
        this.setItemDesc();
        console.log("ndak tekan kene")
        await this.getSimilarity();
        this.isModalAttributeDone = true
      },
      upload_file(){
          let formData = new FormData();
          var name = this.storage_user_id + "-" + this.attachment.name;
          formData.append('file', this.attachment);
          formData.append('filename', name);
         
          var url  = this.base_url + "mail/upload";
          this.axios.post(url , formData,{
              headers: {
                'Content-Type': 'multipart/form-data'
              }
          })
          .then(function (response) {
              console(response.data);
          });
      },
      addMaterial(tipe_save=''){
            var self = this;
            
            self.errors = [];
            if(!self.server_code) this.errors.push("Server required.");
            if(!this.inc) this.errors.push("INC required.");
            if(!this.description) this.errors.push("Description required.");
            if(!this.base_uom) this.errors.push("Base UOM required.");
            if(!this.material_type) this.errors.push("Material Type required.");
            if(!this.material_group) this.errors.push("Material Group required.");
            if(!this.external_group) this.errors.push("External Group required.");
            if(!this.company_id) this.errors.push("Company required.");
            if(!this.plant) this.errors.push("Plant required.");
            //if(!this.valuation_class) this.errors.push("Valuation Class required.");
            if(!this.material_type) this.errors.push("Material Type required.");
            //if(!this.price_control) this.errors.push("Price Control required.");
            //if(!this.lab_office) this.errors.push("Lab Office required.");

            if(!this.sub_material_type && this.material_type === 'ZSP' && this.server_code === 'BC') this.errors.push("Sub Material Type required.");

            if(this.type_stock=='Y'){
              if(!this.criticallity) this.errors.push("Criticallity required.");
              if(!this.safety_stock) this.errors.push("Safety Stock required.");
              if(!this.average_usage) this.errors.push("Average Usage required.");
            }

            if(this.errors.length){
                self.error_dialog = true;

            }else{
                var url = "";
                //var id  =  atob(this.$route.params.id);
                self.loading = true;

                if(self.part_no==''){
                   url = this.base_url + 'material/update_cataloguer'; 
                }else{
                   url = this.base_url + 'material/update_cataloguer_new'; 
                }
                
                var item_status = "DRAFT_CAT";    
                
                
                var company_name ="";
                if(self.company_id){
                    company_name = this.list_company.find(a=>a.COMPANY_ID==this.company_id).COMPANY_NAME;
                }

                var attachment_name = self.attachment_str;
                if(self.attachment.name){
                  attachment_name = self.storage_user_id + "-" + self.attachment.name;
                }else{
                  attachment_name = self.attachment_str;
                }
                               
                self.axios.post(url , {
                    request_no : self.request_no,
                    request_by : self.request_by,
                    request_date : self.request_date,
                    inc_code : self.inc_code,
                    inc : self.inc,
                    description : self.description,
                    additional_information : self.additional_information,
                    colloquial_name : self.colloquial_name,
                    brand : self.brand,
                    part_no : self.part_no,
                    base_uom : self.base_uom,
                    material_group : self.material_group,
                    external_group : self.external_group,
                    company_id : self.company_id,
                    company_name : company_name,
                    plant : self.plant,
                    storage_location : self.storage_location,
                    average_usage : self.average_usage,
                    type_stock : self.type_stock,
                    safety_stock : self.safety_stock,
                    criticallity : self.criticallity,
                    material_type : self.material_type,
                    sub_material_type : self.sub_material_type,
                    server_code : self.server_code,
                    item_status : item_status,
                    message : self.message,
                    user_id : self.storage_user_id,
                    departement_id : self.storage_departement_id,
                    email_approval : self.email_approval,
                    user_approval : self.user_approval,
                    valuation_class : self.valuation_class,
                    material_number : null,
                    price_control : self.price_control,
                    profit_center : self.profit_center,
                    lab_office : self.lab_office,
                    part_no_save : self.part_no_save,
                    json_inc_attribute : self.json_inc_attribute,
                    attachment : attachment_name

                })
                .then(function (response) {
                    if(response.data==true){
                        self.upload_file();

                        if(tipe_save=='SUBMIT'){
                            alert("Item successfully submitted");
                        }else{
                            alert("Item successfully saved ");
                        }
                        
                        self.$router.push('/listCatalog');
                    }else{
                        alert(response.data);
                    }

                    self.loading = false;
                    self.container = true;
                })
                .catch(function (error) {
                    self.result = error;
                    self.loading = false;
                    self.container = true;
                });
                    
            }     
      },
      getDate(){
        var date = new Date().toLocaleString('en-US',{hour12:false}).split(" ");
        //var time = date[1];
        var mdy = date[0];

        mdy = mdy.split('/');
        var month = parseInt(mdy[0]);
        var day = parseInt(mdy[1]); 
        var year = parseInt(mdy[2]);

        // Putting it all together
        var formattedDate = year + '-' + month + '-' + day; 
        return formattedDate;
      }, 
      getMaterialDetail(){
          this.isAIExtensionInstalled = document.documentElement.getAttribute('AI-ext-installed') === 'true';
          var id = atob(this.$route.params.id);
          var self = this;
          var url = "";

          if(self.type_input=='edit'){
              url = this.base_url + "dropdown/dropdynamic/tm_item_registration/REQUEST_NO/" + id;

          }else if(self.type_input=='extended'){
              url = this.base_url + "dropdown/dropdynamic/tm_initial_item/MATERIAL_NUMBER/" + id;
          }
          self.loading = true;
          this.axios.get(url).then(function (response) {
            
              self.data_material = response.data;
              self.request_no = self.data_material[0].REQUEST_NO;
              self.request_code = self.data_material[0].REQUEST_CODE;
              self.inc_code = self.data_material[0].INC_CODE;
              self.description = self.data_material[0].DESCRIPTION;
              self.modalItemDesc = self.data_material[0].DESCRIPTION;
              self.additional_information = self.data_material[0].ADDITIONAL_INFORMATION;
              self.colloquial_name = self.data_material[0].COLLOQUIAL_NAME;
              self.brand = self.data_material[0].BRAND;
              self.type_stock = self.data_material[0].TYPE_STOCK; 
              self.part_no = self.data_material[0].PART_NO;
              self.base_uom = self.data_material[0].BASE_UOM;
              self.company_id = self.data_material[0].COMPANY_ID;
              self.company_name = self.data_material[0].COMPANY_NAME;
              self.plant = self.data_material[0].PLANT;
              self.storage_location = self.data_material[0].STORAGE_LOCATION;
              self.average_usage = self.data_material[0].AVERAGE_USAGE;
              self.safety_stock = self.data_material[0].SAFETY_STOCK;
              self.criticallity = self.data_material[0].CRITICALLITY;
              self.server_code = self.data_material[0].SERVER_CODE;
              self.message = self.data_material[0].MESSAGE;
              self.material_type = self.data_material[0].MATERIAL_TYPE;
              self.sub_material_type = self.data_material[0].SUB_MATERIAL_TYPE;
              self.request_by = self.data_material[0].REQUEST_BY;
              self.valuation_class = self.data_material[0].VALUATION_CLASS;
              self.price_control = self.data_material[0].PRICE_CONTROL;
              self.profit_center = self.data_material[0].PROFIT_CENTER;
              self.lab_office = self.data_material[0].LAB_OFFICE;
              // self.material_number = self.data_material[0].MATERIAL_NUMBER;
              self.material_number = null;
              //self.request_date = self.data_material[0].REQUEST_DATE;
              self.part_no_save = self.data_material[0].PART_NO;
              self.attachment_str = self.data_material[0].ATTACHMENT;

              self.getCompany();             
              self.getMaterialGroupReq(self.data_material[0].SERVER_CODE);
              self.getPlant();
              
              self.plant = self.data_material[0].PLANT;
              self.material_group = self.data_material[0].MATERIAL_GROUP;
              self.external_group = self.data_material[0].EXTERNAL_GROUP;
              

              if(self.type_input=='edit'){
                  var type_input = self.data_material[0].TYPE_INPUT;
                  self.inc = self.data_material[0].INC;
                  if(self.type_stock=='Y'){
                      self.disabledStock=false;
                  }
                  if(type_input=='EXT'){
                    self.disabledMaterial = true;
                  }

              }else if(self.type_input=='extended'){ 
                  self.inc = self.data_material[0].DESCRIPTION;
                  self.list_server_code.splice(self.list_server_code.findIndex(item => item.SERVER_CODE === self.server_code),1);  
                  self.disabledMaterial = true;
              }

              self.getValuationClass();
              self.getPriceControl();
              self.getProfitCenter();
              self.getLabOffice();
              self.getStorageLocation();
        

              self.shortname = self.inc;
              self.attr[0] = self.inc;
              
              self.json_inc_attribute = self.data_material[0].INC_ATTRIBUTE;
              var json_parse_inc = JSON.parse(self.json_inc_attribute);
              
              self.requester_description = self.data_material[0].REQUESTER_DESCRIPTION;
              self.requester_additional_info = self.data_material[0].REQUESTER_ADDITIONAL_INFO;
              var json_requester = self.data_material[0].REQUESTER_JSON_INC;
              self.list_inc_parse = JSON.parse(json_requester);

              if(self.json_inc_attribute){
                  var total_attr = json_parse_inc.length;
                  for(var a=1;a<=total_attr;a++){
                      self.attr[json_parse_inc[a].index] = json_parse_inc[a].val;
                      
                      if(json_parse_inc[a].code=='ADDITIONAL_TEXT'){
                        self.AttrAdditionalInfo = json_parse_inc[a].val;
                      }

                      if(json_parse_inc[a].index=='add'){
                        self.AttrAdditionalInfo = json_parse_inc[a].val;
                      }
                  }
              }

              self.loading = false;
          })
          .catch(function (error) {
              self.result = error;
              self.loading = false;
              self.container = true;
          });
      },
      getPriceControl(){
          var self = this;
          var server = self.server_code;
          var material_type = self.material_type;
          var url = this.base_url + "dropdown/price_control/"+server+"/"+material_type; 
          this.axios.get(url).then(function (response) {
            self.list_price_control = response.data;
          })
      },
      nullPriceControl(){
        this.price_control = "";
      },
      getValuationClass(){
          var self = this;
          var server = self.server_code;
          var material_type = self.material_type;
          var url = this.base_url + "dropdown/valuation_class/"+server+"/"+material_type; 
          this.axios.get(url).then(function (response) {
            self.list_valuation_class = response.data;
          })
      },
      nullValuationClass(){
        this.valuation_class = "";
      },
      getProfitCenter(){
          var self = this;
          var server = self.server_code;
          var material_type = self.material_type;
          var url = this.base_url + "dropdown/profit_center/"+server+"/"+material_type; 
          this.axios.get(url).then(function (response) {
            self.list_profit_center = response.data;
          })
      },
      nullProfitCenter(){
          this.profit_center = "";
      },
      async getLabOffice(extGroupId){
          var self = this;
          var external_group = extGroupId || self.external_group;
          var url = this.base_url + "dropdown/lab_office/"+external_group; 
          const response = await this.axios.get(url);
          self.list_lab_office = response.data;
          self.lab_office = response.data[0].LAB_OFFICE;
      },
      nullLabOffice(){
        this.lab_office = "";
      },
      clearAll(){
              var self = this;
              self.description = "";
              self.modalItemDesc = "";
              self.additional_information = "";
              self.AttrAdditionalInfo = "";
      },

      async refreshSimilarity(){

        const self = this
        const attributes = this.list_inc_attribute.reduce((acc, item, index) => {
        acc[item.ATTRIBUTE_CODE] = self.attr[index];
        return acc;
      }, {});
      console.log(attributes)

        const payload = {
          "inc_code": this.inc_code,
          "request_number": this.request_no,
          "request_code": this.request_code,
          "is_refresh": 1,
          "attributes": attributes
        }
      const url = "https://recommender-svc-mvazw6otuz95yybl.sinarmasmining.com/api/v1/similarities/calculate"

      const headers = {
        // Your headers
        'x-api-key': '2Ek6obwzRIBGWTMY08TZpiZmrQ3JUn',
        // Add any other headers as needed
      };
      await this.axios.post(url,payload,{headers}).then(response => {
          console.log("Check disini")
            // Handle response
            console.log(response.data);
            self.similarity_data = response.data
            
            console.log(self.similarity_data)
            const { duplicate
              // , new_attribute 
            } = self.similarity_data.data.conclusion;

            // Function to convert conclusion data to sample wording
            const convertToSampleWording = (data) => {
              if (Array.isArray(data)) {
                return data.map(item => {
                    const { similarity, attribute_code, value } = item;
                    return  { similarity, attribute_code, value }
                    // return `Attribute "${value}" is found to be ${similarity} similar with attribute "${value}" in ${attribute_code}.`;
                });
              }else{
                const item = data
                const { similarity, attribute_code, value } = item;
                return  { similarity, attribute_code, value }
                // return `Attribute "${value}" is found to be ${similarity} similar with attribute "${value}" in ${attribute_code}.`;
               

              }
            };

            // Convert conclusion data to sample wording and store in an array

            console.log("wording conversion refresh")
            console.log(self.similarity_data.data.conclusion)
            console.log("------")
            console.log(duplicate)

            if(duplicate =="None" || duplicate == null || duplicate == undefined){
              self.duplicate_wording = []
            }else{
            self.duplicate_wording = [
                ...convertToSampleWording(duplicate)
            ];
          }
            // self.duplicate_wording = [
            //     ...convertToSampleWording(duplicate)
            // ];
            console.log("wording conversion refresh")
            console.log(self.duplicate_wording)
            

            
          })
          .catch(error => {
            // Handle error
            console.error('Error Hitting:', error);
            alert("Refresh gagal!\nCoba beberapa saat lagi dan jika masalah masih berlanjut, silakan kontak admin.");
          });
          console.log("after hit refresh")
          // console.log(this.similarity_data)

          this.similarity_data = self.similarity_data
          console.log(this.similarity_data)
    },
      async getSimilarity(val){
        console.log("attr berubah")
        console.log(val)
        const headers = {
        // Your headers
        'x-api-key': '2Ek6obwzRIBGWTMY08TZpiZmrQ3JUn',
        // Add any other headers as needed
      };
        // const payload = {
        //             request_number : this.request_no,
        //             inc_code : this.inc_code,
        //             }

        // const payload = {
        //   "inc_code": "17737",
        //   "request_number": "22381",
        //   "request_code": "170823145417",
        //   "is_refresh": 0,
        //   "attributes": {
        //     "SH_NAME": "WASH BOTTLE,LAB",
        //     "BOTTLE_DESIGN_TYPE": "NI",
        //     "BOTTLE_MATL": "LDPE",
        //     "BOTTLE_CAPACITY": "500ML",
        //     "BOTTLE_DIMENSION_SIZ": "",
        //     "BOTTLE_CAP_DATA": "",
        //     "PKG_DATA": ""
        //   }
        // }
        const payload = {"request_no": this.request_no,"request_code":this.request_code}

        // const payload = {"request_no":"22381","request_code":"170823145417"}
        const similarityUrl = `${this.base_url}auto_db_engine/similarity`
        const self = this
        console.log("similarity before")
        console.log(this.similarity_data)
        
        console.log("similarity after")
        console.log("otw hit")
        await this.axios.post(similarityUrl,payload,{headers}).then(response => {
          console.log("Check disini")
            // Handle response
            console.log(response.data);
            self.similarity_data = response.data
            console.log(self.similarity_data)
            const { duplicate
              // , new_attribute 
            } = self.similarity_data.data.conclusion;

            // Function to convert conclusion data to sample wording
            const convertToSampleWording = (data) => {
                return data.map(item => {
                    const { similarity, attribute_code, value } = item;
                    return `Attribute "${value}" is found to be ${similarity} similar with attribute "${value}" in ${attribute_code}.`;
                });
            };

            // Convert conclusion data to sample wording and store in an array
            if(duplicate =="None" || duplicate == null || duplicate == undefined){
              self.duplicate_wording = []
            }else{
            self.duplicate_wording = [
                ...convertToSampleWording(duplicate)
            ];
          }
            console.log("wording conversion")
            console.log(self.duplicate_wording)


            
          })
          .catch(error => {
            // Handle error
            self.similarity_data=[]
            console.error('Error Hitting:', error);
          });
        console.log("result similarity = ")
        console.log(self.similarity_data)


      
    }
    },
    mounted(){ 

      this.isAIExtensionInstalled = document.documentElement.getAttribute('AI-ext-installed') === 'true';

      window.onload = () => {
      // Run the logic to check if the attribute "AI-ext-installed" is present in the document's root element

        console.log(document.documentElement.getAttribute('AI-ext-installed'))   
          this.isAIExtensionInstalled = document.documentElement.getAttribute('AI-ext-installed') === 'true';
        };
          console.log("====")
          console.log(chrome)
          if (typeof chrome !== 'undefined' && typeof chrome.runtime !== 'undefined') {
      // Check if your extension is installed
      chrome.runtime.sendMessage('aapbdbdomjkkjkaonfhkkikfgjllcleb', { message: 'checkInstalled' }, function(response) {
        if (response && response.installed) {
          // Extension is installed
          console.log('Extension is installed');
        } else {
          // Extension is not installed
          console.log('Extension is not installed');
        }
      });
    } else {
      // Chrome runtime is not available, so the extension is definitely not installed
      console.log('Chrome extension environment not detected');
    }
        //this.getServer();
        //this.getMaterialGroup();
        this.getBrand();
        this.getUOM();
        this.request_date = this.getDate();
        this.request_by = this.storage_username;
        this.additional_information.disabled;
        this.getMailApproval();
        this.getValuationClass();
        this.getPriceControl();
        this.getProfitCenter();
        this.getLabOffice();
        if(atob(this.$route.params.id)){
          this.getMaterialDetail();
        }

        this.setItemDesc();

        if(this.storage_role !='CAT'){
          this.$router.push('/login');
      }
    },
    watch:{
      material_type(){
        if(this.material_type !== 'ZSP'){
          this.sub_material_type = ""
        }
      },

      dialog2(){
        
        this.alert_required=false
      },

    },
    data () { 
      return {
        shortname : "",
        design : "",
        typem : "",
        size: "",
        shape:"",
        color:"",
        inc_code:"",
        name : "Registration",
        title : "Form Item Material Registration",
        dialog: false,
        dialog2: false,
        modalItemDesc:"",
        modalAdditionalInfo:"",
        select: { BRAND_CODE: '1', BRAND_NAME: 'Catterpilar' },
        errors:[],
        uom:[],
        type: [],
        disabledMaterial:false,
        error_dialog:false,
        date:"",
        isDisabled:false,
        attr:[],
        disabledAttr:[],
        disabledStock:true,
        AttrAdditionalInfo:'',
        loading:false,
        //Approval........................
        email_approval:'',
        user_approval:'',

        //searchparam................................
        searchInc:"",
        countString:0,
        
        //storage.....................................
        storage_user_id : atob(localStorage.getItem("user_id")),
        storage_username : atob(localStorage.getItem("username")),
        storage_fullname : atob(localStorage.getItem("fullname")),
        storage_company_id : atob(localStorage.getItem("company_id")),
        storage_company_name : atob(localStorage.getItem("company_name")),
        storage_role : atob(localStorage.getItem("role")),
        storage_server : atob(localStorage.getItem("server")),
        storage_departement_id : atob(localStorage.getItem("departement_id")),

        //v-list.......................................
        list_inc : "",
        list_brand : "",
        list_base_uom : [],
        list_material_group : [],
        list_external_group : [],
        list_company : [],
        list_plant : [],
        list_storage_location : [],
        list_safety_stock : "",
        list_inc_attribute : [],
        list_criticallity : ['Support','Critical','Insurance'],
        list_material_type : [],
        list_sub_material_type: [{SUB_MATERIAL_TYPE_NAME:"VHS",SUB_MATERIAL_TYPE_CODE:"VHS"},{SUB_MATERIAL_TYPE_NAME:"NON VHS",SUB_MATERIAL_TYPE_CODE:"NON_VHS"}],
        list_server_code : ['GEMS','BKES','BC'],
        list_item_status : "",
        list_cataloguer : "",
        list_typestock : [{"TYPE_STOCK_ID":"Y","TYPE_STOCK_VAL":"Stock"},{"TYPE_STOCK_ID":"N","TYPE_STOCK_VAL":"Non Stock"}],
        list_lab_office :[],
        list_valuation_class : [],
        list_price_control : [],
        list_profit_center : [],
        list_inc_parse : [],
        data_material:"",

        //v-model.......................................
        request_no : "",
        request_by : "",
        request_date : "",
        inc : "",
        description : "",
        additional_information : "",
        colloquial_name : "",
        brand : "",
        part_no : "",
        base_uom : "",
        material_group : "", 
        external_group : "",
        company_id : "",
        company_name : "",
        plant : "",
        storage_location : "",
        average_usage : "",
        safety_stock : "",
        criticallity : "",
        material_type : "",
        sub_material_type :"",
        valuation_class : "",
        attachment : [],
        attachment_str : "",
        server_code : "",
        message : "",
        item_status : "",
        cataloguer : "",
        material_number : "",
        price_control : "",
        profit_center : "",
        lab_office : "",
        part_no_save:"",
        json_inc_attribute:"",
        requester_description : "",
        requester_additional_info : "",
        requester_json_inc : "",
        
        required_field_pass:false,
        firstCharIsSymbol:false,
        alert_required:false,
        //.............................Params
        type_input : this.$route.params.type,
        id : atob(this.$route.params.id),

        //similarity propose 
        similarity_data:null,
        request_code : "",
        isModalAttributeDone : false
      }
    },
  }
</script>
