<template>
  <v-app>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="desserts"
    :single-select="singleSelect"
    item-key="ID"
    show-select
    style="padding:25px">

    <template v-slot:top>
      <div>
          <div>
            <v-toolbar style="box-shadow:none !important">
              <v-toolbar-title><b>{{title}}</b></v-toolbar-title>
              <v-spacer></v-spacer>
              
              <v-spacer></v-spacer>
              <v-breadcrumbs>Master Data / Valuation Class</v-breadcrumbs>
              <v-btn icon v-on:click="getData">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-toolbar>

            <div style="padding:0px 25px">
                <v-layout row>
                  <v-flex md8>
                      <v-btn rounded style="margin-top:15px" color="primary" large v-on:click="modalForm()">
                          <v-icon dark style="margin-right:10px">mdi-plus</v-icon>
                          Add Data
                      </v-btn>
                  </v-flex>
                  <v-flex md4>
                      <v-col cols="12" sm="6" md="12">
                        <v-text-field
                          
                          v-model="val"
                           v-on:keyup.enter="getData()"
                          placeholder="Enter Your Keywords"
                          solo
                          append-icon="search"
                        ></v-text-field>
                      </v-col>
                  </v-flex>

                </v-layout>   
            </div>
        </div>
      </div>

      <v-dialog
        v-model="modal_form"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            Valuation Class
          </v-card-title>
          <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                  <div style="padding:10px">
                      <label>
                          Valuation Class ID
                      </label>
                      <v-text-field
                        v-model="valuation_class_id"
                        :rules="rules"
                        :disabled="disabled"
                        placeholder="Enter Valuation Class ID"
                        required
                        solo
                      ></v-text-field>

                      <label>
                          Valuation Class Name
                      </label>
                      <v-text-field
                        v-model="valuation_class_name"
                        :rules="rules"
                        placeholder="Enter Valuation Class Name"
                        required
                        solo
                      ></v-text-field>

                      <label>
                          Material Type
                      </label>
                      <v-select 
                        item-text="MATERIAL_TYPE_NAME"
                        item-value="MATERIAL_TYPE_CODE"
                        v-model="material_type"
                        :items="list_material_type"
                        :rules="rules"
                        label="Choose Material Type"
                        v-bind:value="{ id: MATERIAL_TYPE_CODE, text: MATERIAL_TYPE_NAME }"
                        solo
                      ></v-select>
                      
                      <label>
                          Server
                      </label>
                      <v-select 
                        item-text="SERVER_CODE"
                        item-value="SERVER_NAME"
                        v-model="server_code"
                        :items="list_server"
                        :rules="rules"
                        label="Choose Server"
                        v-bind:value="{ id: SERVER_CODE, text: SERVER_NAME }"
                        solo
                      ></v-select>
                  </div>
                        
                  <div align="right">
                     
                      <v-btn
                      color="primary"
                      text
                      @click="modal_form = false"
                      >
                        Close
                      </v-btn>

                       <v-btn
                      color="primary"
                      text
                      :loading="loading"
                      v-on:click="processForm"
                      >
                        Process
                      </v-btn>
                </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

    </template>

    <template v-slot:item.status="{ item }">
        <v-chip color="success" v-if="item.STATUS=='Y'">Active</v-chip>
        <v-chip color="error" v-if="item.STATUS=='N'">Inactive</v-chip>
    </template>

    <template v-slot:item.action="{ item }">
      <v-icon
        medium
        class="mr-2"
        @click="edit(item)"
      >
        mdi-file-edit
      </v-icon>

      <v-icon
        medium
        class="mr-2"
        color="error"
        @click="changeStatus(item,'N')"
        v-if="item.STATUS=='Y'"
      >
         mdi-close-circle
      </v-icon>

      <v-icon
        medium
        class="mr-2"
        color="success"
        @click="changeStatus(item,'Y')"
        v-if="item.STATUS=='N'"
      >
         mdi-check-circle
      </v-icon>
      
    </template>
  </v-data-table>
  </v-app>
</template>


<script>
export default {
    methods: {
        getData(){
         var self = this;
          var val  = self.val; 
         
          var url  = self.base_url + "master/valuation_class";
          this.axios.post(url , {
              param:val,
          })
          .then(function (response) {
              self.desserts = response.data;
          });
      },
      modalForm(){
        var self = this;
        self.modal_form = true;
        self.disabled = false;
        self.reset();
      },
      edit(item){
          var self = this;
          self.modal_form = true;
          
          self.id = item.ID;
          self.valuation_class_id = item.VALUATION_CLASS_ID;
          self.valuation_class_name = item.VALUATION_CLASS_NAME;
          self.material_type = item.MATERIAL_TYPE_CODE;
          self.server_code = item.SERVER_CODE;
          self.type = "UPDATE";
          self.disabled = true;
      },
      reset () {
        this.id = "";
        this.$refs.form.reset();
        this.type = "INSERT";
      },
      processForm(){
        var self = this;
        if (self.$refs.form.validate()) {
          var type = self.type;
          var url = "";
          self.loading = true;

          if(type=="UPDATE"){
              url  = self.base_url + "master/update_valuation_class";
          }else{
              url  = self.base_url + "master/add_valuation_class";
          }
          
          self.axios.post(url , {
              valuation_class_id : self.valuation_class_id,
              valuation_class_name : self.valuation_class_name,
              material_type : self.material_type,
              server : self.server_code,
              type: self.type,
              id : self.id

          })
          .then(function (response) {
              if(response.data==true){
                self.modal_form = false;
                alert("Data succesfully saved");

              }else{
                alert(response.data)
              }
              self.getData();
              self.loading = false;
          });
        }
      },
      changeStatus(item,status){
        var self = this;
        var url  = self.base_url + "master/change_status";
         
        var text_confirm = 'Are you sure you want to Deactivated this data ?';
        
        if(status=='Y'){
          text_confirm = 'Are you sure you want to Activated this data ?';
        }

        this.$dialog.confirm({
          text: text_confirm,
          overlay:true,
          actions: {
              false: 'No',
              true: {
                color: 'red',
                text: 'Yes',
                handle: () => {
                     self.axios.post(url , {
                        id : item.ID,
                        status : status,
                        table : 'tm_valuation_class',
                        field : 'ID'
                    })
                    .then(function (response) {
                        alert(response.data);
                        self.getData();
                    });                   
                }
              }
            }
        });
      },
      getServer(){
        var self = this;
        var url = self.base_url + "dropdown/server";
        self.axios.get(url).then(function (response) {
            self.list_server = response.data;
        })
      },
      getMaterialType(){
          var self = this;
          var url = self.base_url + "dropdown/material_type";
          self.axios.get(url).then(function (response) {
              self.list_material_type = response.data;
          })
      }
    },
    mounted(){
        this.getData();
        this.getServer();
        this.getMaterialType();
    },
    data () { 
      return {
        name : "ValuationClass",
        title : "Data Valuation Class",
        dialog: false,
        widgets: false,
        singleSelect: false,
        selected: [],
        showSelect:false,
        val:'',
        headers: [
          { text: 'Valuation Class ID', value: 'VALUATION_CLASS_ID',width:250},
          { text: 'Valuation Class Name', value: 'VALUATION_CLASS_NAME',width:350},
          { text: 'Material Type', value: 'MATERIAL_TYPE_CODE',width:250},
          { text: 'Server Code', value: 'SERVER_CODE'},
          { text: 'Status', value: 'status', sortable: true,width:120},
          { text: 'Action', value: 'action', sortable: false,width:120},
          
        ],
         desserts: [],
         modal_detail:false,
         detail:[],
         modal_form:false,
         rules : [
           v => !!v || 'Form is required',
           //v => (v && v.length <= 10) || 'This form must be less than 10 characters',
         ],
         list_server : [],
         list_material_type:[],

        //form..............................
        valuation_class_id : "",
        valuation_class_name : "",
        material_type : "",
        server_code : "",
        type : "",
        id:"",
        disabled : false,

        //storage.....................................
        storage_user_id : localStorage.getItem("user_id"),
        storage_username : localStorage.getItem("username"),
        storage_fullname : localStorage.getItem("fullname"),
        storage_company_id : localStorage.getItem("company_id"),
        storage_company_name : localStorage.getItem("company_name"),
        storage_role : localStorage.getItem("role"),
        storage_departement_id : localStorage.getItem("departement_id"),
        
      }
    },
  }
</script>
