import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router'
import routes from './routes';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import dotenv from 'dotenv';
dotenv.config();



//export const config = {
//  base_url : "http://localhost:3030",
//  file_url : "http://localhost:3030/dir_upload"
//};

export const config = {
  base_url : "https://imasterapi.it-smm.id",
  file_url : "https://imasterapi.it-smm.id/dir_upload"
};

Vue.prototype.appName = 'My App';
Vue.prototype.base_url = 'https://imasterapi.it-smm.id/';
Vue.prototype.file_url = 'https://imasterapi.it-smm.id/dir_upload/';

//Vue.prototype.appName = 'My App';
//Vue.prototype.base_url = 'http://localhost:3030/';
//Vue.prototype.file_url = 'http://localhost:3030/dir_upload/';


// export const config = {
//   base_url : process.env.BASE_URL,
//   file_url : process.env.FILE_URL
// };

// Vue.prototype.appName = 'My App';
// Vue.prototype.base_url = process.env.BASE_URL+'/';
// Vue.prototype.file_url = process.env.FILE_URL+'/';


Vue.use(require('moment'));
Vue.use(VueAxios, axios);
Vue.use(VuetifyDialog, {
  warning: {},
  error: {},
  prompt: {}
})

Vue.config.productionTip = false

Vue.use(VueRouter);
const router = new VueRouter({routes});

new Vue({
  router, 
  vuetify,
  render: h => h(App)
}).$mount('#app')
