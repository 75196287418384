<style>
  .hello {
  width: 100%;
  height: 500px;
}
</style>

<template>
    <div style="padding:40px;background-color:#fff">
    
      <v-layout>
         
         <v-toolbar style="box-shadow:none;background-color:#fff">
            <v-toolbar-title><b>{{title}}</b></v-toolbar-title>
            <v-spacer></v-spacer>

            <div style="padding-top:10px">
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="date"
                      label="Start Date"
                      append-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
            </div>
            
            <div style="margin-left:20px;padding-top:10px">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :return-value.sync="date2"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="date2"
                      label="End Date"
                      append-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date2" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu2 = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.menu2.save(date2)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
            </div>
    
            <div style="margin-left:20px">
              <v-btn color="primary">
                Search
              </v-btn>
            </div>    
         </v-toolbar>
         
      </v-layout>

      <div class="hello" ref="chartdiv"></div>

      <div style="padding:15px">
          <template>
            <v-data-table
              :headers="headers"
              :items="desserts"
              :items-per-page="5"
              class="elevation-1"
            ></v-data-table>
          </template>
      </div>
    </div>
</template>


<script>

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);


export default {
    methods: {
        chart(){
          
          let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

          chart.paddingRight = 20;

          let data = [];
          let visits = 10;
          for (let i = 1; i < 366; i++) {
            visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
            data.push({ date: new Date(2018, 0, i), name: "name" + i, value: visits });
          }

          chart.data = data;

          let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
          dateAxis.renderer.grid.template.location = 0;

          let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
          valueAxis.tooltip.disabled = true;
          valueAxis.renderer.minWidth = 35;

          let series = chart.series.push(new am4charts.LineSeries());
          series.dataFields.dateX = "date";
          series.dataFields.valueY = "value";

          series.tooltipText = "{valueY.value}";
          chart.cursor = new am4charts.XYCursor();

          let scrollbarX = new am4charts.XYChartScrollbar();
          scrollbarX.series.push(series);
          chart.scrollbarX = scrollbarX;

          this.chart = chart;
        }
    },
    mounted(){
        //this.getMaterial();
        if(this.storage_role !='CAT' && this.storage_role !='ADM'){
          this.$router.push('/login');
        }

        this.chart();
    },
    data () { 
      return {
        name : "ApproveRatio",
        title : "Approve / Top Approvers",
        items: [],
        desserts : [],
        headers: [
          { text: 'Action', value: 'action', sortable: false,width:150},
          { text: 'Approval', value: 'status'},
          { text: 'Total Item', value: 'status'},
        ],

        //storage.....................................
        storage_user_id : atob(localStorage.getItem("user_id")),
        storage_username : atob(localStorage.getItem("username")),
        storage_fullname : atob(localStorage.getItem("fullname")),
        storage_company_id : atob(localStorage.getItem("company_id")),
        storage_company_name : atob(localStorage.getItem("company_name")),
        storage_role : atob(localStorage.getItem("role")),
        storage_server : atob(localStorage.getItem("server")),
        storage_departement_id : atob(localStorage.getItem("departement_id")),
      }
    },
  }
</script>
