<style>
  .fltable td{
      vertical-align: top;  
  }

  .v-data-table__empty-wrapper{
    display:none !important;
  }
</style>
<template >
  
  <v-data-table
    hide-default-header
    hide-default-footer
   style="padding:30px">

    <template v-slot:top>
      <div>
          <div>
            <v-toolbar style="box-shadow:none !important">
                <v-toolbar-title><b>{{title}}</b></v-toolbar-title>
               
                <v-spacer></v-spacer> 
                 
                <div style="margin:0px 10px">
                  
                  <v-btn depressed color="primary" to="/listRegister" style="margin-left:10px">Registration List</v-btn>&nbsp;
                                  
                </div>

                
            </v-toolbar>
          
            <div style="padding:20px 25px">
                <v-layout row>
                  
                  <v-flex md6>
                      <v-col cols="12" sm="6" md="12">
                      <v-card
                        max-width="100%"
                        class="mx-auto"
                      >
                        <v-toolbar
                          color="red"
                          dark
                        >
                        
                          <v-toolbar-title><v-icon>mdi-credit-card-plus</v-icon> 
                          Naming Convention</v-toolbar-title>

                          <v-spacer></v-spacer>
                        </v-toolbar>

                        <v-list subheader>
                          <br>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                  <table class="fltable">
                                    <tr>
                                      <td width="10%"><b>Item Name *</b></td>
                                      <td>

                                          <span>
                                              <v-row>
                                                <v-dialog v-model="dialog" transition="fade-transition" max-width="1200px">
                                                  <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                      v-on:click="modalInc()"
                                                      v-model="inc"
                                                                               
                                                      placeholder="Enter Item Name"
                                                      :disabled="disabledMaterial"
                                                      readonly
                                                      solo
                                                    ></v-text-field>
                                                  </template>

                                                  <v-card>
                                                    <v-toolbar dark color="red" fixed>
                                                      
                                                      <v-toolbar-title>INC (Item Name Code)</v-toolbar-title>
                                                      <v-spacer></v-spacer>
                                                      
                                                      <v-btn icon dark @click="dialog = false">
                                                        <v-icon>mdi-close</v-icon>
                                                      </v-btn>
                                                    </v-toolbar>
                                                    <div style="padding:20px 25px
                                                    ">
                                                        <v-layout row>
                                                          <v-flex md12>
                                                              <v-simple-table>
                                                                <template v-slot:default>
                                                                  <thead>
                                                                    <tr>
                                                                      <th colspan="2"></th>
                                                                      <th class="text-right" colspan="2" style="padding:10px">
                                                                          <v-text-field
                                                                            v-model="searchInc"
                                                                            
                                                                            v-on:keyup.enter="getInc()"
                                                                            placeholder="Enter INC Code / INC Name / Colloquial Name"
                                                                            solo
                                                                            append-icon="search"
                                                                            :disabled="disabledMaterial"
                                                                          ></v-text-field>
                                                                      </th>
                                                                    </tr>
                                                                    <tr>
                                                                      <th class="text-left">INC Code</th>
                                                                      <th class="text-left">INC Name</th>
                                                                      <th class="text-left">Short Name</th>
                                                                      <th class="text-left">Colloquial Name</th>
                                                                      <th class="text-left">Material Group</th>
                                                                      <th class="text-left">External Material Group</th>
                                                                      <th class="text-center">action</th> 
                                                                    </tr>
                                                                  </thead>
                                                                  <tbody style="height:100px">
                                                                    <tr v-for="item in list_inc" v-bind:key="item.INC_CODE">
                                                                      <td width="150">{{item.INC_CODE}}</td>
                                                                      <td width="300">{{item.INC_NAME}}</td>
                                                                      <td width="300">{{item.SHORT_NAME}}</td>
                                                                      <td width="150">{{item.COLLOQUIAL_NAME}}</td>
                                                                      <td width="200">{{item.MATERIAL_GROUP_NAME}}</td>
                                                                      <td width="200">{{item.EXTERNAL_GROUP_NAME}}</td>
                                                                      <td align="center">
                                                                          <v-btn small color="default" v-on:click="selectInc($event,item.INC_CODE,item.SHORT_NAME,item.COLLOQUIAL_NAME, item.MATERIAL_GROUP, item.EXTERNAL_MATERIAL_GROUP)">Pilih</v-btn>
                                                                      </td>
                                                                    </tr>
                                                                  </tbody>
                                                                </template>
                                                              </v-simple-table>
                                                          </v-flex>
                                                        </v-layout>   
                                                    </div>
                                                    <v-divider></v-divider>
                                                    <v-card-actions>
                                                      <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
                                                    </v-card-actions>
                                                  </v-card>
                                                  
                                                </v-dialog>
                                              </v-row>
                                            </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td><b>INC Code</b></td>
                                      <td style="font-size:16px">{{inc_code}}</td>
                                    </tr>
                                    <tr>
                                      <td><b>Attribute *</b></td>
                                      <td>
                                          <template>
                                              <v-row justify="left">
                                                <v-dialog v-model="dialog2" max-width="900px">
                                                  <template v-slot:activator="{ on }">
                                                    <v-btn color="primary" :disabled="disabledMaterial" v-on:click="modalAttribute()">Fill Attribute</v-btn>
                                                  </template>
                                                  <v-card>
                                                    <v-card-title>
                                                      <span class="headline">Fill Attribute Value</span>
                                                    </v-card-title>
                                                    <v-card-text>
                                                      <v-container>
                                                            <v-layout>
                                                              <v-flex md7 style="padding-right:30px">
                                                                <div style="height:450px;overflow-y:scroll;padding-right:20px">
                                                                  <table width="100%" class="fltable">
                                                                  <tr v-for="(item,index) in list_inc_attribute" v-bind:key="item.ATTRIBUTE_ID">
                                                                    <td>
                                                                      {{item.ATTRIBUTE_DESC}} <strong>{{ item.REQUIRED_FIELD === "Y"?"*":"" }}</strong>
                                                                      <v-combobox v-if="item.DICTIONARY.length > 0"
                                                                        
                                                                        v-model="attr[index]"
                                                                        :maxlength="40"                      
                                                                        :placeholder="'ENTER '+item.ATTRIBUTE_DESC"
                                                                        :items="item.DICTIONARY"
                                                                        v-on:keyup="setItemDesc()"
                                                                        solo
                                                                        :search-input.sync="search_input[index]"
                                                                        @mouseleave="handleMouseLeave(index)"
                                                                        :disabled="disabledAttr[index]"
                                                                      ></v-combobox>
                                                                      <v-combobox v-if="item.DICTIONARY.length == 0"
                                                                        
                                                                        v-model="attr[index]"
                                                                        :maxlength="40"                      
                                                                        :placeholder="'ENTER '+item.ATTRIBUTE_DESC"
                                                                        :items="null"
                                                                        v-on:keyup="setItemDesc()"
                                                                        solo
                                                                        :search-input.sync="search_input[index]"
                                                                        @mouseleave="handleMouseLeave(index)"
                                                                        :disabled="disabledAttr[index]"
                                                                      ></v-combobox>
                                                                    </td>

                                                                    <td style="padding:30px 0px 0px 5px">
                                                                        
                                                                        <v-tooltip bottom>
                                                                          <template v-slot:activator="{ on }">
                                                                            <span v-on="on">
                                                                                <v-icon
                                                                                  medium
                                                                                  color="orange"
                                                                                  class="mr-2"
                                                                                >
                                                                                  mdi-help-circle
                                                                                </v-icon>
                                                                            </span>
                                                                          </template>

                                                                          <div style="max-width:280px" v-if="item.EXPLANATION">{{item.EXPLANATION}}</div>
                                                                          <div style="max-width:280px" v-if="!item.EXPLANATION">This attribute has no explanation</div>
                                                                      </v-tooltip>

                                                                    </td>
                                                                  </tr>                              
                                                                  
                                                                  <!-- <tr>
                                                                    <td>
                                                                      ADDITIONAL TEXT
                                                                      <v-text-field 
                                                                        v-on:keyup="setItemDesc()"
                                                                        v-model="AttrAdditionalInfo"
                                                                        placeholder="ENTER ADDITIONAL TEXT"
                                                                        :disabled="disabledMaterial"
                                                                        solo
                                                                      ></v-text-field>
                                                                    </td>
                                                                    <td style="padding:30px 0px 0px 5px">
                                                                        
                                                                        <v-tooltip bottom>
                                                                          <template v-slot:activator="{ on }">
                                                                            <span v-on="on">
                                                                                <v-icon
                                                                                  medium
                                                                                  color="orange"
                                                                                  class="mr-2"
                                                                                >
                                                                                  mdi-help-circle
                                                                                </v-icon>
                                                                            </span>
                                                                          </template>

                                                                          <div style="max-width:250px">Additional text for item description</div>
                                                                          
                                                                      </v-tooltip>

                                                                    </td>
                                                                  </tr> -->
                                                              </table>
                                                              </div>
                                                            </v-flex>

                                                            <v-flex md8>
                                                                <table width="100%" class="fltable">
                                                                  <tr>
                                                                    <td>
                                                                      Item Description
                                                                        <br><b>{{countString}} / 40</b>

                                                                      <v-textarea 
                                                                        :maxlength="40"
                                                                        :value="modalItemDesc"
                                                                        readonly                      
                                                                        placeholder=""
                                                                        :disabled="disabledMaterial"
                                                                        solo
                                                                      ></v-textarea>
                                                                    </td>
                                                                  </tr>

                                                                  <tr>
                                                                    <td>
                                                                    Additional Information
                                                                      <v-textarea 
                                                                        :value="modalAdditionalInfo"
                                                                        readonly                     
                                                                        placeholder=""
                                                                        solo
                                                                      ></v-textarea>
                                                                    </td>
                                                                  </tr>
                                                                  <v-alert v-if="alert_required" type="error">
                                                                    Please Fill Required Field
                                                                  </v-alert>
                                                                  <v-alert v-if="firstCharIsSymbol" type="error">
                                                                    First character in every field cant Symbol
                                                                  </v-alert>
                                                                  <tr>
                                                                    <td>
                                                                      <v-btn color="primary" dark @click="dialog2 = false">Close</v-btn>
                                                                      &nbsp;
                                                                      <v-btn color="primary" dark  @click="addDescription()" :disabled="(modalItemDesc =='' || modalItemDesc == undefined ||modalItemDesc == 'undefined' || modalItemDesc == null)">Ok</v-btn>
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td>
                                                                      <br>
                                                                      <v-expansion-panels
                                                                          v-model="panel" 
                                                                          :flat="true"
                                                                        > 
                                                                        <v-expansion-panel>
                                                                          <v-expansion-panel-header><span style="font-size:12px;text-decoration:underline;color:blue">Previous Information</span></v-expansion-panel-header>
                                                                          <v-expansion-panel-content>
                                                                              <table width="100%" style="font-size:11px" cellspacing="0">
                                                                                <tr>
                                                                                  <td colspan="2">
                                                                                    <b>Requester Attributes Value</b>
                                                                                    <hr>
                                                                                  </td>
                                                                                </tr>

                                                                                <tr v-for="inc in list_inc_parse" v-bind:key="inc.ATTRIBUTE_ID">
                                                                                  <td>{{inc.desc}}</td>
                                                                                  <td width="50%"><b>{{inc.val}}</b></td>
                                                                                </tr>

                                                                                <tr>
                                                                                  <td colspan="2">
                                                                                      <br>
                                                                                      <b>Requester Item Description</b>
                                                                                      <hr>
                                                                                  </td>
                                                                                </tr>
                                                                                <tr>
                                                                                  <td colspan="2">
                                                                                    {{requester_description}}
                                                                                  </td>
                                                                                </tr>

                                                                                <tr>
                                                                                  <td colspan="2">
                                                                                      <br>
                                                                                      <b>Requester Item Information</b>
                                                                                      <hr>
                                                                                  </td>
                                                                                </tr>
                                                                                <tr>
                                                                                  <td colspan="2">
                                                                                    {{requester_additional_info}}
                                                                                  </td>
                                                                                </tr>
                                                                              </table>
                                                                          </v-expansion-panel-content>
                                                                        </v-expansion-panel>
                                                                      </v-expansion-panels>
                                                                    </td>
                                                                  </tr>
                                                              </table>
                                                            </v-flex>
                                                        </v-layout>
                                                            
                                                      </v-container>
                                                      <small>*All informations will be combined in to Item Description</small>
                                                    </v-card-text>
                                                  </v-card>
                                                </v-dialog>
                                              </v-row>
                                            </template>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td><b>Item Description *</b></td>
                                      <td>
                                          <template>
                                              <v-row justify="left">
                                                <v-textarea
                                                  :maxlength="40"
                                                  disabled
                                                  v-model="description"
                                                                           
                                                  placeholder="Enter Item Description"
                                                  solo
                                                ></v-textarea>
                                              </v-row>
                                          </template>
                                      </td>
                                    </tr>             

                                    <tr>
                                      <td><b>Additional Information</b></td>
                                      <td>
                                          <template>
                                              <v-row justify="left">
                                                <v-textarea
                                                  v-model="additional_information"
                                                    
                                                  disabled                       
                                                  placeholder="Enter Additional Information"
                                                  solo
                                                ></v-textarea>
                                              </v-row>
                                            </template>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td><b>Colloquial Name</b></td>
                                      <td>
                                          <template>
                                              <div style="color:grey;font-size:10px;margin-bottom:10px" v-if="colloquial_name_info">
                                                Existing Colloquial Name : {{colloquial_name_info}}
                                              </div>
                                              <v-row justify="left">
                                                <v-text-field
                                                  v-model="colloquial_name"
                                                                            
                                                  placeholder="Enter Colloquial Name"
                                                  solo
                                                  :disabled="disabledMaterial"
                                                ></v-text-field>
                                              </v-row>
                                            </template>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td><b>Brand</b></td>
                                      <td> 
                                          <template>
                                              <v-row justify="left">
                                                <v-text-field
                                                v-model="brand"
                                                                                 
                                                placeholder="Enter Brand"
                                                :disabled="disabledMaterial"
                                                solo
                                              ></v-text-field>
                                            </v-row>
                                          </template>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td width="30%"><b>Part Number</b></td>
                                      <td>
                                          <v-template>
                                            <v-row justify="left">
                                              <v-text-field 
                                                v-model="part_no"
                                                                                 
                                                placeholder="Enter Part Number"
                                                :disabled="disabledMaterial"
                                                solo
                                              ></v-text-field>
                                            </v-row>
                                          </v-template>
                                      </td>
                                    </tr>

                                    <tr style="display:none">
                                      <td><b>Notes</b></td>
                                      <td>
                                          <template>
                                              <v-row justify="left">
                                                <v-textarea
                                                  v-model="message"
                                                    
                                                  :disabled="isDisabled"                        
                                                  placeholder="Enter Notes"
                                                  solo
                                                ></v-textarea>
                                              </v-row>
                                            </template>
                                      </td>
                                    </tr>
                                    
                                  </table>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          
                        </v-list>
                      </v-card>
                      </v-col>
                      
                      <br><br>

                     
                  </v-flex>

                  <v-flex md6>
                      <table width="100%"  class="fltable" style="margin-top:20px">
                          

                          <tr>
                            <td><b>Server *</b></td>
                            <td width="70%">
                              <v-select 
                                  item-text="SERVER_CODE"
                                  item-value="SERVER_CODE"
                                  v-model="server_code"
                                  :items="list_server_code"
                                  label="Choose Server"
                                  disabled
                                  v-bind:value="{ id: SERVER_CODE, text: SERVER_CODE }"
                                  v-on:change="getCompany(),nullCompany()"
                                  solo
                                ></v-select>
                            </td>
                          </tr>

                          <tr>
                            <td><b>Company *</b></td>
                            <td>
                              <v-autocomplete
                                  item-text="COMPANY_NAME"
                                  item-value="COMPANY_ID"
                                  v-model="company_id"
                                  :items="list_company"
                                  label="Choose Company"
                                  v-bind:value="{ id: COMPANY_ID, text: COMPANY_NAME }"
                                  v-on:change="getPlant(),nullPlant(),nullStorageLocation()"
                                  solo
                                ></v-autocomplete>
                            </td>
                          </tr>

                          <tr>
                            <td><b>Plant *</b></td>
                            <td>
                              <v-autocomplete
                                  item-text="PLANT_NAME"
                                  item-value="PLANT_CODE"
                                  v-model="plant"
                                  :items="list_plant"
                                  label="Choose Plant"
                                  v-bind:value="{ id: PLANT_CODE, text: PLANT_CODE + PLANT_NAME }"
                                  v-on:change="getStorageLocation(),nullStorageLocation()"
                                  solo
                                ></v-autocomplete>
                            </td>
                          </tr>
                          
                          <tr>
                            <td><b>Storage Location</b></td>
                            <td>
                              <v-select
                                  item-text="STORAGE_LOCATION_NAME"
                                  item-value="STORAGE_LOCATION_ID"
                                  v-model="storage_location"
                                  :items="list_storage_location"
                                  label="Choose Storage Location"
                                  v-bind:value="{ id: STORAGE_LOCATION_ID, text: STORAGE_LOCATION_NAME }"
                                  solo
                                ></v-select>
                            </td>
                          </tr>

                          <tr>
                            <td><b>Stock Type *</b></td>
                            <td>
                              <v-select
                                  item-text="TYPE_STOCK_VAL"
                                  item-value="TYPE_STOCK_ID"
                                  v-model="type_stock"
                                  :items="list_typestock"
                                  label="Choose Stock Type"
                                  v-bind:value="{ id: TYPE_STOCK_ID, text: TYPE_STOCK_VAL }"
                                  solo
                                  v-on:change="getStockType"
                                ></v-select>
                            </td>
                          </tr>

                          <tr>
                              <td><b>Material Type</b></td>
                              <td>
                                  <v-autocomplete
                                    item-text="MATERIAL_TYPE_NAME"
                                    item-value="MATERIAL_TYPE_CODE"
                                    v-model="material_type"
                                    :items="list_material_type"
                                    label="Choose Material Type"
                                    solo

                                    @change="handleItemSelection"
                                  ></v-autocomplete>
                              </td>
                            </tr>


                          <tr v-if="material_type === 'ZSP' && server_code === 'BC'">
                              <td><b>Sub Material Type *</b></td>
                              <td>
                                  <v-autocomplete
                                    
                                    item-text="SUB_MATERIAL_TYPE_NAME"
                                    item-value="SUB_MATERIAL_TYPE_CODE"
                                    v-model="sub_material_type"
                                    :items="list_sub_material_type"
                                    label="Choose Sub Material Type"
                                    solo
                                  ></v-autocomplete>
                              </td>
                            </tr>

                          <tr>
                            <td><b>Material Group</b></td>
                            <td>
                                  <v-autocomplete
                                      item-text="MATERIAL_GROUP_NAME"
                                      item-value="MATERIAL_GROUP_ID"
                                      v-model="material_group"
                                      :items="list_material_group"
                                      label="Choose Material Group"
                                      v-on:change="getExternalGroup(),nullExternalGroup()"
                                      readonly
                                      solo
                                    ></v-autocomplete>
                            </td>
                          </tr>
                          
                          <tr>
                            <td><b>External Group</b></td>
                            <td>
                                <v-autocomplete
                                  item-text="EXTERNAL_GROUP_NAME"
                                  item-value="EXTERNAL_GROUP_CODE"
                                  v-model="external_group"
                                  :items="list_external_group"
                                  label="Choose External Group"
                                  solo
                                  readonly
                                ></v-autocomplete>
                            </td>
                          </tr>

                            <tr>
                            <td valign="top"><b>UOM *</b></td>
                            <td >
                                <v-autocomplete
                                  item-text="UOM_NAME"
                                  item-value="UOM_CODE"
                                  v-model="base_uom"
                                  :items="list_base_uom"
                                  label="Choose UOM" 
                                  v-bind:value="{ id: UOM_CODE, text: UOM_NAME }"
                                  solo
                                ></v-autocomplete>
                            </td>
                          </tr>


                          <tr>
                            <td><b>Criticallity *</b></td>
                            <td>
                              <v-select
                                  v-model="criticallity"
                                  :items="list_criticallity"
                                  label="Choose Criticallity"
                                  solo
                                  :disabled="disabledStock"
                                ></v-select>
                            </td>
                          </tr>

                          <tr>
                              <td><b>Safety Stock *</b></td>
                              <td> 
                                  <v-text-field
                                  v-model="safety_stock"
                                                                   
                                  placeholder="Enter Safety Stock"
                                  solo
                                  :disabled="disabledStock"
                               ></v-text-field>
                              </td>
                            </tr>

                          <tr>
                            <td><b>Average Usage *</b></td>
                            <td>
                              <v-text-field
                                v-model="average_usage" 
                                                         
                                placeholder="Enter Average Usage"
                                solo
                                :disabled="disabledStock"
                              ></v-text-field>
                            </td>
                          </tr>

                          <tr style="display:none">
                            <td><b>Request By</b></td>
                            <td>
                              <v-text-field
                                style="font-style:"
                                disabled
                                v-model="request_by"
                                readonly
                                solo
                                value="123"
                              ></v-text-field>
                            </td>
                          </tr>
                          <tr style="display:none">
                            <td><b>Request Date</b></td>
                            <td>
                              <v-text-field
                                style="font-style:"
                                disabled
                                v-model="request_date"
                                readonly
                                solo
                              ></v-text-field>
                            </td>
                          </tr>
                          <tr>
                            <td><b>Attachment</b></td>
                            <td>
                                <template>
                                    <v-file-input v-model="attachment" ref="attachment" chips show-size label="File input"></v-file-input>
                                </template>

                                <div>
                                    <a :href="file_url+attachment_str" :download="attachment_str" target="_blank">
                                      <span style="font-size:18px"><b>{{attachment_str}}</b></span><br>
                                    </a>
                                    <br><br>
                                </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="2">
                              <div align="right">
                                  <v-btn 
                                  depressed 
                                  large color="primary" 
                                  v-on:click="addMaterial('SAVE')" 
                                  :disabled="dialog"
                                  :loading="dialog">
                                    Save as Draft
                                  </v-btn>&nbsp;
                                  
                                  <v-btn 
                                  depressed 
                                  large color="primary" 
                                  :disabled="dialog"
                                  :loading="dialog"
                                  v-on:click="addMaterial('SUBMIT')">
                                  Submit
                                  </v-btn>
                              </div>
                            </td>
                          </tr>                        
                      </table>
                  </v-flex>

                </v-layout>  

                <v-dialog
                    v-model="error_dialog"
                    max-width="500px"
                  >
                    <v-card>
                      <v-card-title>
                        Warning
                      </v-card-title>
                      <v-card-text>
                          <p v-if="errors.length">
                            <b>Please correct the following error(s) : </b>
                            <ul>
                              <li v-for="error in errors" v-bind:key="error.id">{{ error }}</li>
                            </ul>
                          </p>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          color="primary"
                          text
                          @click="error_dialog = false"
                        >
                          Close
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog
                    v-model="loading"
                    persistent
                    color="red"
                    width="300"
                  >
                    <v-card
                      color="primary"
                      dark
                    >
                      <v-card-text>
                        Loading..
                        <v-progress-linear
                          indeterminate
                          color="white"
                          class="mb-0"
                        ></v-progress-linear>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
            </div>

            <div style="display:none">
                <v-toolbar style="box-shadow:none !important">
                  <v-toolbar-title>5 Items</v-toolbar-title>
                  
                  <v-spacer></v-spacer>
                  <v-btn large icon v-on:click="deletes" style="margin-right:10px">
                    <v-icon>mdi-delete-circle</v-icon>
                  </v-btn>
              </v-toolbar>
            </div>
        </div>
      </div>
    </template>

    <template v-slot:item.action="{ item }">
      <v-icon
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil-outline
      </v-icon>

      <v-icon
        
        @click="deletes(item)"
      >
        mdi-delete-outline
      </v-icon>
    </template>
  </v-data-table>
</template>


<script>
export default {
    methods: {
      setItemDesc(){
          var self = this;
          var row_attr = self.list_inc_attribute.length;
          var val = self.attr[0]+";";
          for(var x=1;x<=row_attr;x++){
              if(self.attr[x]){
                self.attr[x] = self.attr[x].replace(";",",")
                val += self.attr[x] + ";";
              }
          }

          var values = [];
          for(var n=0;n<=row_attr;n++){
              if(self.attr[n]){
                values.push({
                   "index" : n,
                   "code" : self.list_inc_attribute[n].ATTRIBUTE_CODE,
                   "desc" : self.list_inc_attribute[n].ATTRIBUTE_DESC,
                   "val" : self.attr[n]
                });
              }
          }
          
          self.countString = val.length;
          if(self.countString > 40){
            self.countString=40;
          }

          var valItem = val.substr(0,41);
          self.modalItemDesc = valItem.slice(0,-1);
          self.AttrAdditionalInfo = self.AttrAdditionalInfo.replace(";",",")
          var additionalInfo = self.AttrAdditionalInfo;
          
          if(additionalInfo !=''){
              values.push({
                   "index" : "add",
                   "code" : "ADDITIONAL_TEXT",
                   "desc" : "ADDITIONAL TEXT",
                   "val" : additionalInfo
                });
              additionalInfo = self.AttrAdditionalInfo + ";";
          }

          var valAdditional = val + additionalInfo;
          
          self.json_inc_attribute = JSON.stringify(values);

          self.modalAdditionalInfo = valAdditional.slice(0,-1);
      },
      handleMouseLeave(index) {
      this.attr[index] = this.search_input[index];
      this.attr[index] = this.search_input[index]
      this.setItemDesc(index)
    },
      addDescription(){
        if(!this.required_field_pass){
        this.required_field_pass = true
        let counterSymbol = 0
        for (const [index,value] of this.list_inc_attribute.entries()){
          console.log(value.REQUIRED_FIELD)
          if (/^[^a-zA-Z0-9]/.test(this.attr[index])) {
            this.firstCharIsSymbol=true
            counterSymbol++
          }
          if(value.REQUIRED_FIELD === "Y"){
            if(this.attr.length >= index){
              if(this.attr[index]==undefined || this.attr[index] == ""){
                this.required_field_pass = false
              }
            }
            
          }
        }
        if(counterSymbol > 0){
          this.firstCharIsSymbol = true;
        }else{
          this.firstCharIsSymbol = false
        }
      }
      console.log(this.required_field_pass)
        if(!this.required_field_pass){
        this.alert_required=true
        // alert("please fill required field")
      }else if(this.firstCharIsSymbol){
        this.firstCharIsSymbol = true
        this.required_field_pass = false;
      }
        else{
        this.description = this.modalItemDesc;
        this.additional_information = this.modalAdditionalInfo;
        this.dialog2 = false;
        this.required_field_pass=false
        this.alert_required=false
      }
      },
      async selectInc(event,code,name,colloquial_name, matGroupId, extMatGroupId){
          this.inc_code=code;
          this.inc = name;
          this.shortname = name;
          this.dialog = false;
          this.colloquial_name_info = colloquial_name;
          this.material_group = this.list_material_group.find(mat => mat.MATERIAL_GROUP_ID == matGroupId).MATERIAL_GROUP_ID;
          await this.getExternalGroup(matGroupId);
          this.external_group = this.list_external_group.find(mat => mat.EXTERNAL_GROUP_CODE == extMatGroupId).EXTERNAL_GROUP_CODE;
          this.clearAll();
          this.attr = [];
      },
      getColor (calories) {
          if (calories > 400) return 'red'
          else if (calories > 200) return 'orange'
          else return 'green'
      },
      getBrand(){
          var self = this;
          var url = this.base_url + "dropdown/dropselect/tm_brand/BRAND_NAME"; 
          this.axios.get(url).then(function (response) {
            self.list_brand = response.data;
          })
      },
      getInc(){
          var self = this;
          var val  = self.searchInc; 
          var url  = self.base_url + "search/inc/" + this.server_code;
          this.axios.post(url , {
              param : val
          })
          .then(function (response) {
              self.list_inc = response.data;
          });
      },
      getMailApproval(){
          var self = this;
          var url  = self.base_url + "mail/mail_approval";
          this.axios.post(url , {
              departement : self.storage_departement_id,
              role : 'APP1'
          })
          .then(function (response) {
              self.email_approval = response.data[0].EMAIL;
              self.user_approval = response.data[0].USER_NAME;
          });
      },
      getServer(){
        // var self = this;
        // //self.company_id = '';
        // //self.plant = '';
        // self.storage_location = '';
        // var url = this.base_url + "dropdown/dropdynamic/ts_server_setting/USER_ID/"+self.storage_user_id; 
        // this.axios.get(url).then(function (response) {
        //   self.list_server_code = response.data;
        // });
        // var self = this;
        // self.company_id = '';
        // self.plant = '';
        // self.storage_location = '';
        // var url = this.base_url + "dropdown/server_setting/"+self.storage_user_id; 
        // this.axios.get(url).then(function (response) {
        //   var list_server = JSON.parse(response.data[0].SERVER_ROLE);
        //   self.list_server_code = list_server;
        //   self.server_code = list_server[0];
        // });
        self.list_server_code = ['GEMS','BKES','BC']
      },
      nullServer(){
        this.plant = "";
      },
      getCompany(){
        var self = this;
        //self.company_id = '';
        //self.storage_location = '';
        var url = this.base_url + "dropdown/company/"+self.server_code; 
        this.axios.get(url).then(function (response) {
          self.list_company = response.data;
        });
        this.getMaterialGroup();
        this.getMaterialType();
      },
      nullCompany(){
        self.plant = "";
      },
      getPlant(){
        var self = this;
        self.plant = '';
        var url = this.base_url + "dropdown/plant/"+self.company_id; 
        this.axios.get(url).then(function (response) {
          self.list_plant = response.data;
        })
      },
      nullPlant(){
        this.plant = "";
      },
      getStorageLocation(){
        var self = this;
        var url = this.base_url + "dropdown/storage_location/"+self.plant; 
        this.axios.get(url).then(function (response) {
          self.list_storage_location = response.data;
          if(response.data.length==0){
              self.list_storage_location = []
          }
        })
      },
      nullStorageLocation(){
        this.storage_location = "";
      },
      getUOM(){
          var self = this;
          var url = this.base_url + "dropdown/uom"; 
          this.axios.get(url).then(function (response) {
            self.list_base_uom = response.data;
          })
      },
      getAttribute(){
          var self = this;
          var inc_code = self.inc_code;
          var url = this.base_url + "dropdown/inc_attribute/"+inc_code; 
          this.axios.get(url).then(function (response) {

            

            self.list_inc_attribute = response.data;
            // let description = ""
            for(let i =0;i<self.list_inc_attribute.length;i++){
              if(self.list_inc_attribute[i].DICTIONARY)
              self.list_inc_attribute[i].DICTIONARY=self.list_inc_attribute[i].DICTIONARY.split(",")
            }
            if(self.description == ""){
            self.modalItemDesc = self.shortname
            self.modalAdditionalInfo = self.shortname}
            // console.log(self.list_inc_attribute)
            self.attr[0] = self.shortname;
          });
      },
      getMaterialGroup(){
          var self = this;
          self.material_group = "";
          var url = this.base_url + "dropdown/material_group/"+self.server_code;
          this.axios.get(url).then(function (response) {
            self.list_material_group = response.data;
            self.getExternalGroup();
          });

      },
      getMaterialGroupReq(server_code){
          var self = this;
          self.material_group = "";
          var url = this.base_url + "dropdown/material_group/"+server_code;
          this.axios.get(url).then(function (response) {
            self.list_material_group = response.data;
            self.getExternalGroup();
          });

      },
      nullMaterialGroup(){
        this.material_group = "";
      },
      async getExternalGroup(matGroupId){
          var self = this;
          var id = matGroupId || self.material_group;
          var url = this.base_url + "dropdown/external_group/"+id;
          const response = await this.axios.get(url);
          self.list_external_group = response.data;
      },
      nullExternalGroup(){
        this.external_group = "";
      },
      getMaterialType(){
          var self = this;
          var id = self.server_code;
          var url = this.base_url + "dropdown/material_type/"+id; 
          this.axios.get(url).then(function (response) {
            if(self.server_code !=="BC"){
            self.list_material_type = response.data;
            }else{
              let materials = response.data;
              let filteredMaterials = materials
              if(self.type_stock == "Y"){
             filteredMaterials = materials.filter(item => 
                    item.MATERIAL_TYPE_CODE === "ZOS" || item.MATERIAL_TYPE_CODE === "ZSP"
              );
            }else if(self.type_stock == "N"){

              filteredMaterials = materials.filter(item => 
                    item.MATERIAL_TYPE_CODE === "ZOS" || item.MATERIAL_TYPE_CODE === "ZNS"
              );
            }
            self.list_material_type = filteredMaterials;
            }
          })
      },
      nullMaterialType(){
          this.material_type = "";
      },
      getStockType(){
        var self = this;
        if(self.type_stock=='Y'){
            self.disabledStock = false;
        }else{
          self.disabledStock = true;
          self.criticallity = '';
          self.average_usage = '';
          self.safety_stock = '';
        }
      },
      modalInc(){
        this.dialog = true;
        this.getInc();
      },
      modalAttribute(){
        this.required_field_pass = false
        this.disabledAttr[0] = true;
        this.getAttribute();
        this.dialog2 = true;
        this.modalAdditionalInfo = this.additional_information;
        this.setItemDesc();
        
      },
      upload_file(){
          let formData = new FormData();
          var name = this.storage_user_id + "-" + this.attachment.name;
          formData.append('file', this.attachment);
          formData.append('filename', name);
         
          var url  = this.base_url + "mail/upload";
          this.axios.post(url , formData,{
              headers: {
                'Content-Type': 'multipart/form-data'
              }
          })
          .then(function (response) {
              console(response.data);
          });
      },
      addMaterial(tipe_save=''){
            var self = this;
            
            self.errors = [];
            if(!self.server_code) this.errors.push("Server required.");
            if(!this.inc) this.errors.push("INC required.");
            if(!this.description) this.errors.push("Description required.");
            //if(!this.part_no) this.errors.push("Part Number required.");
            if(!this.base_uom) this.errors.push("Base UOM required.");
            if(!this.company_id) this.errors.push("Company required.");
            if(!this.plant) this.errors.push("Plant required.");
            if(!this.type_stock) this.errors.push("Stock Type required.");
            if(!this.type_stock) this.errors.push("Type Stock required.");

            if(!this.sub_material_type && this.material_type === 'ZSP' && this.server_code === 'BC') this.errors.push("Sub Material Type required.");

            if(this.type_stock=='Y'){
              if(!this.criticallity) this.errors.push("Criticallity required.");
              if(!this.safety_stock) this.errors.push("Safety Stock required.");
              if(!this.average_usage) this.errors.push("Average Usage required.");
            }
            
            if(this.errors.length){
                self.error_dialog = true;

            }else{
                var url = "";
                var id  =  this.$route.params.id;
                var item_status = "DRAFT";
                var type_input  = "NEW";
                self.loading = true;

                if(id){
                    id = atob(this.$route.params.id);
                    
                    if(this.type_input=='edit'){
                        if(self.part_no==''){
                          url = this.base_url + 'material/update'; 
                        }else{
                          url = this.base_url + 'material/update_new'; 
                        }

                        type_input = "NEW";

                    }else if(this.type_input=='extended'){
                        if(self.part_no!=''){
                          url = this.base_url + 'material/add';
                          type_input = "EXT";

                        }else{
                          url = this.base_url + 'material/add_new';
                          type_input = "EXT";
                        }
                    }
                
                }else{
                    if(self.part_no!=''){
                      url = this.base_url + 'material/add';
                      type_input = "NEW";

                    }else{
                      url = this.base_url + 'material/add_new';
                      type_input = "NEW";
                    }
                }
                
                if(tipe_save=='SUBMIT'){
                    if(self.type_stock=='N'){
                        item_status = "CAT";
                    }else if(self.type_stock=='Y'){
                        item_status = 'APP1';
                    }
                }
                
                var company_name ="";
                if(self.company_id){
                    company_name = this.list_company.find(a=>a.COMPANY_ID==this.company_id).COMPANY_NAME;
                }
                
                //fileupload

                var attachment_name = self.attachment_str;
                if(self.attachment.name){
                  attachment_name = self.storage_user_id + "-" + self.attachment.name;
                }else{
                  attachment_name = self.attachment_str;
                }
                 
                self.axios.post(url , {
                    request_no : self.request_no,
                    request_by : self.request_by,
                    request_date : self.request_date,
                    inc_code : self.inc_code,
                    inc : self.inc,
                    description : self.description,
                    additional_information : self.additional_information,
                    colloquial_name : self.colloquial_name,
                    brand : self.brand,
                    part_no : self.part_no,
                    base_uom : self.base_uom,
                    material_group : self.material_group,
                    external_group : self.external_group,
                    company_id : self.company_id,
                    company_name : company_name,
                    plant : self.plant,
                    storage_location : self.storage_location,
                    average_usage : self.average_usage,
                    type_stock : self.type_stock,
                    safety_stock : self.safety_stock,
                    criticallity : self.criticallity,
                    material_type : self.material_type,
                    sub_material_type : self.sub_material_type,
                    server_code : self.server_code,
                    type_input : type_input,
                    item_status : item_status,
                    message : self.message,
                    user_id : self.storage_user_id,
                    departement_id : self.storage_departement_id,
                    email_approval : self.email_approval,
                    user_approval : self.user_approval,
                    json_inc_attribute : self.json_inc_attribute,
                    requester_description : self.description,
                    requester_additional_info : self.additional_information,
                    requester_json_inc : self.json_inc_attribute,
                    part_no_save : self.part_no_save,
                    attachment : attachment_name
                })
                .then(function (response) {
                    if(response.data==true){
                        
                        self.upload_file();

                        if(tipe_save=='SUBMIT'){
                            alert("Item successfully submitted");
                        }else{
                            alert("Item successfully saved");
                        }
                        
                        self.$router.push('/listRegister');
                    }else{
                        alert(response.data);
                    }

                    self.loading = false;
                    self.container = true;
                })
                .catch(function (error) {
                    self.result = error;
                    self.loading = false;
                    self.container = true;
                });
                    
            }     
      },
      getDate(){
        var date = new Date().toLocaleString('en-US',{hour12:false}).split(" ");
        //var time = date[1];
        var mdy = date[0];

        mdy = mdy.split('/');
        var month = parseInt(mdy[0]);
        var day = parseInt(mdy[1]); 
        var year = parseInt(mdy[2]);

        // Putting it all together
        var formattedDate = year + '-' + month + '-' + day; 
        return formattedDate;
      }, 
      getMaterialDetail(){
          var id = atob(this.$route.params.id);
          var self = this;
          var url = "";
          //alert(self.type_input);
          if(this.type_input=='edit'){
              url = this.base_url + "dropdown/dropdynamic/tm_item_registration/REQUEST_NO/" + id;

          }else if(this.type_input=='extended'){
              url = this.base_url + "dropdown/dropdynamic/tm_initial_item/MATERIAL_NUMBER/" + id;
          }
          
          self.loading = true;
          this.axios.get(url).then(function (response) {
            
              self.data_material = response.data;
              self.request_no = self.data_material[0].REQUEST_NO;
              self.inc_code = self.data_material[0].INC_CODE;
              self.description = self.data_material[0].DESCRIPTION;
              self.modalItemDesc = self.data_material[0].DESCRIPTION;
              self.additional_information = self.data_material[0].ADDITIONAL_INFORMATION;
              self.colloquial_name = self.data_material[0].COLLOQUIAL_NAME;
              self.brand = self.data_material[0].BRAND;
              self.type_stock = self.data_material[0].TYPE_STOCK; 
              self.part_no = self.data_material[0].PART_NO;
              self.base_uom = self.data_material[0].BASE_UOM;
              //self.server_code = self.data_material[0].SERVER_CODE;
              self.server_code =atob(self.$route.params.server);
              self.message = self.data_material[0].MESSAGE;
              self.storage_location = self.data_material[0].STORAGE_LOCATION;
              self.attachment_str = self.data_material[0].ATTACHMENT;

               if(self.type_input=='edit'){
                  self.material_type = self.data_material[0].MATERIAL_TYPE;
                  self.company_id = self.data_material[0].COMPANY_ID;
                  self.company_name = self.data_material[0].COMPANY_NAME;
                  self.plant = self.data_material[0].PLANT;
                  self.storage_location = self.data_material[0].STORAGE_LOCATION;
                  self.average_usage = self.data_material[0].AVERAGE_USAGE;
                  self.safety_stock = self.data_material[0].SAFETY_STOCK;
                  self.criticallity = self.data_material[0].CRITICALLITY;
               }
              
              self.part_no_save = self.data_material[0].PART_NO;
              self.getCompany();             
              self.getMaterialGroupReq(self.data_material[0].SERVER_CODE);
              self.getPlant();
              
              self.plant = self.data_material[0].PLANT;
              self.material_group = self.data_material[0].MATERIAL_GROUP;
              self.external_group = self.data_material[0].EXTERNAL_GROUP;
              self.getStorageLocation();

              if(self.type_input=='edit'){
                  var type_input = self.data_material[0].TYPE_INPUT;
                  self.inc = self.data_material[0].INC;
                  if(self.type_stock=='Y'){
                      self.disabledStock=false;
                  }
                  if(type_input=='EXT'){
                    self.disabledMaterial = true;
                  }

              }else if(self.type_input=='extended'){ 
                  self.inc = self.data_material[0].DESCRIPTION;
                  //self.list_server_code.splice(self.list_server_code.findIndex(item => item.SERVER_CODE === self.server_code),1);  
                  self.disabledMaterial = true;
              }

              self.shortname = self.inc;
              self.attr[0] = self.inc;

              self.json_inc_attribute = self.data_material[0].INC_ATTRIBUTE;
              var json_parse_inc = JSON.parse(self.json_inc_attribute);
              
              self.requester_description = self.data_material[0].REQUESTER_DESCRIPTION;
              self.requester_additional_info = self.data_material[0].REQUESTER_ADDITIONAL_INFO;
              var json_requester = self.data_material[0].REQUESTER_JSON_INC;
              self.list_inc_parse = JSON.parse(json_requester);

              if(self.json_inc_attribute){
                  var total_attr = json_parse_inc.length;
                  for(var a=1;a<=total_attr;a++){
                      self.attr[json_parse_inc[a].index] = json_parse_inc[a].val;
                      
                      if(json_parse_inc[a].index=='add'){
                        self.AttrAdditionalInfo = json_parse_inc[a].val;
                      }
                  }
              }
             
              setTimeout(() => (
                  self.loading = false
              ), 700);
          })
          .catch(function (error) {
              self.result = error;
              self.loading = false;
              self.container = true;
          });
      },
      clearAll(){
              var self = this;
              self.description = "";
              self.modalItemDesc = "";
              self.additional_information = "";
              self.AttrAdditionalInfo = "";            
      }
    },
    watch:{
      dialog2(){
        
        this.alert_required=false
      },
      type_stock(){
        this.getMaterialType()
      //   let val = this.type_stock
      //   if(this.server_code ==="BC"){
      //         let materials = this.list_material_type;
      //   console.log("type stock adalah")
      //         console.log(materials)
      //         let filteredMaterials
      //         if(val== "Y"){
      //        filteredMaterials = materials.filter(item => 
      //               item.MATERIAL_TYPE_CODE === "ZOS" || item.MATERIAL_TYPE_CODE === "ZSP"
      //         );
      //       }else if(val== "N"){

      //         filteredMaterials = materials.filter(item => 
      //               item.MATERIAL_TYPE_CODE === "ZOS" || item.MATERIAL_TYPE_CODE === "ZNS"
      //         );
      //       }
      //       this.list_material_type = filteredMaterials;
            
      // }
      
    }
  },
    mounted(){ 
        this.getServer();
        //this.getMaterialGroup();
        this.getBrand();
        this.getUOM();
        this.request_date = this.getDate();
        this.request_by = this.storage_username;
        this.additional_information.disabled;
        this.getMailApproval();
        if(this.$route.params.id){
          this.getMaterialDetail();
        }

        this.server_code = atob(this.$route.params.server);
        this.getCompany();
        if(this.storage_role !='REQ'){
            this.$router.push('/login');
        }
        this.setItemDesc();
    },
    data () { 
      return {
        flat : true,
        shortname : "",
        design : "",
        typem : "",
        size: "",
        shape:"",
        color:"",
        inc_code:"",
        name : "Registration",
        title : "Item Registration Form",
        dialog: false,
        dialog2: false,
        modalItemDesc:"",
        modalAdditionalInfo:"",
        select: { BRAND_CODE: '1', BRAND_NAME: 'Catterpilar' },
        errors:[],
        uom:[],
        type: [],
        disabledMaterial:false,
        error_dialog:false,
        date:"",
        isDisabled:false,
        attr:[],
        disabledAttr:[],
        disabledStock:true,
        AttrAdditionalInfo:'',
        loading:false,
        //Approval........................
        email_approval:'',
        user_approval:'',

        //searchparam................................
        searchInc:"",
        countString:0,
        
        //storage.....................................
        storage_user_id : atob(localStorage.getItem("user_id")),
        storage_username : atob(localStorage.getItem("username")),
        storage_fullname : atob(localStorage.getItem("fullname")),
        storage_company_id : atob(localStorage.getItem("company_id")),
        storage_company_name : atob(localStorage.getItem("company_name")),
        storage_role : atob(localStorage.getItem("role")),
        storage_server : atob(localStorage.getItem("server")),
        storage_departement_id : atob(localStorage.getItem("departement_id")),

        //v-list.......................................
        list_inc : "",
        list_brand : "",
        list_base_uom : [],
        list_material_group : [],
        list_external_group : [],
        list_company : [],
        list_plant : [],
        list_storage_location : [],
        list_safety_stock : "",
        list_inc_attribute : [],
        list_criticallity : ['Support','Critical','Insurance'],
        list_material_type : [],
        list_sub_material_type: [{SUB_MATERIAL_TYPE_NAME:"VHS",SUB_MATERIAL_TYPE_CODE:"VHS"},{SUB_MATERIAL_TYPE_NAME:"NON VHS",SUB_MATERIAL_TYPE_CODE:"NON_VHS"}],
        list_valuation_class : "",
        list_server_code : ['GEMS','BKES','BC'],
        list_item_status : "",
        list_cataloguer : "",
        list_typestock : [{"TYPE_STOCK_ID":"Y","TYPE_STOCK_VAL":"Stock"},{"TYPE_STOCK_ID":"N","TYPE_STOCK_VAL":"Non Stock"}],
        data_material:"",

        //v-model.......................................
        request_no : "",
        request_by : "",
        request_date : "",
        inc : "",
        description : "",
        additional_information : "",
        colloquial_name : "",
        colloquial_name_info : "",
        brand : "",
        part_no : "",
        base_uom : "",
        material_group : "", 
        external_group : "",
        company_id : "",
        company_name : "",
        plant : "",
        storage_location : "",
        average_usage : "",
        safety_stock : "",
        criticallity : "",
        material_type : "",
        valuation_class : "",
        attachment : [],
        attachment_str : "",

        server_code : "",
        message : "",
        item_status : "",
        cataloguer : "",
        json_inc_attribute : "",
        requester_description : "",
        requester_additional_info : "",
        requester_json_inc : "",
        part_no_save : "",
        required_field_pass:false,
        firstCharIsSymbol:false,
        alert_required:false,
        type_stock:"",
        //.............................Params
        type_input : this.$route.params.type,
        server_route : atob(this.$route.params.server),
        search_input : []
        //id : atob(this.$route.params.id)
      }
    },
  }
</script>
