<template>
  <v-app>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="desserts"
    :single-select="singleSelect"
    item-key="REQUEST_NO"
    show-select
    style="padding:25px">

    <template v-slot:top>
      <div>
          <div>
            <v-toolbar style="box-shadow:none !important">
              <v-toolbar-title>
                <b>
                  {{title}}
                </b>
              </v-toolbar-title>

              <v-spacer></v-spacer>

              

              <v-spacer></v-spacer>
              <v-btn icon v-on:click="getMaterial">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-toolbar>

            <div style="padding:0px 25px">
                <v-layout row>
                  <v-flex md8></v-flex>

                  <v-flex md4>
                      <v-col cols="12" sm="6" md="12">
                        <v-text-field
                          
                          v-model="val"
                           v-on:keyup.enter="getMaterial()"
                          placeholder="Enter Your Keywords"
                          solo
                          append-icon="search"
                        ></v-text-field>
                      </v-col>
                  </v-flex>

                </v-layout>   
            </div>
        </div>
      </div>

      <v-dialog
        v-model="modal_message"
        max-width="700px"
      >
        <v-card>
          <v-card-title>
            Message
          </v-card-title>
          <v-card-text>
              <div style="height:300px;max-height:400px;overflow-y:scroll;padding:20px">
                    <div>
                      <span>Message From Requester</span>
                      <div v-if="requester_message" style="padding:15px 20px;margin-top:10px;background-color:#fafafa;border-radius:10px;width:90%">
                          {{requester_message}}
                          
                          <div align="right" style="font-size:12px">{{requester_date}}</div>
                      </div>                     
                    </div>

                    <div v-if="!requester_message" style="padding:20px;margin-top:10px;background-color:#fafafa;border-radius:10px;width:90%">
                        Not Message Entry From Requester
                    </div>
                    <br>
                    <div>
                      <span>Message Note</span>
                      <div  v-for="message in list_message" v-bind:key="message.ID" style="padding:15px 20px;color:#fff;background-color:#1976d2;border-radius:10px;width:90%;margin-top:15px">
                          <b>{{message.NAME}}</b>, {{message.DEPARTEMENT_DESC}} | {{message.ROLE_ID}}<br>
                          {{message.MESSAGE}}
                          
                          <div align="right" style="font-size:12px">{{message.MESSAGE_DATE}}</div>
                      </div>                     
                    </div> 
              </div>
              <div style="padding-top:5px">
                  <v-text-field
                    label="Enter Message"
                    v-model="message"
                    placeholder="Enter Message"
                    append-icon="send"
                    @click:append="sendMessage()"
                    v-on:keyup.enter="sendMessage()"
                  ></v-text-field>
              </div>
              <div align="right">
                  <v-btn
                  color="primary"
                  text
                  @click="modal_message = false"
                >
                  Close
                </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>



      <v-dialog
        v-model="modal_detail"
        max-width="85%"  
      >
        <v-card>
          <v-card-title>
            Detail Item
          </v-card-title>
          <v-card-text>

          <template>
              <v-tabs
                  v-model="tab"
                >
                  <v-tabs-slider></v-tabs-slider>

                  <v-tab href="#tab-1">
                    Detail
                  </v-tab>

                  <v-tab href="#tab-2">
                    Log
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item  key="1" value="tab-1">
                    <v-card flat>
                      <v-card-text>
                          <div style="padding-left:10px">
                                <v-layout row>
                                  <v-flex md6>
                                      <table width="100%" cellspacing="15">
                                          <tr>
                                            <td width="35%">Item Code</td>
                                            <td><b>{{detail.INC_CODE}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Item Name</td>
                                            <td><b>{{detail.INC}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Description</td>
                                            <td><b>{{detail.DESCRIPTION}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Additional Information</td>
                                            <td><b>{{detail.ADDITIONAL_INFORMATION}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Brand</td>
                                            <td><b>{{detail.BRAND}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Part No</td>
                                            <td><b>{{detail.PART_NO}}</b></td>
                                          </tr>
                                          
                                          <tr>
                                            <td>Material Type</td>
                                            <td><b>{{detail.MATERIAL_TYPE}} - {{detail.MATERIAL_TYPE_NAME}}</b></td>
                                          </tr>
                                          
                                          <tr>
                                            <td>Criticallity</td>
                                            <td><b>{{detail.CRITICALLITY}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Request By</td>
                                            <td><b>{{detail.REQUEST_BY}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Request Date</td>
                                            <td><b>{{detail.DATE}}</b></td>
                                          </tr>
                                      </table>
                                  </v-flex>

                                  <v-flex md6>
                                      <table width="100%" cellspacing="15">
                                          <tr>
                                            <td width="35%">Server</td>
                                            <td><b>{{detail.SERVER_CODE}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Company</td>
                                            <td><b>{{detail.COMPANY_NAME}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Plant</td>
                                            <td><b>{{detail.PLANT_NAME}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Storage Location</td>
                                            <td><b>{{detail.STORAGE_LOCATION_NAME}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Stock Type</td>
                                            <td>
                                              <b v-if="detail.TYPE_STOCK=='Y'">STOCK</b>
                                              <b v-if="detail.TYPE_STOCK=='N'">NON STOCK</b>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Material Group</td>
                                            <td><b>{{detail.MATERIAL_GROUP}} - {{detail.MATERIAL_GROUP_NAME}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>External Group</td>
                                            <td><b>{{detail.MATERIAL_GROUP}} - {{detail.EXTERNAL_GROUP_NAME}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>UOM</td>
                                            <td><b>{{detail.UOM_NAME}}</b></td>
                                          </tr>
                                          <tr>
                                            <td>Safety Stock</td>
                                            <td><b>{{detail.SAFETY_STOCK}}</b></td>
                                          </tr>

                                          <tr>
                                            <td>Average Usage</td>
                                            <td><b>{{detail.AVERAGE_USAGE}}</b></td>
                                          </tr>
                                      </table> 
                                  </v-flex>
                                </v-layout>    
                            </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item key="2" value="tab-2" >
                    <v-card flat>
                      <v-card-text>
                          <div style="height:300px;padding-top:20px">
                            <template>
                              <v-simple-table>
                                <template>
                                  <thead>
                                    <tr>
                                      <th class="text-left" width="50"></th>
                                      <th class="text-left" width="350">Date</th>
                                      <th class="text-left">Activity</th>
                                      <th class="text-left">Role</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-if="log.SUBMIT_DATE">
                                        <td>
                                            <v-icon
                                              color="primary"
                                              large
                                              class="mr-2"
                                            >
                                              mdi-calendar
                                            </v-icon>
                                        </td>
                                        <td>
                                          <b>{{log.SUBMIT_DATE}}</b>
                                        </td>
                                        <td>
                                          Submitted By <b>{{detail.REQUEST_BY}} ({{detail.DEPARTEMENT_ID}})</b>
                                        </td>
                                        <td>
                                          Requester
                                        </td>
                                    </tr>

                                    <tr v-if="log.APPROVAL1_DATE">
                                        <td>
                                            <v-icon
                                              color="primary"
                                              large
                                              class="mr-2"
                                            >
                                              mdi-calendar
                                            </v-icon>
                                        </td>
                                        <td>
                                         <b>{{log.APPROVAL1_DATE}}</b>
                                        </td>
                                        <td>
                                          Approved By <b>{{log.USER_APPROVAL1}} ({{log.DEPARTEMENT_APPROVAL1}})</b>
                                        </td>
                                        <td>
                                          Approval 1
                                        </td>
                                    </tr>

                                    <tr v-if="log.APPROVAL2_DATE">
                                        <td>
                                            <v-icon
                                              color="primary"
                                              large
                                              class="mr-2"
                                            >
                                              mdi-calendar
                                            </v-icon>
                                        </td>
                                        <td>
                                          <b>{{log.APPROVAL2_DATE}}</b>
                                        </td>
                                        <td>
                                          Approved By <b>{{log.USER_APPROVAL2}} ({{log.DEPARTEMENT_APPROVAL2}})</b>
                                        </td>
                                        <td>
                                          Approval 2
                                        </td>
                                    </tr>

                                      <tr v-if="log.CATALOGUER_DATE">
                                          <td>
                                              <v-icon
                                                color="primary"
                                                large
                                                class="mr-2"
                                              >
                                                mdi-calendar
                                              </v-icon>
                                          </td>
                                          <td>
                                            <b>{{log.CATALOGUER_DATE}}</b>
                                          </td>
                                          <td>
                                            Cataloguing By <b>{{log.USER_CATALOGUER}}</b>
                                          </td>
                                          <td>
                                            Cataloguer
                                          </td>
                                      </tr>

                                      <tr v-if="log.REGISTER_SAP_DATE">
                                          <td>
                                              <v-icon
                                                color="primary"
                                                large
                                                class="mr-2"
                                              >
                                                mdi-calendar
                                              </v-icon>
                                          </td>
                                          <td>
                                            <b>{{log.REGISTER_SAP_DATE}}</b>
                                          </td>
                                          <td>
                                            Register TO SAP By <b>{{log.USER_CATALOGUER}}</b>
                                          </td>
                                          <td>
                                            Cataloguer
                                          </td>
                                      </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </template>
                          </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                </v-tabs-items>

                <div align="right">
                    <v-btn
                    color="primary"
                    text
                    @click="modal_detail = false"
                  >
                    Close
                  </v-btn>
              </div>
            </template>

              
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>

    <template v-slot:item.action="{ item }">
      
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">
              <v-icon
              medium
              class="mr-2"
              v-if="!item.MESSAGE"
              @click="getMessage(item)"
            >
              email
            </v-icon>

            <v-icon
              medium
              color="primary"
              class="mr-2"
              v-if="item.MESSAGE"
              @click="getMessage(item)"
            >
              email
            </v-icon>
          </span>
        </template>
        <span>Message</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">
              <v-icon
                medium
                color="primary"
                class="mr-2"
                @click="getDetail(item)"
              >
                mdi-book-open
              </v-icon>
          </span>
        </template>
        <span>Detail</span>
    </v-tooltip>

      
    </template>

    <template v-slot:item.status="{ item }">
        <span v-if="item.ITEM_STATUS=='DRAFT'">Draft</span>
        <span v-if="item.ITEM_STATUS=='NI'">Need Info Requester</span>
        <span v-if="item.ITEM_STATUS=='APP1'">Waiting Approval 1</span>
        <span v-if="item.ITEM_STATUS=='APP2'">Waiting Approval 2</span>
        <span v-if="item.ITEM_STATUS=='CAT'">Waiting Cataloguing</span>
        <span v-if="item.ITEM_STATUS=='DRAFT_CAT'">Waiting Sent to SAP</span>
        <span v-if="item.ITEM_STATUS=='REG'">Registered on SAP</span>
        <span v-if="item.ITEM_STATUS=='FAIL'">Failed</span>
        <span v-if="item.ITEM_STATUS=='RJT'">Rejected</span>
    </template>

  </v-data-table>
  </v-app>
</template>


<script>
export default {
    methods: {
        getMaterial(){
         var self = this;
          var val  = self.val; 
         
          var url  = self.base_url + "search/item_registration";
          
          this.axios.post(url , {
              param:val,
              user_id : self.storage_user_id,
              status  : '',//self.storage_role,
              departement_id : self.storage_departement_id,
              server : self.storage_server 
          })
          .then(function (response) {
              self.desserts = response.data;
          });
      },
        getMessage(item){
            var self= this;
            self.modal_message = true;
            self.requester_message = item.MESSAGE;
            self.requester_date = item.DATE;
            self.request_no = item.REQUEST_NO;
            self.listMessage();
        },
        edit(item){
          var id = item.REQUEST_NO;
          this.$router.push({path: '/catalogRegister/edit/'+id});
        },
        listMessage(){
          var self = this;
          var url  = self.base_url + "mail/list_message";
          this.axios.post(url , {
              request_no : self.request_no
          })
          .then(function (response) {
              self.list_message = response.data;
          });
        },
        getDetail(item){
            var self= this;
            self.modal_detail = true;
            self.detail = item;

            var url  = self.base_url + "master/log";
            this.axios.post(url , {
                request_no : item.REQUEST_NO
            })
            .then(function (response) {
                self.log = response.data[0];
            });
        }
    },
    mounted(){
        this.getMaterial();
        if(this.storage_role !='REQ'){
          this.$router.push('/login');
        }
    },
    data () { 
      return {
        name : "Registration",
        title : "Item Registration List",
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
        singleSelect: false,
        selected: [],
        showSelect:false,
        val:'',
        log:'',
        tab:'tab-1',
        headers: [
          { text: 'Action', value: 'action', sortable: false,width:130},
          { text: 'Status', value: 'status',width:'180' },
          { text: 'Requester', value: 'REQUEST_BY',width:'130' },
          { text: 'Request Date', value: 'REQUEST_DATE',width:'130' },
          { text: 'Server', value: 'SERVER_CODE',width:'110' },
          { text: 'INC Code', value: 'INC_CODE',width:'150' },
          { text: 'Item Name', value: 'INC',width:'250' },
          { text: 'Description', value: 'DESCRIPTION',width:'250'},
          { text: 'Brand', value: 'BRAND',width:'150' },
          { text: 'Part Number', value: 'PART_NO',width:'200' },
          { text: 'Company', value: 'COMPANY_NAME',width:'250' },
          { text: 'Plant', value: 'PLANT_NAME',width:'170' },
          { text: 'Storage Location', value: 'STORAGE_LOCATION_NAME',width:'180' },
          { text: 'UOM', value: 'UOM_NAME',width:'150' },
          { text: 'Criticallity', value: 'CRITICALLITY',width:'150' },
          { text: 'Avg Usage', value: 'AVERAGE_USAGE',width:'120' },
          { text: 'Notes', value: 'MESSAGE',width:'200' },
        ],
        desserts: [],
        list_message:[],
        user_id:'',
        email_approval:'imasterdevelope2@gmail.com',
        user_request:'',
        departement_id:'',
        username:'',
        user_approval_request:'',
        request_no : '',
        message : '',
        requester_message:'',
        requester_date:'',
        modal_message:'',
        modal_detail:false,
        detail:[],

        //storage.....................................
        storage_user_id : localStorage.getItem("user_id"),
        storage_username : localStorage.getItem("username"),
        storage_fullname : localStorage.getItem("fullname"),
        storage_company_id : localStorage.getItem("company_id"),
        storage_company_name : localStorage.getItem("company_name"),
        storage_role : localStorage.getItem("role"),
        storage_departement_id : localStorage.getItem("departement_id"),
        storage_server : localStorage.getItem("server")
      }
    },
  }
</script>
