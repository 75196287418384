<style>
.hello {
  width: 100%;
  height: 500px;
  margin-top: 20px;
}
</style>

<template>
  <div style="padding: 40px; background-color: #fff;">
    <div>
      <b
        ><h3>{{ title }}</h3></b
      >
    </div>
    <v-layout>
      <v-toolbar style="box-shadow: none; background-color: #fff;">
        <v-layout row>
          <v-flex md2>
            <div style="padding-top: 10px;">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date"
                    label="Start Date"
                    append-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false"
                    >Cancel</v-btn
                  >
                  <v-btn text color="primary" @click="$refs.menu.save(date)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </div>
          </v-flex>

          <v-flex md2>
            <div style="margin-left: 20px; padding-top: 10px;">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="date2"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date2"
                    label="End Date"
                    append-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date2" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false"
                    >Cancel</v-btn
                  >
                  <v-btn text color="primary" @click="$refs.menu2.save(date2)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </div>
          </v-flex>

          <v-flex md2>
            <div style="margin-left: 20px; padding-top: 10px;">
              <v-select
                item-text="SERVER_NAME"
                item-value="SERVER_CODE"
                v-model="server_code"
                :items="list_server_code"
                :rules="rules"
                label="Choose Server"
                v-on:change="getCompany(), nullCompany()"
              ></v-select>
            </div>
          </v-flex>

          <v-flex md3>
            <div style="margin-left: 20px; padding-top: 10px;">
              <v-autocomplete
                item-text="COMPANY_NAME"
                item-value="COMPANY_ID"
                v-model="company_id"
                :items="list_company"
                :rules="rules"
                label="Choose Company"
                v-bind:value="{ id: COMPANY_ID, text: COMPANY_NAME }"
              ></v-autocomplete>
            </div>
          </v-flex>

          <v-flex md3>
            <div style="margin-left: 20px; padding-top: 20px;">
              <v-btn color="primary" v-on:click="getData">
                Search
              </v-btn>
              &nbsp;

              <v-btn color="success" dark v-on:click="alldata()">
                All Data
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-toolbar>
    </v-layout>
    <br />

    <v-layout>
      <v-flex md12>
        <div>Request Company</div>

        <!--<v-alert type="error" v-if="chart_data1==''">
               Company Data is Not Available
            </v-alert>-->

        <div class="hello" id="chartdiv"></div>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex md12>
        <br /><br />
        <div>Request Department</div>

        <!--<v-alert type="error" v-if="chart_data2==''">
               Department Data is Not Available
            </v-alert>-->

        <div class="hello" id="chartdiv2"></div>
      </v-flex>
    </v-layout>

    <div style="padding: 15px; display: none;">
      <template>
        <v-data-table
          :headers="headers"
          :items="desserts"
          :items-per-page="5"
          class="elevation-1"
        ></v-data-table>
      </template>
    </div>
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

export default {
  methods: {
    getData() {
      var self = this;
      var url = self.base_url + "dashboard/top_requester2";
      var url2 = self.base_url + "dashboard/top_requester1";

      self.axios
        .post(url, {
          server: self.server_code,
          company: self.company_id,
          startdate: self.date,
          enddate: self.date2,
        })
        .then(function (response) {
          self.chart_data1 = response.data;
          self.chart();
        });

      self.axios
        .post(url2, {
          server: self.server_code,
          company: self.company_id,
          startdate: self.date,
          enddate: self.date2,
        })
        .then(function (response) {
          self.chart_data2 = response.data;
          self.chart2();
        });
    },
    chart() {
      var self = this;
      am4core.ready(function () {
        am4core.useTheme(am4themes_animated);
        var chart = am4core.create("chartdiv", am4charts.XYChart);

        chart.data = self.chart_data1;

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "SHORTNAME";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;

        categoryAxis.renderer.labels.template.adapter.add("dy", function (
          dy,
          target
        ) {
          if (target.dataItem && target.dataItem.index & (2 == 2)) {
            return dy + 25;
          }
          return dy;
        });

        chart.yAxes.push(new am4charts.ValueAxis());

        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "TOTAL";
        series.dataFields.categoryX = "SHORTNAME";
        series.name = "Total Item";
        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        series.columns.template.fillOpacity = 0.8;

        var columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 0;
        columnTemplate.strokeOpacity = 1;
      });
    },
    chart2() {
      var self = this;
      am4core.ready(function () {
        am4core.useTheme(am4themes_animated);

        var chart = am4core.create("chartdiv2", am4charts.XYChart);

        // Add data
        chart.data = self.chart_data2;

        // Create axes

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "SHORTNAME";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.renderer.labels.template.horizontalCenter = "right";
        categoryAxis.renderer.labels.template.verticalCenter = "middle";
        categoryAxis.renderer.labels.template.rotation = 270;

        categoryAxis.renderer.labels.template.adapter.add("dy", function (
          dy,
          target
        ) {
          if (target.dataItem && target.dataItem.index & (2 == 2)) {
            return dy + 25;
          }
          return dy;
        });

        chart.yAxes.push(new am4charts.ValueAxis());

        // Create series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "TOTAL";
        series.dataFields.categoryX = "SHORTNAME";
        series.name = "Total Item";
        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        series.columns.template.fillOpacity = 0.8;
        series.tooltip.pointerOrientation = "vertical";

        var columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 0;
        columnTemplate.fill = "#03af70";
        columnTemplate.strokeOpacity = 1;
      });
    },
    getServer() {
      var self = this;
      var url = this.base_url + "dropdown/server_dashboard";
      this.axios.get(url).then(function (response) {
        self.list_server_code = response.data;
      });
    },
    getCompany() {
      var self = this;
      var server_code = self.server_code;
      if (server_code == "") {
        server_code = "-";
      }
      var url = this.base_url + "dropdown/company_dashboard/" + server_code;
      this.axios.get(url).then(function (response) {
        self.list_company = response.data;
      });
    },
    nullCompany() {
      this.company_id = "";
      this.list_company = [];
    },
    alldata() {
      var self = this;
      self.server_code = "";
      self.company_id = "";
      self.date = "";
      self.date2 = "";
      self.list_company = [];
      self.getData();
    },
    getDateMonth() {
      var today = new Date();
      var year = today.getFullYear();
      var month = today.getMonth();
      var date = today.getDate();
      if (month - 1 <= 0) year = today.getFullYear();
      var backdate = new Date(year, month - 1, date)
        .toISOString()
        .substr(0, 10);
      this.date = backdate;
    },
  },
  mounted() {
    if (this.storage_role != "CAT" && this.storage_role != "ADM") {
      this.$router.push("/login");
    }

    this.getDateMonth();
    this.getData();
    this.getServer();
    this.getCompany();
  },
  data() {
    return {
      name: "ApproveRatio",
      title: "Top Requester",
      items: [],
      desserts: [],
      headers: [
        { text: "Action", value: "action", sortable: false, width: 150 },
        { text: "Approval", value: "status" },
        { text: "Total Item", value: "status" },
      ],

      chart_data1: [],
      chart_data2: [],
      list_company: [{ COMPANY_ID: "", COMPANY_NAME: "All Company" }],
      list_server_code: [],

      server_code: "GEMS",
      company_id: "",
      date: "",
      date2: new Date().toISOString().substr(0, 10),

      //storage.....................................
      storage_user_id: atob(localStorage.getItem("user_id")),
      storage_username: atob(localStorage.getItem("username")),
      storage_fullname: atob(localStorage.getItem("fullname")),
      storage_company_id: atob(localStorage.getItem("company_id")),
      storage_company_name: atob(localStorage.getItem("company_name")),
      storage_role: atob(localStorage.getItem("role")),
      storage_server: atob(localStorage.getItem("server")),
      storage_departement_id: atob(localStorage.getItem("departement_id")),
    };
  },
};
</script>
