import Login from './modules/login/Login.vue';
import DashView from './Dash.vue';
import Apps from './Apps.vue';

import Dashboard from './modules/dashboard/Dashboard.vue';
import SearchRegistration from './modules/registration/SearchRegistration.vue';
import ListRegistration from './modules/registration/ListRegistration.vue';
import AllRegistration from './modules/registration/AllRegistration.vue';
import Registration from './modules/registration/AddRegistration.vue';
import ListCatalog from './modules/catalog/ListCatalog.vue';
import ListApprove from './modules/approve/ListApprove.vue';
import CatalogRegistration from './modules/catalog/CatalogRegistration.vue';

//Master Data
import Company from './modules/master/company/company.vue';
import Server from './modules/master/server/server.vue';
import Plant from './modules/master/plant/plant.vue';
import StorageLocation from './modules/master/storage_location/storage_location.vue';
import Uom from './modules/master/uom/uom.vue';
import MaterialType from './modules/master/material_type/material_type.vue';
import MaterialGroup from './modules/master/material_group/material_group.vue';
import ExternalGroup from './modules/master/external_group/external_group.vue';
import ProfitCenter from './modules/master/profit_center/profit_center.vue';
import ValuationClass from './modules/master/valuation_class/valuation_class.vue';
import PriceControl from './modules/master/price_control/price_control.vue';
import LabOffice from './modules/master/lab_office/lab_office.vue';

//Material
import InitialItem from './modules/master/initial_item/initial_item.vue';
import Inc from './modules/master/inc/inc.vue';
import IncAttribute from './modules/master/inc_attribute/inc_attribute.vue';

//User
import User from './modules/master/user/user.vue';
import Role from './modules/master/role/role.vue';
import Departement from './modules/master/departement/departement.vue';
import DepartementRole from './modules/master/approval_setting/approval_setting.vue';
import CataloguerRole from './modules/master/cataloguer_setting/cataloguer_setting.vue';

import ApproveRatio from './modules/dashboard/approve_ratio.vue';
import RequestRatio from './modules/dashboard/request_ratio.vue';
import AverageSLA from './modules/dashboard/average_sla.vue';
import TopRequester from './modules/dashboard/top_requester.vue';
import TopApprover from './modules/dashboard/top_approver.vue';
import TopComplain from './modules/dashboard/top_complain.vue';

import AverageSLAUser from './modules/dashboard/average_sla_user.vue';
//Log
//import LogActivity from './modules/master/log_activity/log_activity.vue';
//import LogService from './modules/master/log_service/log_service.vue';
//import Departement from './modules/master/departement/departement.vue';

import ServiceInitial from './modules/catalog/ServiceInitial.vue';

const routes = [
    {
      path: '/login',
      component: Login
    },
    {
      path: '/Apps/:token',
      component: Apps
    },
    {
      path: '/',
      component: DashView,
      children: [
        {
            path: 'dashboard',
            alias: '',
            component: Dashboard,
            name: 'Dashboard'
        },
        {
            path: 'searchRegister',
            alias: '',
            component: SearchRegistration,
            name: 'SearchRegister',
        },
        {
            path: 'listRegister/:status?',
            alias: '',
            component: ListRegistration,
            name: 'ListRegister'
        },
        {
            path: 'allRegister/',
            alias: '',
            component: AllRegistration,
            name: 'AllRegister'
        },
        {
            path: 'addRegister/:server?/:type?/:id?',
            alias: '',
            component: Registration,
            name: 'AddRegister'
        },
        {
            path: 'listCatalog',
            alias: '',
            component: ListCatalog,
            name: 'ListCatalog'
        },
        {
            path: 'catalogRegister/:type?/:id?',
            alias: '',
            component: CatalogRegistration,
            name: 'catalogRegister'
        },
        {
            path: 'serviceInitial/',
            alias: '',
            component: ServiceInitial,
            name: 'serviceInitial'
        },
        {
            path: 'listApprove',
            alias: '',
            component: ListApprove,
            name: 'ListApprove'
        },
        {
            path: 'listApprove',
            alias: '',
            component: ListApprove,
            name: 'ListApprove'
        },
        {
            path: 'company',
            alias: '',
            component: Company,
            name: 'company'
        },
        {
            path: 'server',
            alias: '',
            component: Server,
            name: 'server'
        },
        {
            path: 'plant',
            alias: '',
            component: Plant,
            name: 'plant'
        },
        {
            path: 'storageLocation',
            alias: '',
            component: StorageLocation,
            name: 'storageLocation'
        },
        {
            path: 'uom',
            alias: '',
            component: Uom,
            name: 'uom'
        },
        {
            path: 'materialType',
            alias: '',
            component: MaterialType,
            name: 'materialType'
        },
        {
            path: 'materialGroup',
            alias: '',
            component: MaterialGroup,
            name: 'materialGroup'
        },
        {
            path: 'externalGroup',
            alias: '',
            component: ExternalGroup,
            name: 'externalGroup'
        },
        {
            path: 'valuationClass',
            alias: '',
            component: ValuationClass,
            name: 'valuationClass'
        },
        {
            path: 'profitCenter',
            alias: '',
            component: ProfitCenter,
            name: 'ProfitCenter'
        },
        {
            path: 'priceControl',
            alias: '',
            component: PriceControl,
            name: 'priceControl'
        },
        {
            path: 'labOffice',
            alias: '',
            component: LabOffice,
            name: 'labOffice'
        },
        {
            path: 'initialItem',
            alias: '',
            component: InitialItem,
            name: 'initialItem'
        },
        {
            path: 'inc',
            alias: '',
            component: Inc,
            name: 'inc'
        },
        {
            path: 'incAttribute',
            alias: '',
            component: IncAttribute,
            name: 'incAttribute'
        },
        {
            path: 'departement',
            alias: '',
            component: Departement,
            name: 'departement'
        },
        {
            path: 'role',
            alias: '',
            component: Role,
            name: 'role'
        },
        {
            path: 'user',
            alias: '',
            component: User,
            name: 'user'
        },
        {
            path: 'departementRole',
            alias: '',
            component: DepartementRole,
            name: 'departementRole'
        },
        {
            path: 'cataloguerRole',
            alias: '',
            component: CataloguerRole,
            name: 'cataloguerRole'
        },
        {
            path: 'approveRatio',
            alias: '',
            component: ApproveRatio,
            name: 'approveRatio'
        },
        {
            path: 'requestRatio',
            alias: '',
            component: RequestRatio,
            name: 'requestRatio'
        },
        {
            path: 'averageSLA',
            alias: '',
            component: AverageSLA,
            name: 'averageSLA'
        },
        {
            path: 'averageSLAUser',
            alias: '',
            component: AverageSLAUser,
            name: 'averageSLAUser'
        },
        {
            path: 'topRequester',
            alias: '',
            component: TopRequester,
            name: 'topRequester'
        },
        {
            path: 'topApprover',
            alias: '',
            component: TopApprover,
            name: 'topApprover'
        },
        {
            path: 'topComplain',
            alias: '',
            component: TopComplain,
            name: 'topComplain'
        }
      ]
    }
  ]

export default routes;