<style>
  .hello {
    height: 500px;
    padding:20px;
    box-shadow:0 5px 15px rgba(0,0,0,0.08);
  } 
</style>

<template>

    <div style="padding:40px;background-color:#fff">
      <div>
          <b><h3>{{title}}</h3></b>
      </div>

      <v-layout>
         <v-toolbar style="box-shadow:none;background-color:#fff">
        <v-layout row>
                <v-flex md2>
                    <div style="padding-top:10px">
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="date"
                                label="Start Date"
                                append-icon="event"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="date" no-title scrollable>
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                              <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                            </v-date-picker>
                          </v-menu>
                      </div>
                </v-flex>

                <v-flex md2>
                  <div style="margin-left:20px;padding-top:10px">
                        <v-menu
                          ref="menu2"
                          v-model="menu2"
                          :close-on-content-click="false"
                          :return-value.sync="date2"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="date2"
                              label="End Date"
                              append-icon="event"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="date2" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu2 = false">Cancel</v-btn>
                            <v-btn text color="primary" @click="$refs.menu2.save(date2)">OK</v-btn>
                          </v-date-picker>
                        </v-menu>
                    </div>   
                </v-flex>
                
                <v-flex md2>
                  <div style="margin-left:20px;padding-top:10px">
                        <v-select 
                          item-text="SERVER_NAME"
                          item-value="SERVER_CODE"
                          v-model="server_code"
                          :items="list_server_code"
                          :rules="rules"
                          label="Choose Server"
                          v-on:change="getCompany(),nullCompany()"
                        ></v-select>
                    </div>   
                </v-flex>

                <v-flex md3>
                  <div style="margin-left:20px;padding-top:10px">
                        <v-autocomplete 
                          item-text="COMPANY_NAME"
                          item-value="COMPANY_ID"
                          v-model="company_id" 
                          :items="list_company"
                          :rules="rules"
                          label="Choose Company"
                          v-bind:value="{ id: COMPANY_ID, text: COMPANY_NAME }"
                        ></v-autocomplete>
                    </div>   
                </v-flex>

                

                <v-flex md3>
                    <div style="margin-left:20px;padding-top:10px">
                      <v-btn color="primary" v-on:click="getData">
                        Search
                      </v-btn>
                      &nbsp;
                      <v-btn color="success" dark v-on:click="alldata">
                        All Data
                      </v-btn>
                    </div>
                </v-flex>

              </v-layout>
              </v-toolbar>
        </v-layout>
      <br>
      
      <v-layout style="padding:10px">
          <v-flex md6>
            <div class="hello" id="chartdiv1"></div>
          </v-flex>
          <v-flex md6>
            <div class="hello" id="chartdiv2"></div>
          </v-flex>
      </v-layout>

      <v-layout style="padding:10px">
          <v-flex md12>
            <br>
            <div><h3>Company</h3></div>
            <div class="hello" id="chartdiv3"></div>
          </v-flex>
      </v-layout>
      

      <div style="padding:15px;display:none">
          <template>
            <v-data-table
              :headers="headers"
              :items="desserts"
              :items-per-page="5"
              class="elevation-1"
            ></v-data-table>
          </template>
      </div>
    </div>
</template>


<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

export default {
    methods: {
        getData(){
          var self = this;
          var url  = self.base_url + "dashboard/done_approve1";
          var url2  = self.base_url + "dashboard/done_approve2";
          var url3  = self.base_url + "dashboard/done_approve3";

          self.axios.post(url , {
              server : self.server_code,
              company : self.company_id,
              startdate : self.date,
              enddate : self.date2
          })
          .then(function (response) {
              self.data_done = response.data[0].DONE ?  response.data[0].DONE : 0;
              self.data_onreview = response.data[0].ONREVIEW ?  response.data[0].ONREVIEW : 0;
              self.chart3();             
          });


          self.axios.post(url2 , {
              server : self.server_code,
              company : self.company_id,
              startdate : self.date,
              enddate : self.date2
          })
          .then(function (response) {             
              self.data_reg = response.data[0].REG ?  response.data[0].REG : 0;
              self.data_draft = response.data[0].DRAFT_CAT ?  response.data[0].DRAFT_CAT : 0;
              self.data_rjt = response.data[0].RJT ?  response.data[0].RJT : 0;
              self.chart2();             
          });

          self.axios.post(url3 , {
              server : self.server_code,
              company : self.company_id,
              startdate : self.date,
              enddate : self.date2
          })
          .then(function (response) {                        
              self.chart_data3 = response.data;
              self.chart();
          });
        },
        chart(){
            var self = this;
            am4core.ready(function() {

                // Themes begin
                am4core.useTheme(am4themes_animated);

                var chart = am4core.create("chartdiv3", am4charts.XYChart);

                chart.data = self.chart_data3;

                // Create axes
                var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "SHORTNAME";
                categoryAxis.title.text = "Company";
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.renderer.minGridDistance = 20;
                categoryAxis.renderer.cellStartLocation = 0.1;
                categoryAxis.renderer.cellEndLocation = 0.9;

                var  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.min = 0;
                valueAxis.title.text = "Total Request";

                // Create series
                function createSeries(field, name, stacked,color) {
                  var series = chart.series.push(new am4charts.ColumnSeries());
                  series.dataFields.valueY = field;
                  series.dataFields.categoryX = "SHORTNAME";
                  series.name = name;
                  series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
                  series.stacked = stacked;
                  series.columns.template.width = am4core.percent(95);
                  series.fill = color;
                  series.strokeWidth = 0;
                }

                createSeries("DRAFT_CAT", "On Review", false,'#fbe75e');
                createSeries("REG", "Done", true,'#03af70');

                chart.legend = new am4charts.Legend();

            }); // end am4core.ready()
        },
        chart2(){
             var self = this;
             am4core.ready(function() {

                // Themes begin
                am4core.useTheme(am4themes_animated);

                var chart = am4core.create("chartdiv2", am4charts.PieChart);

                // Add data
                chart.data = [ {
                  "country": "Registered On SAP",
                  "litres": self.data_reg
                }, {
                  "country": "Waiting Sent to SAP",
                  "litres": self.data_draft
                }, {
                  "country": "Failed",
                  "litres": self.data_rjt
                }];

                // Add and configure Series
                var pieSeries = chart.series.push(new am4charts.PieSeries());
                pieSeries.dataFields.value = "litres";
                pieSeries.dataFields.category = "country";
                pieSeries.slices.template.stroke = am4core.color("#fff");
                pieSeries.slices.template.strokeWidth = 2;
                pieSeries.slices.template.strokeOpacity = 1;
                pieSeries.alignLabels = false;
                pieSeries.labels.template.bent = true;

                // This creates initial animation
                pieSeries.hiddenState.properties.opacity = 1;
                pieSeries.hiddenState.properties.endAngle = -90;
                pieSeries.hiddenState.properties.startAngle = -90;

                var colors = ["#03af70","#fbe75e","#fe1f1f"];
                var colorset = new am4core.ColorSet();
                colorset.list = [];
                for(var i=0;i<colors.length;i++)
                    colorset.list.push(new am4core.color(colors[i]));
                pieSeries.colors = colorset;

            }); // end am4core.ready()
        },
        chart3(){
             var self = this;
             am4core.ready(function() {

                am4core.useTheme(am4themes_animated);

                var chart = am4core.create("chartdiv1", am4charts.PieChart);

                // Add data
                chart.data = [ {
                  "country": "Done",
                  "litres": self.data_done
                }, {
                  "country": "On Review",
                  "litres":  self.data_onreview
                }];

                // Add and configure Series
                var pieSeries = chart.series.push(new am4charts.PieSeries());
                pieSeries.dataFields.value = "litres";
                pieSeries.dataFields.category = "country";
                pieSeries.slices.template.stroke = am4core.color("#fff");
                pieSeries.slices.template.strokeWidth = 2;
                pieSeries.slices.template.strokeOpacity = 1;
                pieSeries.alignLabels = false;
                pieSeries.labels.template.bent = true;
                
                // This creates initial animation
                pieSeries.hiddenState.properties.opacity = 1;
                pieSeries.hiddenState.properties.endAngle = -90;
                pieSeries.hiddenState.properties.startAngle = -90;

                var colors = ["#03af70","#fbe75e"];
                var colorset = new am4core.ColorSet();
                colorset.list = [];
                for(var i=0;i<colors.length;i++)
                    colorset.list.push(new am4core.color(colors[i]));
                pieSeries.colors = colorset;

            }); // end am4core.ready()
        },
        getServer(){
          var self = this;
          var url = this.base_url + "dropdown/server_dashboard"; 
          this.axios.get(url).then(function (response) {
            self.list_server_code = response.data;
          })
        },
        getCompany(){  
          var self = this;
          var server_code = self.server_code;
          if(server_code==''){
            server_code = '-'
          }
          var url = this.base_url + "dropdown/company_dashboard/"+server_code; 
          this.axios.get(url).then(function (response) {
            self.list_company = response.data;
          });
        },
        nullCompany(){
          this.company_id = "";
          this.list_company = [];
        },
        alldata(){
          var self = this;
          self.server_code = "";
          self.company_id = "";
          self.date = "";
          self.date2 = "";
          self.list_company = [];
          self.getData();
        },
        getDateMonth(){
          var today=new Date()
          var year=today.getFullYear()
          var month=today.getMonth()
          var date=today.getDate()
          if((month-1)<=0)
          year=today.getFullYear()
          var backdate = new Date(year,month-1,date).toISOString().substr(0, 10);
          this.date = backdate;
        }
    },
    mounted(){
        //this.getData();
        if(this.storage_role !='CAT' && this.storage_role !='ADM'){
          this.$router.push('/login');
        }

        this.getDateMonth();
        this.getServer();        
        this.getData();
        this.getCompany();
    },
    data () { 
      return {
        name : "ApproveRatio",
        title : "Done / Request Ratio",
        items: [],
        desserts : [],
        headers: [
          { text: 'Action', value: 'action', sortable: false,width:150},
          { text: 'Approval', value: 'status'},
          { text: 'Total Item', value: 'status'},
        ],

        data_done : "",
        data_onreview : "",
        data_reg : "",
        data_draft : "",
        data_rjt : "",
        chart_data3 : [],
        list_company : [{COMPANY_ID:'',COMPANY_NAME:'All Company'}],
        list_server_code : [],

        server_code : 'GEMS',
        company_id : '',
        date : '',
        date2:new Date().toISOString().substr(0, 10),

        //storage.....................................
        storage_user_id : atob(localStorage.getItem("user_id")),
        storage_username : atob(localStorage.getItem("username")),
        storage_fullname : atob(localStorage.getItem("fullname")),
        storage_company_id : atob(localStorage.getItem("company_id")),
        storage_company_name : atob(localStorage.getItem("company_name")),
        storage_role : atob(localStorage.getItem("role")),
        storage_server : atob(localStorage.getItem("server")),
        storage_departement_id : atob(localStorage.getItem("departement_id")),
      }
    },
  }
</script>
